import React, {Component} from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import axios from '@/assets/js/axios'
import {Toast} from 'antd-mobile'
import {getQueryString, signParam, toPage, toBookCover, toBookRead, toCatalog, string} from '@/assets/js/string'
import ctx from '@/assets/js/ctx'
import { List, Flex,Button } from 'antd-mobile';
import { Space } from 'antd-mobile-v5';

const Item = List.Item;
const Brief = Item.Brief;

export class index extends Component {
  static contextType = ctx
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.state = {
      flag:0
    }
  }
  addShelf = (e, articleId, chapterId) => {
    var stamp = new Date().getTime()
    const ev = e || window.event
    ev.stopPropagation()
    axios.post(global.constants.apiUrl + `novel/postAddCase`, {
      articleId: articleId,
      chapterId: chapterId,
      ver: this.context.ver,
      channel: this.context.channel,
      versionId: this.context.versionId,
      openId: this.context.openid,
      site: this.context.site,
      timestamp: stamp,
      sign: signParam(this.context.ver, this.context.channel, this.context.versionId, this.context.openid, this.context.site, stamp)
    })
      .then((res) => {
        Toast.success(res.msg, 1)
      })
  }
  componentDidMount () {
    if (this.props.homeList) {
      this.props.changeHomeListFirstHeight(this.headerRef.current.clientHeight)
      this.setState({
        flag: 1
      })
    }
  }
  render() {
    const {addShelf, delBtnFlag} = this

    const {bookShelfList, readLogList, freeList, rankList, searchList, classifyList, cataLogList, fenSiList, reviewList, financeList, financeList1, signList, index, deleteUserBook,topList,homeList,xsthList,mshdList,jpbList,rowID} = this.props

    return (
      <>

      {/* 首页 */}
        {homeList &&
          
        <div className={`aui-col-xs-6`} onClick={() => {
                toBookCover(homeList.articleid, this.context)
              }}>
          <div className={`aui-bg-white aui-border-radius-10 aui-padded-10 aui-padded-b-0 aui-margin-t-10`} style={rowID % 2 == 0 ? {'marginRight': '5px'} : {'marginLeft': '5px'}} ref={this.headerRef}>
              <Flex>
                <Flex.Item>
                  <img src={homeList.thumburl} className={`middle`} 
                            onError={e => {
                              e.target.src = 'http://i.mixs.cn/upload/cover/default.jpeg';
                            }}/>
                </Flex.Item>
                <Flex.Item style={{'height':'68px'}}>
                  <span className={`aui-font-size-14 articlename aui-ellipsis-2 aui-text-bold`}
                      style={{'fontSize': '0.45rem!important'}}>{homeList.articlename}</span>
                  <p className={`aui-font-size-12 author aui-margin-t-5`}
                      style={{'fontSize': '0.3rem!important'}}>{homeList.author}</p>
                </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-10`}>
                <Flex.Item>
                  {homeList.is_self === 1 ? <span className={`aui-font-size-10 span-radius-mini is_self`}>自营</span> : ''}
                  <span className={`aui-font-size-10 span-radius-mini ${ homeList.is_self === 1 ? 'aui-margin-l-5' : ''} self_sale`}>{homeList.is_esilver_buy}</span>
                  <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 fullflag`}>{homeList.fullflag}</span>
                </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-0`}>
                <Flex.Item>
                  <span className={`aui-font-size-10 span-radius-mini sortname`}>{string.truncate(homeList.sortname, 2, '')}</span>  
                  <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 first-sale`}>{homeList.warrant_type}</span>
                  <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 size`}>{homeList.size}</span>
               </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-10`}>
                <Flex.Item>
                <p className={`aui-text-color-gray-light aui-font-size-12 aui-ellipsis-3 intro`} style={{'lineHeight':'0.9rem','minHeight':'70px'}}>{string.truncate(homeList.intro, 90, '...')}</p>
                </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-5`} style={{ 'paddingBottom': '0.25rem' }}>
                <Flex.Item>
                  {homeList.is_recommend === 1 ?
                    <>
                      <img src={require('@/assets/icon/book-hot.png')} style={{ 'width': '20px','float':'left' }} />
                    </>
                    : ''}
                    {homeList.sale_total === 0 ?
                    <>
                      <img src={require('@/assets/icon/book-bqdj.png')} style={{ 'width': '40px','float':'left' }} />
                    </>
                    : ''}
                  {homeList.is_new === 1 ?
                    <>
                      <img src={require('@/assets/icon/book/new.png')} style={{ 'width': '25px','float':'left','marginTop':'3px' }} />
                    </>
                    : ''}
                </Flex.Item>
                <Flex.Item style={{'textAlign':'right','marginLeft':'0px','whiteSpace':'nowrap'}}>
                  {homeList.book_price > 0 ?
                    <span className={`aui-font-size-14 aui-text-color-pink`}>¥ <span className={`${homeList.book_price >= 10000 ? 'aui-font-size-12' : ''}`}>{homeList.book_price}</span><span className={`aui-font-size-10`}> FM点</span></span>
                    : 
                    <>
                      <span className={`aui-font-size-14 aui-text-color-gray text-decoration`}>¥ <span className={`${homeList.book_price >= 10000 ? 'aui-font-size-12' : ' '}`}>{homeList.market_price}</span><span className={`aui-font-size-10`}> FM点</span></span><br/>
                      <span className={`aui-font-size-14 aui-text-color-pink`}>
                      年卡免费</span>
                      </>
                  }
                </Flex.Item>
              </Flex>
            </div>
        </div>
        }

        {xsthList &&
          
        <div onClick={() => {
                toBookCover(xsthList.articleid, this.context)
              }}>
              <div className={`aui-bg-white aui-border-radius-10 aui-padded-10 aui-padded-b-0 aui-margin-t-10`} style={{'height':'205px'}}>
                <div className={`aui-col-xs-8`}>
                <Flex>
                  
                  <Flex.Item>
                    <span className={`aui-font-size-14 articlename aui-ellipsis-2 aui-text-bold`}
                        style={{'fontSize': '0.45rem!important'}}>{xsthList.articlename}</span>
                    <p className={`aui-font-size-12 author aui-margin-t-5`}
                      style={{ 'fontSize': '0.3rem!important' }}>{xsthList.author}</p>
                    
                    <Flex className={`aui-margin-t-10`}>
                      <Flex.Item>
                        {xsthList.is_self === 1 ? <span className={`aui-font-size-10 span-radius-mini is_self`}>自营</span> : ''}
                        <span className={`aui-font-size-10 span-radius-mini ${ xsthList.is_self === 1 ? 'aui-margin-l-5' : ''} self_sale`}>{xsthList.is_esilver_buy}</span>
                        <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 fullflag`}>{xsthList.fullflag}</span>
                      </Flex.Item>
                    </Flex>
                    <Flex className={`aui-margin-t-0`}>
                  <Flex.Item>
                    <span className={`aui-font-size-10 span-radius-mini sortname`}>{string.truncate(xsthList.sortname, 2, '')}</span>  
                    <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 first-sale`}>{xsthList.warrant_type}</span>
                    <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 size`}>{xsthList.size}</span>
                </Flex.Item>
                    </Flex>
                    <Flex className={`aui-margin-t-5`}>
                  <Flex.Item>
                  <p className={`aui-text-color-gray-light aui-font-size-12 aui-ellipsis-3 intro`} style={{'lineHeight':'0.9rem','minHeight':'54px'}}>{string.truncate(xsthList.intro, 90, '...')}</p>
                  </Flex.Item>
                    </Flex>
                    <Flex className={`aui-margin-t-5`}>
                      <Flex.Item>
                    {xsthList.is_recommend === 1 ?
                      <>
                        <img src={require('@/assets/icon/book-hot.png')} style={{ 'width': '20px','float':'left' }} />
                      </>
                      : ''}
                      {xsthList.sale_total === 0 ?
                      <>
                        <img src={require('@/assets/icon/book-bqdj.png')} style={{ 'width': '40px','float':'left' }} />
                      </>
                      : ''}
                  </Flex.Item>
                    </Flex>
                    <Flex className={`aui-margin-t-5`}>
                  <Flex.Item style={{'textAlign':'left','marginLeft':'0px','whiteSpace':'nowrap'}}>
                    <span className={`aui-font-size-14 aui-text-color-pink`}>原价：¥ <span className={`${ xsthList.book_price >= 10000 ? 'aui-font-size-12' : ''}`}>{xsthList.book_price}</span><span className={`aui-font-size-10`}> FM点</span></span>
                      </Flex.Item>
                  <Flex.Item style={{'textAlign':'left','marginLeft':'0px','whiteSpace':'nowrap'}}>
                    <span className={`aui-font-size-14 aui-text-color-pink`}>现价：¥ <span className={`${ xsthList.book_price >= 10000 ? 'aui-font-size-12' : ''}`}>{xsthList.book_price}</span><span className={`aui-font-size-10`}> FM点</span></span>
                  </Flex.Item>
                </Flex>
                  </Flex.Item>
                </Flex>
              </div>
              <div className={`aui-col-xs-4`}>
                  <Flex>
                    <Flex.Item>
                      <img src={xsthList.thumburl} onError={(e) => { e.target.src = "http://i.mixs.cn/upload/cover/xy.jpeg" }} style={{ 'width': '7rem', 'height': '7rem','borderRadius':'0.3rem' }}/>
                    </Flex.Item>
                  </Flex>
              </div>
            </div>
          </div>
        }

        {mshdList &&
        <div className={`aui-col-xs-6`} onClick={() => {
                toBookCover(mshdList.articleid, this.context)
              }}>
          <div className={`aui-bg-white aui-border-radius-10 aui-padded-10 aui-padded-b-0 aui-margin-t-10`} style={rowID % 2 == 0 ? {'marginRight': '5px'} : {'marginLeft': '5px'}} ref={this.headerRef}>
              <Flex>
                <Flex.Item>
                  <img src={mshdList.thumburl} className={`middle`} 
                            onError={e => {
                              e.target.src = 'http://i.mixs.cn/upload/cover/default.jpeg';
                            }}/>
                </Flex.Item>
                <Flex.Item style={{'height':'68px'}}>
                  <span className={`aui-font-size-14 articlename aui-ellipsis-2 aui-text-bold`}
                      style={{'fontSize': '0.45rem!important'}}>{mshdList.articlename}</span>
                  <p className={`aui-font-size-12 author aui-margin-t-5`}
                      style={{'fontSize': '0.3rem!important'}}>{mshdList.author}</p>
                </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-10`}>
                <Flex.Item>
                  {mshdList.is_self === 1 ? <span className={`aui-font-size-10 span-radius-mini is_self`}>自营</span> : ''}
                  <span className={`aui-font-size-10 span-radius-mini ${ mshdList.is_self === 1 ? 'aui-margin-l-5' : ''} self_sale`}>{mshdList.is_esilver_buy}</span>
                  <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 fullflag`}>{mshdList.fullflag}</span>
                </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-0`}>
                <Flex.Item>
                  <span className={`aui-font-size-10 span-radius-mini sortname`}>{string.truncate(mshdList.sortname, 2, '')}</span>  
                  <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 first-sale`}>{mshdList.warrant_type}</span>
                  <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 size`}>{mshdList.size}</span>
               </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-10`}>
                <Flex.Item>
                <p className={`aui-text-color-gray-light aui-font-size-12 aui-ellipsis-3 intro`} style={{'lineHeight':'0.9rem','minHeight':'54px'}}>{string.truncate(mshdList.intro, 90, '...')}</p>
                </Flex.Item>
              </Flex>
              <Flex className={`aui-margin-t-5`} style={{ 'paddingBottom': '0.25rem' }}>
                <Flex.Item>
                  {mshdList.is_recommend === 1 ?
                    <>
                      <img src={require('@/assets/icon/book-hot.png')} style={{ 'width': '20px','float':'left' }} />
                    </>
                    : ''}
                    {mshdList.sale_total === 0 ?
                    <>
                      <img src={require('@/assets/icon/book-bqdj.png')} style={{ 'width': '40px','float':'left' }} />
                    </>
                    : ''}
                  {mshdList.is_new === 1 ?
                    <>
                      <img src={require('@/assets/icon/book/new.png')} style={{ 'width': '25px','float':'left','marginTop':'3px' }} />
                    </>
                    : ''}
                </Flex.Item>
                <Flex.Item style={{ 'textAlign': 'right', 'marginLeft': '0px', 'whiteSpace': 'nowrap' }}>
                  {mshdList.market_price != mshdList.book_price ?
                    <>
                      <span className={`aui-font-size-14 aui-text-color-pink`} >¥ <span className={`${mshdList.book_price >= 10000 ? 'aui-font-size-12' : ''}`}>{mshdList.book_price}</span><span className={`aui-font-size-10`}> FM点</span></span>
                      <br />
                      <span className={`aui-font-size-10 aui-text-color-pink`} style={{ textDecoration: 'line-through' }}>原价：¥ <span className={`${mshdList.market_price >= 10000 ? 'aui-font-size-10' : ''}`}>{mshdList.market_price}</span><span className={`aui-font-size-10`}>FM点</span></span>
                    </>
                    :
                    <>
                      <span className={`aui-font-size-14 aui-text-color-pink`}>¥ <span className={`${mshdList.book_price >= 10000 ? 'aui-font-size-12' : ''}`}>{mshdList.book_price}</span><span className={`aui-font-size-10`}> FM点</span></span>
                    </>
                  }
                </Flex.Item>
              </Flex>
            </div>
        </div>
        }

        {jpbList &&
          
          <div className={`aui-col-xs-6`} onClick={() => {
            toBookCover(jpbList.articleid, this.context)
          }}>
      <div className={`aui-bg-white aui-border-radius-10 aui-padded-10 aui-padded-b-0 aui-margin-t-10`} style={rowID % 2 == 0 ? {'marginRight': '5px'} : {'marginLeft': '5px'}} ref={this.headerRef}>
          <Flex>
            <Flex.Item>
              <img src={jpbList.thumburl} className={`middle`} 
                        onError={e => {
                          e.target.src = 'http://i.mixs.cn/upload/cover/default.jpeg';
                        }}/>
            </Flex.Item>
            <Flex.Item style={{'height':'68px'}}>
              <span className={`aui-font-size-14 articlename aui-ellipsis-2 aui-text-bold`}
                  style={{'fontSize': '0.45rem!important'}}>{jpbList.articlename}</span>
              <p className={`aui-font-size-12 author aui-margin-t-5`}
                  style={{'fontSize': '0.3rem!important'}}>{jpbList.author}</p>
            </Flex.Item>
          </Flex>
          <Flex className={`aui-margin-t-10`}>
            <Flex.Item>
              {jpbList.is_self === 1 ? <span className={`aui-font-size-10 span-radius-mini is_self`}>自营</span> : ''}
              <span className={`aui-font-size-10 span-radius-mini ${ jpbList.is_self === 1 ? 'aui-margin-l-5' : ''} self_sale`}>{jpbList.is_esilver_buy}</span>
              <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 fullflag`}>{jpbList.fullflag}</span>
            </Flex.Item>
          </Flex>
          <Flex className={`aui-margin-t-0`}>
            <Flex.Item>
              <span className={`aui-font-size-10 span-radius-mini sortname`}>{string.truncate(jpbList.sortname, 2, '')}</span>  
              <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 first-sale`}>{jpbList.warrant_type}</span>
              <span className={`aui-font-size-10 span-radius-mini aui-margin-l-5 size`}>{jpbList.size}</span>
           </Flex.Item>
          </Flex>
          <Flex className={`aui-margin-t-10`}>
            <Flex.Item>
            <p className={`aui-text-color-gray-light aui-font-size-12 aui-ellipsis-3 intro`} style={{'lineHeight':'0.9rem','minHeight':'54px'}}>{string.truncate(jpbList.intro, 90, '...')}</p>
            </Flex.Item>
          </Flex>
          <Flex className={`aui-margin-t-5`} style={{ 'paddingBottom': '0.25rem' }}>
            <Flex.Item>
              {jpbList.is_recommend === 1 ?
                <>
                  <img src={require('@/assets/icon/book-hot.png')} style={{ 'width': '20px','float':'left' }} />
                </>
                : ''}
                {jpbList.sale_total === 0 ?
                <>
                  <img src={require('@/assets/icon/book-bqdj.png')} style={{ 'width': '40px','float':'left' }} />
                </>
                : ''}
              {jpbList.is_new === 1 ?
                <>
                  <img src={require('@/assets/icon/book/new.png')} style={{ 'width': '25px','float':'left','marginTop':'3px' }} />
                </>
                : ''}
            </Flex.Item>
            <Flex.Item style={{'textAlign':'right','marginLeft':'0px','whiteSpace':'nowrap'}}>
              {jpbList.book_price > 0 ? <>
                 <span className={`aui-font-size-12 text-decoration`}>¥ <span className={`${ jpbList.market_price >= 10000 ? 'aui-font-size-12' : ''}`}>{jpbList.market_price}</span></span>
                 <span className={`aui-margin-l-5 aui-font-size-14 aui-text-color-pink`}>¥ <span className={`${jpbList.book_price >= 10000 ? 'aui-font-size-12' : ''}`}>{jpbList.book_price}</span><span className={`aui-font-size-10`}> FM点</span></span>
                 </>
                : 
                <>
                  <span className={`aui-font-size-14 aui-text-color-gray text-decoration`}>¥ <span className={`${jpbList.book_price >= 10000 ? 'aui-font-size-12' : ' '}`}>{homeList.market_price}</span><span className={`aui-font-size-10`}> FM点</span></span><br/>
                  <span className={`aui-font-size-14 aui-text-color-pink`}>
                  年卡免费</span>
                  </>
              }
            </Flex.Item>
          </Flex>
        </div>
    </div>
          }

        {/* 阅读记录 */}
        {readLogList &&
        <li className={`aui-list-item aui-padded-l-10 aui-padded-t-10 aui-padded-b-10 aui-bg-none`}>
          <div className={`aui-grid`}>
            <div className={`aui-row`}>
              <div className={`aui-col-xs-3 aui-padded-t-0 aui-padded-b-0 cur`} style={{'width': '3.2rem'}}
                   onClick={() => {
                     toBookRead(readLogList.articleid, readLogList.readchapterid, this.context)
                   }}>
                <img src={readLogList.bookimage} className={`aui-image-s-v`} style={{'height': '4.0rem'}}/>
                {(readLogList.bookimage || []).indexOf('nocover.png') !== -1 ?
                  <div className={`no-cover-block`} style={{'width': '100%', 'bottom': '20px'}}>
                    <p className={`aui-font-size-12 articlename`}
                       style={{'fontSize': '0.45rem!important'}}>{readLogList.articlename}</p>
                    <p className={`aui-font-size-12 author aui-margin-t-5`}
                       style={{'fontSize': '0.3rem!important'}}>{readLogList.author}</p>
                  </div>
                  : ''}
              </div>
              <div className={`aui-col-xs-6 aui-padded-t-0 aui-padded-b-0 aui-padded-l-5`} onClick={() => {
                toBookRead(readLogList.articleid, readLogList.readchapterid, this.context)
              }}>
                <div className={`aui-list-item-inner aui-padded-r-0 aui-padded-t-0`}>
                  <div className={`aui-list-item-text aui-padded-b-5 cur`}>
                    <div
                      className={`aui-list-item-title aui-ellipsis-1 aui-text-align-l`}>{readLogList.articlename}</div>
                  </div>
                  <div className={`aui-list-item-text aui-pull-left`}>
                    <div className={`aui-list-item-title-s aui-ellipsis-1 aui-font-size-12 aui-text-align-l`}>
                      更新：{readLogList.lastchaptername}</div>
                  </div>
                  <div className={`aui-list-item-text aui-pull-left`}>
                    <div
                      className={`aui-list-item-title-s aui-ellipsis-1 aui-font-size-12 aui-text-align-l aui-text-color-pink`}>
                      读至：{readLogList.readchaptername}</div>
                  </div>
                </div>
              </div>
              <div className={`aui-col-xs-3 aui-padded-t-0 aui-padded-b-0 aui-pull-right`}
                   style={{'paddingRight': '0.15rem !important'}}>
                <div className={`aui-card-list aui-margin-b-0`} style={{'height': '4.2rem'}}>
                  <div className={`aui-card-list-header aui-padded-t-0`}>

                  </div>
                  <div className={`aui-card-list-footer aui-padded-0`} style={{'display': 'block'}}>
                    <div className={`aui-text-color-pink aui-pull-right aui-padded-r-5 aui-padded-r-10 cur`}
                         onClick={() => {
                           addShelf(event, readLogList.articleid, readLogList.readchapterid)
                         }}>
                      {readLogList.isbookcase == 0 ?
                        <span>加入书架</span>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`aui-row aui-margin-t-10`}>
              <div className={`aui-col-xs-3 aui-padded-0`} style={{'width': '3.2rem', 'height': '1px'}}></div>
              <div className={`aui-col-xs-9 line`}>

              </div>
            </div>
          </div>
          {readLogList.chapterupdate ?
            <i className={`aui-iconfont fa fa-update`}></i>
            : ''}

        </li>
        }

        {/* 目录 */}
        {cataLogList &&
        <List id={`chapter-${cataLogList.chapterid}`}>
          <Item extra={cataLogList.isCurrent == 1 ? <i className={`aui-iconfont mixs-iconfont mixs-icon-flag`}></i> : '', cataLogList.issale == 0 ? <i className={`aui-iconfont aui-icon-lock`}></i> : ''}  onClick={() => {
            toBookRead(cataLogList.articleid, cataLogList.chapterid, this.context)
          }}><span className={`aui-list-item-title aui-font-size-18 cur ${cataLogList.isCurrent ? 'aui-text-color-pink' : ''} `}>{string.truncate(cataLogList.name, 15, '...')}</span></Item>
        </List>
        }

        {/* 粉丝排行 */}
        {fenSiList &&
        <li class="aui-list-item aui-list-item-middle aui-padded-t-0 aui-padded-b-0">
          <div class="aui-media-list-item-inner">
            <div class="aui-list-item-media aui-padded-0" style={{'width': '2rem'}}>
              {index < 3 ?
                <span className={`mixs-iconfont mixs-icon-flag flag ${'flag-color-' + index}`}
                      ms-if="index < 3"><i>{parseInt(index) + 1}</i></span>
                : ''}
              {index >= 3 ?
                <span class="flag"><i style={{
                  'color': '#757575',
                  'border': '1px solid #999999',
                  'width': '1rem',
                  'left': '0.6rem',
                  'top': '-0.8rem',
                  'padding': '0.1rem 0rem'
                }}>{index + 1}</i></span>
                : ''}
            </div>
            <div class="aui-list-item-media" style={{'width': '3rem'}}>
              <img src={fenSiList.avatar} onerror="this.src='/assets/icon/my.jpeg'"
                   class="aui-img-round aui-list-img-sm aui-margin-center" style={{'height': '2.3rem'}}/>
            </div>
            <div class="aui-list-item-inner aui-padded-t-0">
              <div class="aui-list-item-text">
                <div
                  class="aui-list-item-title aui-font-size-16">{string.truncate(fenSiList.nickname, 16, '...')}</div>
                <div class="aui-list-item-right aui-font-size-16">{fenSiList.total}
                  {this.context.siteinfo && this.context.siteinfo.currencyname}
                </div>
              </div>
              <div class="aui-list-item-text">
                <div>
                  {fenSiList.vip == 0 ?
                    <span
                      class="aui-text-color-pink aui-margin-r-5 aui-font-size-14"
                      style={{'font-size': '0.72rem !important'}}>VIP1</span>
                    : ''}
                  {fenSiList.vip > 0 ?
                    <span
                      class="aui-text-color-pink aui-margin-r-5 aui-font-size-14">VIP{fenSiList.vip}</span>
                    : ''}
                  {fenSiList.monthly == 1 ?
                    <span class="aui-text-color-pink aui-font-size-14">包月</span>
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </li>
        }

        {/* 评论 */}
        {reviewList &&
        <li class="aui-chat-item aui-chat-left aui-padded-b-5">
          <div class="aui-chat-media">
            <img src={reviewList.avatar} onerror="this.src='/assets/icon/my.jpeg';"/>
          </div>
          <div class="aui-chat-inner aui-padded-t-5 aui-padded-b-5">
            <div class="aui-chat-name aui-font-size-12">
              <div class="aui-pull-left"><span class="aui-font-size-14"
                                               style={{'color': '#414141'}}>{reviewList.username}</span>
                {reviewList.vip == 0 ? <span class="aui-label aui-label-outlined aui-margin-l-5">普通会员</span> : ''}
                {reviewList.vip == 1 ? <span ms-if="el.vip == 1"
                                             class="aui-label aui-label-danger aui-label-outlined aui-margin-l-5">VIP</span> : ''}
                {reviewList.monthly == 1 ? <span ms-if="el.monthly == 1"
                                                 class="aui-label aui-label-warning aui-label-outlined aui-margin-l-5">包月</span> : ''}
                <br/>{reviewList.replaytime}
              </div>
              <div class="aui-pull-right" onClick={() => {
                submitReplayComment(reviewList.replayid)
              }}><i
                class="aui-iconfont aui-icon-comment"></i></div>
            </div>
            <div class="aui-chat-content aui-padded-r-10">
              {reviewList.content}
            </div>
          </div>
          {reviewList.replays && reviewList.replays.map((el, il) =>
            <div class="aui-chat">
              <div class="aui-chat-item aui-chat-left aui-padded-5 aui-padded-t-10 aui-padded-b-0 aui-padded-l-10"
                   style={{'background': '#f1f1f1'}}>
                <div class="aui-chat-media" style={{'margin-left': '0.25rem'}}>
                  <img src={el.avatar} onerror="this.src='/assets/icon/my.jpeg';"/>
                </div>
                <div class="aui-chat-inner aui-padded-l-0" style={{'padded-left': '0.25rem'}}>
                  <div class="aui-chat-name">
                    <span class="aui-label aui-label-warning">{el.username}</span>
                    {el.vip == 0 ? <span class="aui-label aui-label-outlined aui-margin-l-5">普通会员</span> : ''}
                    {el.vip == 1 ?
                      <span class="aui-label aui-label-danger aui-label-outlined aui-margin-l-5">VIP</span> : ''}
                    {el.monthly == 1 ?
                      <span class="aui-label aui-label-warning aui-label-outlined aui-margin-l-5">包月</span> : ''}
                    <br/>
                    {el.replaytime}
                  </div>
                  <div class="aui-chat-content">
                    {el.content}
                  </div>
                </div>
              </div>
            </div>)}
        </li>
        }

        {/* 免费小说 */}
        {freeList &&
        <>
          <List className="sub-list">
            <Item
              arrow=""
              thumb={freeList.thumburl}
              multipleLine
              onClick={() => {
                toBookCover(freeList.articleid, this.context)
              }}
            >
              <span className={`aui-font-size-16`}>{string.truncate(freeList.articlename, 12, '...')}</span>
              <Brief style={{'marginTop':'0px'}}>
              <span className={`aui-font-size-12`}>¥{freeList.book_price} FM点 · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{freeList.warrant_type} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{freeList.is_esilver_buy}</span>
            </Brief>
              <Brief style={{'marginTop':'0px'}}>
                <span className={`aui-font-size-12`}>{freeList.author} · </span>
                <span className={`aui-font-size-12 aui-margin-l-5`}>{freeList.size} · </span>
                <span className={`aui-font-size-12 aui-margin-l-5`}>{freeList.fullflag} · </span>
                <span className={`aui-font-size-12 aui-margin-l-5`}>{freeList.allvisit}</span>
              </Brief>
            </Item>
          </List>
        </>
        }

        {/* 书库 */}
        {rankList &&
        <li className={`aui-list-item aui-bg-none`} onClick={() => {
          toBookCover(rankList.articleid, this.context)
        }}>
          <div className={`aui-media-list-item-inner`}>
            <div className={`aui-list-item-media aui-padded-r-15`} style={{'width': '5.1rem'}}>
              <img src={rankList.thumburl} className={`aui-image-s-s`} style={{'height': '5.5rem'}}/>
              {(rankList.thumburl || []).indexOf('nocover.png') !== -1 ?
                <div className={`no-cover-block aui-padded-r-15`}>
                  <p className={`aui-font-size-12 articlename`}>{rankList.articlename}</p>
                  <p className={`aui-font-size-12 author aui-margin-t-5`}>{rankList.author}</p>
                </div>
                : ''}
              {1 == 1 ?
                <span className={`flag`}
                      className={classnames(styles.flag, rankList.index > 3 ? styles.gray : '')}>{rankList.index}</span>
                : ''}
            </div>
            <div className={`aui-list-item-inner aui-margin-r-10`}>
              <div className={`aui-list-item-text`}>
                <div className={`aui-list-item-title`}>
                  <div
                    className={`aui-list-item-title aui-ellipsis-1 aui-font-size-16`}>{string.truncate(rankList.articlename, 15, '...')}</div>
                </div>
                <div className={`aui-list-item-right`}></div>
              </div>
              <div className={`aui-list-item-text aui-ellipsis-2 aui-font-size-14`}
                   style={{'display': '-webkit-box'}}>{rankList.intro}</div>
              <div className={`aui-info`} style={{'padding': '0', 'marginTop': '1.4rem'}}>
                <div className={`aui-info-item`}>
                  <span className={`aui-font-size-12 aui-label aui-label-outlined`}
                        style={{'padding': '0.2rem'}}>{rankList.author}</span>
                  <span className={`aui-margin-l-5 aui-font-size-12 aui-label aui-label-outlined`}
                        style={{'padding': '0.2rem'}}>{rankList.size}</span>
                  <span className={`aui-margin-l-5 aui-font-size-12 aui-label aui-label-outlined`}
                        style={{'padding': '0.2rem'}}>{rankList.fullflag}</span>
                </div>
                <div className={`aui-info-item`}>
                  <div className={` aui-font-size-12 aui-label aui-label-outlined`}
                       style={{'padding': '0.2rem'}}>{rankList.monthvisit}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
        }

        {/* 书架 */}
        {bookShelfList &&
        <List className={`sub-list aui-bg-white aui-border-radius-10 `}>
          <Item
            arrow=""
            thumb={bookShelfList.bookimage}
            multipleLine
            onClick={() => {
              toBookRead(bookShelfList.articleid, bookShelfList.readchapterid, this.context)
            }}
            extra={bookShelfList.isDel == 1 ?
              <div className={`aui-text-color-gray`}>已删除</div>
              :
              <div className={`aui-text-color-pink aui-font-size-14`}
                   onClick={(event) => {
                     deleteUserBook(event, bookShelfList.articleid)
                   }}>
                <span>移除</span>
              </div>
            }
            align="bottom"
          >
            <span className={`aui-font-size-16`}>{string.truncate(bookShelfList.articlename, 12, '...')}</span>
            <Brief>
              <div className={`aui-font-size-12`}>更新：{bookShelfList.lastchaptername}</div>
            </Brief>
            <Brief>
              <div className={`aui-font-size-12 aui-text-color-pink`}>
                读至：{bookShelfList.readchaptername}
              </div>
              </Brief>

            </Item>
            <Flex className={`aui-font-size-14 aui-float-right`}>
                <Flex.Item><Button size="small" type="warning" inline className={`aui-padded-l-5 aui-padded-r-5 pull-right`}>等待授权</Button><Button size="small" inline className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-10 pull-right`}>已付款</Button></Flex.Item>
               </Flex>
        </List>
        }
        {/* 书架 */}

        {/* 书库 */}
        {classifyList &&
        <List className="sub-list" onClick={() => {
          toBookCover(classifyList.articleid, this.context)
        }}>
          <Item
            arrow=""
            thumb={classifyList.thumburl}
            multipleLine
            onClick={() => {
            }}
          >
            <span className={`aui-font-size-16`}>{string.truncate(classifyList.articlename, 8, '...')}</span> {classifyList.sale_total === 0 ? <span className={`aui-font-size-12 aui-text-color-pink aui-margin-l-5 first-sale`} style={{'borderRadius':'3px','padding':'0px 3px 0px 3px'}}>可登记</span> : ''} {classifyList.is_self === 1 ? <span className={`aui-font-size-12 aui-margin-l-5 self_sale`} style={{'borderRadius':'3px','padding':'0px 3px 0px 3px'}}>FM自营</span> : ''}
            <Brief style={{'marginTop':'0px'}}>
              <span className={`aui-font-size-12`}>¥{classifyList.book_price} FM点 · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{classifyList.warrant_type} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{classifyList.is_esilver_buy}</span>
            </Brief>
            <Brief style={{'marginTop':'0px'}}>
              <span className={`aui-font-size-12`}>{classifyList.author} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{classifyList.size} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{classifyList.fullflag} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{string.truncate(classifyList.sortname, 2, '')}</span>
              <span className={`aui-font-size-12 aui-margin-l-5 aui-pull-right`}>{classifyList.searchback}</span>
            </Brief>
          </Item>
        </List>
        }

        {/* 搜索 */}
        {searchList &&
        <li className={`aui-list-item aui-bg-none`} onClick={() => {
          toBookCover(searchList.articleid, this.context)
        }}>
          <div className={`aui-media-list-item-inner`}>
            <div className={`aui-list-item-media aui-padded-r-15`} style={{'width': '5.1rem'}}>
              <img src={searchList.thumburl} className={`aui-image-s-s`} style={{'height': '5.5rem'}}/>
              {searchList.thumburl == '/assets/image/nocover.png' ?
                <div className={`no-cover-block aui-padded-r-15`}>
                  <p className={`aui-font-size-12 articlename`}>{searchList.articlename}</p>
                  <p className={`aui-font-size-12 author aui-margin-t-5`}>{searchList.author}</p>
                </div>
                : ''}
              <span className={`flag`}
                    className={classnames(styles.flag, searchList.index > 3 ? styles.gray : '')}>{searchList.index}</span>
            </div>
            <div className={`aui-list-item-inner`}>
              <div className={`aui-list-item-text`}>
                <div className={`aui-list-item-title`}>
                  <div className={`aui-list-item-title aui-ellipsis-1 aui-font-size-16`}>{searchList.articlename}</div>
                </div>
                <div className={`aui-list-item-right`}></div>
              </div>
              <div className={`aui-list-item-text aui-ellipsis-2 aui-font-size-14`}
                   style={{'display': '-webkit-box'}}>{searchList.intro}</div>
              <div className={`aui-info`} style={{'padding': '0', 'marginTop': '1.4rem'}}>
                <div className={`aui-info-item`}>
                  <span className={`aui-font-size-12 aui-label aui-label-outlined`}
                        style={{'padding': '0.2rem'}}>{searchList.author}</span>
                  <span className={`aui-margin-l-5 aui-font-size-12 aui-label aui-label-outlined`}
                        style={{'padding': '0.2rem'}}>{searchList.size}</span>
                  <span className={`aui-margin-l-5 aui-font-size-12 aui-label aui-label-outlined`}
                        style={{'padding': '0.2rem'}}>{searchList.fullflag}</span>
                </div>
                <div className={`aui-info-item`}>
                  <div className={` aui-font-size-12 aui-label aui-label-outlined`}
                       style={{'padding': '0.2rem'}}>{string.truncate(searchList.sortname, 2, '')}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
        }

        {/* 记录 */}
        {financeList &&
        <li className={`aui-list-item`}>
          <div className={`aui-list-item-inner`}>
            <div className={`aui-list-item-title`} style={{'fontSize': '0.7rem'}}>{financeList.text}</div>
            <div className={`aui-list-item-right`} style={{'fontSize': '0.7rem'}}>{financeList.time}</div>
          </div>
        </li>
        }

        {/* 记录 */}
        {financeList1 &&
        <li className={`aui-padded-10`}>
          <p className={`aui-font-size-14`}>{financeList1.text1}</p>
          <p className={`aui-font-size-12 aui-text-align-r aui-margin-t-5`}>{financeList1.time}</p>
          <p className={`aui-font-size-12 aui-text-align-r aui-margin-t-5`}>{financeList1.text2}</p>
        </li>
        }

        {/* 签到排行 */}
        {signList &&
        <li class="aui-list-item aui-list-item-middle">
          <div class="aui-media-list-item-inner">
            <div class="aui-list-item-media" style={{'width': '2rem'}}>
              {index < 3 ?
                <span class="mixs-iconfont mixs-icon-flag flag"
                      className={'flag-color-' + index}><i>{parseInt(index) + 1}</i></span>
                : ''}
              {index >= 3 ?
                <span class="flag"><i style={{'color': '#000'}}>{parseInt(index) + 1}</i></span>
                : ''}
            </div>
            <div class="aui-list-item-media" style={{'width': '3rem'}}>
              <img src={signList.avatar} onerror="this.src='/images/userhead.gif'"
                   class="aui-img-round aui-list-img-sm aui-margin-center" style={{'height': '2.3rem'}}/>
            </div>
            <div class="aui-list-item-inner">
              <div class="aui-list-item-text">
                <div
                  class="aui-list-item-title aui-font-size-14">{string.truncate(signList.nickname, 20, '...')}</div>
                <div class="aui-list-item-right aui-font-size-14" style={{'minWidth': '2rem'}}>{signList.con} 天
                </div>
              </div>
              <div class="aui-list-item-text">
                <div>
                  {signList.vip == 0 ?
                    <span class="aui-label aui-label-info aui-label-outlined">普通会员</span>
                    : ''}
                  {signList.vip == 1 ?
                    <span class="aui-label aui-label-danger aui-label-outlined">VIP</span>
                    : ''}
                  {signList.monthly == 1 ?
                    <span class="aui-label aui-label-success aui-label-outlined">包月</span>
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </li>
        }

        {/* 排行榜 */}
        {topList &&
        <List className="sub-list" onClick={() => {
          toBookCover(topList.articleid, this.context)
        }}>
          <Item
            arrow=""
            thumb={topList.thumburl}
            multipleLine
            align={'top'}
            extra={<><div className={`mixs-iconfont mixs-icon-flag flag ${'flag-color-' + topList.no}`}><i style={(parseInt(topList.no) < 10) ? {'left':'0.9rem'} : {}}>{topList.no}</i></div></>}
            onClick={() => {
            }}
          >
            <span className={`aui-font-size-16`}>{string.truncate(topList.articlename, 12, '...')}</span>
            <Brief style={{'marginTop':'10px'}}><span className={`aui-font-size-12`}>{string.truncate(topList.intro, 18, '...')}</span></Brief>
            <Brief style={{'marginTop':'0px'}}>
              <span className={`aui-font-size-12`}>{topList.author} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{topList.size} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{topList.fullflag} · </span>
              <span className={`aui-font-size-12 aui-margin-l-5`}>{string.truncate(topList.sortname, 2, '')}</span>
            </Brief>
          </Item>
        </List>
        }
      </>
    )
  }
}

export default index
