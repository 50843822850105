import React, {Component} from 'react'
import FinanceListItem from '@/components/FinanceListItem'
import {ListView, WingBlank, WhiteSpace} from 'antd-mobile'
import ctx from '@/assets/js/ctx'
import {axiosParam, signParam, toPage, toBookCover, toBookRead, toCatalog} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'
import TopNav from '@/components/TopNav'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import sdk from '@/routes/wxShare'

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
      defaltState: 1,
      className: '',
      filterId: 0,
      page: 0,
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      refreshing: false,
      isLoading: true,
      hasMore: true,
      dataType: 'readLogList',
      totalCount: 0,
      pagesize: 10,
      useBodyScroll: false
    }
  }

  onRefresh = () => {
    this.setState({refreshing: true, isLoading: true, hasMore: true, page: 0, dataList: []});
    this.reqBookList();
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqBookList();
  }

  reqBookList = (action) => {
    const {page, stamp} = this.state
    let params = {
      page: page + 1
    };
   
    params = $.extend(params, axiosParam(this.context,stamp))

    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'user/getUserReadLogList', params, this)
    }, 0);
  }

  onRef = (ref) => {
    this.financeListItem = ref
  }

  render() {
    const {dataSource, isLoading, refreshing, hasMore, dataType, totalCount, pagesize, useBodyScroll, setting} = this.state

    const {onEndReached, onRef, onRefresh} = this

    const style = {'marginTop': '45px','height':document.documentElement.clientHeight - 45}

    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 阅读记录' : ''}>
        </DocumentTitle>
        <TopNav name={'阅读记录'} history={this.props.history}/>
        <>
          <div className={`aui-content user-read-log aui-bg-wx ${getTheme()}`}>
            <WingBlank>
              <WhiteSpace style={{}}/>
              <ul className={`aui-list aui-media-list history-book aui-bg-none`}>
                <FinanceListItem onEndReached={onEndReached} hasMore={hasMore}
                                 dataSource={dataSource} onRefresh={onRefresh} isLoading={isLoading}
                                 refreshing={refreshing} onRef={onRef} totalCount={totalCount} pagesize={pagesize}
                                 dataType={dataType} style={style} useBodyScroll={useBodyScroll} {...this.props}/>
              </ul>
            </WingBlank>
          </div>
        </>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    }, () => {
      this.reqBookList();
      sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 阅读记录' : '',url: window.location.href, type: 'common'},timestamp,this.context)
    })
  }
}

export default index
