import React, {Component} from 'react'
import axios from '@/assets/js/axios'
import {Toast, SegmentedControl, ListView, PullToRefresh,List,Flex,WhiteSpace,Button} from 'antd-mobile'
import ctx from '@/assets/js/ctx'
import MenuMini from '@/components/MenuMini'
import Menu from '@/components/Menu'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {axiosParam, signParam, toPage, toBookCover, toBookRead,string, toOneCheckout} from '@/assets/js/string'
import {setHtmlBackground} from '@/assets/js/content.js'
import BannerGroup from '@/components/BannerGroup'
import BookListItem from '@/components/BookListItem'
import {loadingMore} from '@/assets/js/content'
import NavTxt from '@/components/NavTxt'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import sdk from '@/routes/wxShare'
import NavigatorTabbar  from '@/components/NavigatorTabbar'
const Item = List.Item;
const Brief = Item.Brief;

function LikeBody(props) {
  return (
    <>
    <div className={`aui-margin-t-5 aui-bg-white aui-border-radius-10`}>
      <ul className={``}>
        {props.children}
      </ul>
    </div>
      </>
  );
}

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor(props) {
    super(props)
    this.state = {
      recommendList: [],
      page: 0,
      deleteState: false,
      system: 'ios',
      showOperateBar: false,
      isSelectAll: false,
      selectArr: [],
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      refreshing: false,
      isLoading: true,
      hasMore: true,
      dataType: 'bookShelfList',
      pagesize: 10,
      useBodyScroll: true
    }
  }

  onRefresh = () => {
    this.setState({
      refreshing: true,
      isLoading: true,
      hasMore: true,
      page: 0,
      dataList: []
    }, () => {
      this.reqBookList();
    });

  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqBookList();
  }

  reqBookList = (action) => {
    const {page, pagesize, stamp} = this.state
    let params = {
      page: page + 1,
      pagesize: pagesize
    };
    params = $.extend(params, axiosParam(this.context,stamp))

    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'user/getUserBookList', params, this)
    }, 0);
  }

  /* 删除收藏 */
  deleteUserBook = (e,articleId) => {
    const ev = e || window.event
    ev.stopPropagation()
    const {stamp} = this.state
    if (!articleId) {
      Toast.fail('请选择删除的书籍', 1);
      return;
    }
    var tmpArr = [];
    tmpArr.push(articleId);

    let params = {
      articleid: tmpArr,
    }
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `user/postDeleteFavoriteBook`, params).then((res) => {
      Toast.success('书籍删除成功', 1, () => {
        this.onRefresh();
      });

    })
  }

  onRef = (ref) => {
    //this.financeListItem = ref
  }

  onTabChange = (e) => {
    // eslint-disable-next-line default-case
    switch (e.nativeEvent.selectedSegmentIndex) {
      case 1:
        
        break;
      case 0:
        toPage('orderList',this.context)
        break;
      }
  }

  render() {
    const {hasMore, recommendList, dataSource, refreshing, isLoading, pagesize, dataType, useBodyScroll, setting} = this.state
    const {deleteUserBook, onEndReached, onRefresh, onRef, props,onTabChange} = this

    const style = {'marginTop': '55px','height':document.documentElement.clientHeight - 55}


    const antIcon = <div className={`am-icon-dot`} />
    
    function MyBody(props) {
      return (
        <>
          <SegmentedControl
            values={[ '我的订单','我的书架']}
            tintColor={'#ff0000'}
            selectedIndex={1}
            style={{ height: '40px', 'margin': '15px' }}
            onChange={onTabChange}
            />
          <div className="am-list-body my-body">
          {props.children}
          </div>
        </>
      );
    }
    
    return (
      <><DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 书架' : ''}>
      </DocumentTitle>
        <div className={`aui-content book-shelf user-read-log aui-bg-wx ${getTheme()}`}>
          <ul className={`aui-list aui-media-list history-book aui-bg-none`}>
            <ListView
              ref={el => this.lv = el}
              dataSource={dataSource}
              pageSize={pagesize}
              useBodyScroll={false}
              style={{ marginTop: '0px', height: document.documentElement.clientHeight -75 ,overflow:'hidden',overflowY:'scroll'}}
              renderBodyComponent={() => <MyBody/>}
              renderSectionBodyWrapper={sectionID => <LikeBody/>}
              renderRow={
                (item, sectionId, rowID) => {
                  return <>
                     <div className={`aui-list aui-media-list history-book aui-bg-tran aui-margin-t-0 aui-padded-t-5`}>
                      <li className={`aui-list-item aui-bg-none`}>
                        <div className={`aui-media-list-item-inner`}>
                          <div
                            className={`aui-list-item-media aui-margin-0`}
                            style={{ width: 'auto' }}
                          >
                            <img
                              src={item.bookimage}
                              className={`square`}
                              onClick={() => {
                                toBookCover(item.articleid, this.context);
                              }}
                            onError={e => {
                              e.target.src = 'http://i.mixs.cn/upload/cover/default.jpeg';
                            }}
                            />
                          </div>
                          <div
                            className={`aui-list-item-inner aui-padded-r-5`}
                          >
                            <div className={`aui-list-item-text`}>
                              <span
                                className={`aui-font-size-14 aui-text-color-black aui-text-bold`}
                              >
                                {string.truncate(
                                  item.articlename,
                                  12,
                                  '...'
                                )}
                              </span>
                            </div>
                            <Brief>
                              <div
                                className={`aui-margin-t-10 aui-text-align-l`}
                              >
                                <span className={`aui-font-size-14 aui-text-color-black`}>
                                  更新：{item.lastchaptername}
                                </span>
                              </div>
                            </Brief>
                            <WhiteSpace style={{}}/>
                              <Brief>
                              <div
                                className={`aui-list-item-text aui-margin-t-0 aui-text-align-r all-sale`}
                              >
                                <span
                                  className={`aui-font-size-14 aui-text-color-pink`}
                                >
                                  读至：{item.readchaptername}
                                </span>
                              </div>
                            </Brief>
                          </div>
                        </div>
                        <Brief>
                              <div
                                className={`aui-margin-t-0 aui-text-align-r aui-padded-b-10`}
                              >
                                 <Flex className={`aui-font-size-14 aui-float-right`}>
                              <Flex.Item>
                                <Button size="small" type="warning" inline className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}   onClick={() => {
                                            toOneCheckout(item.articleid,this.context)
                                  }}>买有声书</Button>
                                    <Button size="small" inline className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`} onClick={() => {
                                      toBookRead(item.articleid, item.readchapterid, this.context)
                                    }}>继续阅读</Button>
                                    <Button size="small" inline className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`} onClick={(event) => {
                                      deleteUserBook(event, item.articleid)
                                    }}>移除书架</Button>
                                  </Flex.Item>
       
                                </Flex>
                                
                              </div>
                            </Brief>
                      </li>
                    </div>
                  </>
                }
              }
              renderFooter={() => (<div style={{'clear': 'both', padding: 0, textAlign: 'center'}}>
                {isLoading ? antIcon : (hasMore ? '' : '没有更多')}
              </div>)}
              pullToRefresh={<PullToRefresh
                indicator={{activate: antIcon, deactivate: antIcon, release: antIcon, finish: antIcon}}
                refreshing={refreshing}
                distanceToRefresh={55}
                onRefresh={onRefresh}
              />}
              onEndReached={onEndReached}
              onEndReachedThreshold={20}
            />
          </ul>
        </div>

          <NavigatorTabbar selectedTab="orderList"></NavigatorTabbar>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    }, () => {
      this.reqBookList()
      sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 书架' : '',url: window.location.href, type: 'common'},timestamp,this.context)
    })

  }
}

export default index
