import React, {Component} from 'react'
import styles from './index.module.scss'
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {
  Page,
  Cells,
  CellsTitle,
  Cell,
  CellHeader,
  CellBody,
  CellFooter,
  Msg,Footer, FooterLinks, FooterLink, FooterText
} from 'react-weui';
//import styles
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import {getTheme, getSetting} from '@/assets/js/setting'
import sdk from '@/routes/wxShare'

export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      step: 1
    }
  }

  toNext = (index) => {
    this.setState({
      step: index
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const {step} = this.state
    const {toNext, goBack,props} = this
    return (
      <>
        <DocumentTitle title='投诉'>
        </DocumentTitle>

        {step == 1 ?
          <div class="page">
            <Page className="cell" title="List" subTitle="列表">
              <CellsTitle style={{'background':'#f5f5f9','paddingTop':'.77em','marginTop':'0px','marginBottom':'0px'}}>请选择投诉该网页的原因：</CellsTitle>
              <Cells onClick={() => {
                toNext(2)
              }}>
                <Cell access>
                  <CellBody>
                    网页包含欺诈信息（如：假红包）
                  </CellBody>
                  <CellFooter/>
                </Cell>
                <Cell access>
                  <CellBody>
                    网页包含色情信息
                  </CellBody>
                  <CellFooter/>
                </Cell>
                <Cell access>
                  <CellBody>
                    网页包含暴力恐怖信息
                  </CellBody>
                  <CellFooter/>
                </Cell>
                <Cell access>
                  <CellBody>
                    网页包含政治敏感信息
                  </CellBody>
                  <CellFooter/>
                </Cell>
                <Cell access>
                  <CellBody>
                    网页在收集个人隐私信息（如：钓鱼链接）
                  </CellBody>
                  <CellFooter/>
                </Cell>
                <Cell access>
                  <CellBody>
                    网页包含诱导分享/关注性质的内容
                  </CellBody>
                  <CellFooter/>
                </Cell>
                <Cell access>
                  <CellBody>
                    网页可能包含谣言信息
                  </CellBody>
                  <CellFooter/>
                </Cell>
                <Cell access>
                  <CellBody>
                    网页包含赌博信息
                  </CellBody>
                  <CellFooter/>
                </Cell>
              </Cells>
              <CellsTitle><a>请选择投诉该网页的原因：</a></CellsTitle>
              <CellsTitle style={{'text-align': 'center'}}><a>投诉须知</a></CellsTitle>
            </Page>
          </div>

          : ''}
        {step == 2 ?
          <Page className="cell" title="List" subTitle="列表">
            <CellsTitle style={{'background':'#f5f5f9','paddingTop':'.77em','marginTop':'0px','marginBottom':'0px'}}>你可以：</CellsTitle>
            <Cells onClick={() => {
              toNext(3)
            }}>
              <Cell access>
                <CellBody>
                  提交给团队审核
                </CellBody>
                <CellFooter/>
              </Cell>
            </Cells>
            <CellsTitle style={{'text-align': 'center'}}><a>投诉须知</a></CellsTitle>
          </Page>
          : ''}
        {step == 3 ?
          <Page className="msg_success">
            <Msg
              type="success"
              title="投诉已提交"
              description="团队会尽快核实，并通过'团队'通知你审核结果。感谢你的支持。"
              buttons={[{
                type: 'primary',
                label: '关闭',
                onClick: props.history ? props.history.goBack : false
              }]}
            />
          </Page>
          : ''}

      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    })
  }
}

export default index
