import React, {Component} from 'react'
import BookListItem from '@/components/BookListItem'
import {ListView, PullToRefresh} from 'antd-mobile'
import {toPage, toBookCover, toBookRead} from '@/assets/js/string'
import ctx from '@/assets/js/ctx'
import {string} from '@/assets/js/string'


function MyBody(props) {
  return (
    <div className="am-list-body my-body">
      {props.children}
    </div>
  );
}

function LikeBody(props) {
  return (
    <div className={`aui-margin-t-5 aui-bg-white aui-border-radius-10`}>
      <ul className={``}>
        {props.children}
      </ul>
    </div>
  );
}
export class index extends Component {
  static contextType = ctx

  componentDidMount() {
    this.props.onRef(this)
  }

  toTop = (x, y) => {
    setTimeout(() => this.lv.scrollTo(x, y), 800);
  }

  deleteUserBook=(articleId)=>{
    if(this.props.deleteUserBook!==null){
      this.props.deleteUserBook(articleId);
    }
  }


  changeHomeListFirstHeight = (e) => {
    
  };

  render() {
    const {changeHomeListFirstHeight} = this
    const {dataSource, onEndReached, hasMore, onRefresh, refreshing, isLoading, dataType, useBodyScroll, pagesize, style} = this.props
    const antIcon = <div className={`am-icon-dot`}/>
    return (
      <>
        <ListView
          ref={el => this.lv = el}
          dataSource={dataSource}
          useBodyScroll={useBodyScroll}
          style={style}
          initialListSize={pagesize}
          pageSize={pagesize}
          renderBodyComponent={() => <MyBody />}
          renderSectionBodyWrapper={sectionID => <LikeBody/>}
          renderRow={
            (rowData, sectionId, rowID) => {
              switch (dataType) {
                case 'walfareList': {/* 福利记录 */}
                case 'payList': {/* 充值记录 */}
                case 'propsList': {/* 道具记录 */}
                case 'monthList': {/* 包月记录 */}
                case 'chapterList': {/* 章节记录 */}
                  return <BookListItem {...this.props} financeList1={rowData}  changeHomeListFirstHeight={changeHomeListFirstHeight}/>
                  break;
                case 'fenSiList':
                  return <BookListItem {...this.props} index={rowID} fenSiList={rowData}/>
                  break;
                case 'cataLogList':
                  return <BookListItem {...this.props} cataLogList={rowData}  changeHomeListFirstHeight={changeHomeListFirstHeight}/>
                  break;
                case 'signList':
                  return <BookListItem {...this.props} index={rowID} signList={rowData}/>
                  break;
                case 'classifyList':
                case 'searchList':
                  return <BookListItem {...this.props} classifyList={rowData}/>
                  break;
                case 'readLogList':
                  return <BookListItem {...this.props} readLogList={rowData}  changeHomeListFirstHeight={changeHomeListFirstHeight}/>
                case 'bookShelfList':
                  return <BookListItem {...this.props} deleteUserBook={this.deleteUserBook} bookShelfList={rowData}/>
                  break;
              }

            }
          }
          style={style}
          renderFooter={() => (<div style={{'clear': 'both', padding: 0, textAlign: 'center'}}>
            {isLoading ? antIcon : (hasMore ? '' : '没有更多')}
          </div>)}
          pullToRefresh={<PullToRefresh
            indicator={{activate: antIcon, deactivate: antIcon, release: antIcon, finish: antIcon}}
            refreshing={refreshing}
            distanceToRefresh={55}
            onRefresh={onRefresh}
          />}
          onEndReached={onEndReached}
          onEndReachedThreshold={20}
          onScroll={this.props.onScroll}
        />
      </>
    )
  }
}

export default index
