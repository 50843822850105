import React, {Component} from 'react'
import axios from '@/assets/js/axios.js'
import {getUserInfo,quitLogin} from '@/assets/js/user'
import HeaderNav from '@/components/HeaderNav'
import WxPay from '@/components/WxPay'
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam, axiosParam, toHome, toBookRead, toCatalog} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {PullToRefresh, Flex, Button, List, ListView,WhiteSpace,WingBlank} from 'antd-mobile'
import TopNav from '@/components/TopNav'
import Title from '@/components/Title'
import {loadingMore} from '@/assets/js/content'
import {string} from '@/assets/js/string'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import sdk from '@/routes/wxShare'
const Item = List.Item;
const Brief = Item.Brief;

function MyBody(props) {
  return (
    <div className="am-list-body my-body">
      {props.children}
    </div>
  );
}

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
      userInfo: {},
      siteInfo: {},
      ruleId: 0,
      payMoney: '-',
      page: 0,
      isAllLoaded: false,
      financeRuleList: [],
      financeMonthlyRuleList: [],
      isFirstLoaded: true,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      pagesize: 10,
      isLoading: true,
      refreshing: true,
      down: true,
      hasMore: true,
      useBodyScroll: true,
      myInfo: {
        monthly: {
          day: "未开通",
          date: "开通"
        },
        user_readtime: 0
      },
    }
  }

  reqFinanceList = (action) => {
    const {stamp,typeId,orderId} = this.state
    let params = {
      siteId: 1,
      orderId: orderId,
      typeId: typeId,
      pos: 'read'
    };

    params = $.extend(params, axiosParam(this.context,stamp))

    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'pay/getVoiceFinanceList', params, this)
    }, 10);
  }

  onRefresh = () => {
    this.setState({dataList:[],isFirstLoaded:true,refreshing: true});
    this.reqFinanceList();
  }

  onChangePay = (payMoney,ruleId) => {

    this.setState({
      ruleId: ruleId,
      payMoney: payMoney
    }, () => {
      for (var j = 0; j <= this.state.dataList.length - 1; j++) {
        if (this.state.dataList[j].money == this.state.payMoney) {
          this.setState({
            payMoney: this.state.dataList[j]['money']
          });
          break;
        }
      }
    })

  }

  onRef = (ref) => {
    this.child = ref
  }

  toWxPay = (e) => {
    this.child.init(this.state.payMoney,this.state.ruleId)
  }

  payNotify = (e) => {
    window.location.reload();
  }

   //用户信息
   reqMyInfo = () => {
    const {stamp} = this.state

    let params = {};

    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `user/getUserHome`, params).then((res) => {
      this.setState({
        myInfo: res.data,
        isLoadFinished: true,
        refreshing: false
      })
    })
  }

  LikeBody = (props) => {
    return (
      <>
        <div className={`blank`}></div>
        <div className={`aui-margin-15 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}>
          <div class="aui-content aui-pay-body">
            <section class="aui-grid">
              <div class="aui-row">
                <WhiteSpace size="sm" />
                {props.children}
                <WhiteSpace size="sm" />
              </div>
            </section>
          </div>
        </div>

        <div className={`aui-row-padded buy-body aui-margin-l-15 aui-margin-r-15`}>
          <div className={` aui-margin-t-10`} style={{'marginTop': '20px!important'}}>
            <Button className={`aui-border-radius-10 btn-large`} type="warning" onClick={() => {
              this.toWxPay()
            }}>立即充值：{this.state.payMoney / 100} 元
            </Button>
          </div>
        </div>
       
      </>

    );
  }

  render() {
    const {userInfo, refreshing, myInfo, financeMonthlyRuleList, ruleId, isFirstLoaded, dataSource, pagesize, payMoney, setting} = this.state

    const {getUserInfo, onRefresh, onChangePay, toWxPay, LikeBody} = this
    const antIcon = <div className={`am-icon-dot`}/>

    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | VIP开通' : ''}>
        </DocumentTitle>
        <TopNav name={'VIP开通'} history={this.props.history}/>

        <div className={`aui-pay-index aui-bg-wx ${getTheme()}`}>
          <ListView
            ref={el => this.lv = el}
            dataSource={dataSource}
            useBodyScroll={false}
            pageSize={pagesize}
            style={{'marginTop': '45px', 'height': document.documentElement.clientHeight - 45}}
            renderSectionHeader={(sectionData, sectionID) => (
              <>
                <Flex
                  className={`userinfo aui-border-radius-10 aui-bg-white aui-margin-t-10`}>
                  <Flex.Item>
                    <List className={`avatar`}>
                      <Item
                        arrow="horizontal"
                        thumb={userInfo.avatar}
                        multipleLine
                        arrow=""
                        onClick={() => {
                        }}
                      >
                        {userInfo.nickname}
                        <Brief>ID:{userInfo.userid}</Brief>
                      </Item>
                    </List>
                  </Flex.Item>
                  <Flex.Item>
                    <List className="my-list">
                      <Item arrow="horizontal" multipleLine arrow="">
                        <span className={`aui-font-size-14`}>VIP会员：{myInfo.monthly.day}</span>
                      </Item>
                    </List>
                  </Flex.Item>
                </Flex>
              </>
            )}
            renderBodyComponent={() => <MyBody/>}
            renderSectionBodyWrapper={sectionID => <LikeBody/>}
            renderRow={
              (rowData, sectionId, rowID) => {
                if (isFirstLoaded && rowData.isDefault == 1)
                {
                  this.state.ruleId = rowData.ruleid
                  this.state.payMoney = rowData.money
                  this.state.isFirstLoaded = false
                }
                switch (rowData.typeid) {
                  case 1:
                    return <List className={`${rowID > 0 ? 'aui-margin-t-10' : ''} btn-large ${ payMoney == rowData.money ? 'current' : '' }`} onClick={() => {
                      onChangePay(rowData.money,rowData.ruleid)
                    }}>
                      <Item thumb={payMoney == rowData.money ? require('@/assets/icon/right.png') : ''} extra={<span className={`aui-font-size-14`}>{rowData.name}</span>}><span className={`aui-font-size-14`}>{rowData.egold} {this.context.siteinfo && this.context.siteinfo.currencyname} { (1 == 1) ? ('+ 送 ' + rowData.esilver + ' ' + (this.context.siteinfo && this.context.siteinfo.esilvername) + '') : ''}</span></Item>
                    </List>
                    break;
                  case 2:
                    return <List className={`aui-margin-t-10 btn-large ${ payMoney == rowData.money ? 'current' : '' }`} onClick={() => {
                      onChangePay(rowData.money,rowData.ruleid)
                    }}>
                      <Item thumb={payMoney == rowData.money ? require('@/assets/icon/right.png') : ''} extra={<span className={`aui-font-size-14`}>{string.delHtmlTag(rowData.name)}</span>}><span className={`aui-font-size-14`}>{string.delHtmlTag(rowData.desc)}</span></Item>
                    </List>
                    break;
                    case 3:
                      return <List className={`aui-margin-t-10 btn-large ${ payMoney == rowData.money ? 'current' : '' }`} onClick={() => {
                        onChangePay(rowData.money,rowData.ruleid)
                      }}>
                        <Item thumb={payMoney == rowData.money ? require('@/assets/icon/right.png') : ''} extra={<span className={`aui-font-size-14`}>{string.delHtmlTag(rowData.name)}</span>}><span className={`aui-font-size-14`}>{string.delHtmlTag(rowData.desc)}</span></Item>
                      </List>
                      break;
                }

              }
            }
            pullToRefresh={<PullToRefresh
              indicator={{activate: antIcon, deactivate: antIcon, release: antIcon, finish: antIcon}}
              refreshing={refreshing}
              distanceToRefresh={55}
              onRefresh={onRefresh}
            />}
          />
        </div>
        <WxPay onRef={this.onRef} payNotify={this.payNotify}></WxPay>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    var typeId = getQueryString('typeId')
    const { orderid } = this.props.match.params;
    this.setState({
      stamp: timestamp,
      typeId: typeId,
      orderId: orderid
    }, () => {
      getUserInfo(this.context, timestamp)
        .then((res) => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context)
            }
          } else {
            this.setState({
              userInfo: res.data
            })
            this.reqMyInfo()
            this.reqFinanceList()
            // sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | VIP开通' : '',url: window.location.href, type: 'common'},timestamp,this.context)
          }
        })
    })
  }
}

export default index
