import React, { Component, lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import MyGlobal from '../../components/MyGlobal'; // 与路由相关的内置组件
import axios from '@/assets/js/axios';
import { getQueryString, isNull } from '@/assets/js/string';
import sdk from '@/routes/wxShare';

/*
    高阶组件不是React里面的内置组件，它是一种设计思想

    定义一个函数，传入的参数是一个组件，在这个函数中对传入的组件进行各种处理，最后导出处理之后的组件
*/
function checkToken(OldComponent) {
  class NewComponent extends Component {
    constructor() {
      super();
      this.state = {
        token: '',
      };
    }

    render() {
      const { token } = this.state;
      return (
        <>
          {/* 传入的组件本身的props会没了，所以必须向传入的组件中注入当前组件的props*/}
          {token && <OldComponent {...this.props} token={token} />}
        </>
      );
    }

    componentDidMount() {
      const token = localStorage.getItem('token');
      var openid = localStorage.getItem('openid');
      var debug = getQueryString('debug');
      var timestamp = localStorage.getItem('timestamp');
      var sign = localStorage.getItem('sign');
      var oldVer = localStorage.getItem('ver');
      var ver = getQueryString('ver');
      var channel = getQueryString('channel');
      var versionId = getQueryString('versionId');
      var site = getQueryString('site');
      var logintype = localStorage.getItem('logintype');
      logintype = !logintype ? 1 : logintype;
      var userId = localStorage.getItem('userId');

      if (debug == 'error') {
        const token = localStorage.setItem('token', 'ok');
        var openid = localStorage.setItem('openid', 'oWS2r6SCzgGHd4VG8TN-WmepMjBo'); //oi0GuwKGCqRGR43TgybjmuCV28xA  | otq750WA4OVPXd6OFvh1i_npneXQ
        var timestamp = localStorage.setItem('timestamp', '1581468120'); //1581468120
        var channel = localStorage.setItem('channel', '');
        var sign = localStorage.setItem('sign', '106657400266bed59ac700ba8770856d'); //e8d789e557be89736b89240ea73cf598  |  106657400266bed59ac700ba8770856d
        var oldVer = localStorage.setItem('ver', 'v1'); //v13 | v3
        var ver = localStorage.setItem('ver', 'v1'); //v13 | v3
        var versionId = localStorage.setItem('versionId', '3.0'); //2.0
        var site = localStorage.setItem('site', 'fmmixs');
        var userId = localStorage.setItem('userId', '85686');
      }

      if (isNull(ver) || isNull(versionId)) {
        window.location.href = '/notFound';
        return;
      }
      let isLogin = true;
      const whiteList = [
        '/home',
        '/classify',
        '/topList/ly',
        '/topList/female',
        '/topList/male',
        '/topList/msale',
        '/topList/size',
        '/topList/last',
        '/topList/new',
        '/topList/warrant',
        '/userlogin',
        '/reg',
        '/alipay',
        '/download',
      ]; // 不需要登陆的页面
      for (var i in whiteList) {
        if (whiteList[i].toLowerCase() == this.props.location.pathname.toLowerCase()) {
          this.setState({
            token: 'ok',
          });
          return;
        }
      }

      // console.log('userId=' + userId + '|timestamp='+timestamp + '|sign='+sign + '|ver='+ver + '|oldVer='+oldVer)
      //需要登录
      if (
        navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 ||
        typeof navigator.wxuserAgent !== 'undefined'
      ) {
        //微信环境
        if (isNull(userId) || isNull(timestamp) || isNull(sign) || ver != oldVer) {
          localStorage.clear();
          let return_uri =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            window.location.search;
          let router =
            '?ver=' +
            ver +
            '&channel=' +
            channel +
            '&versionId=' +
            versionId +
            '&site=' +
            site +
            '';
          window.location.href =
            '/Login' + router + '&redirectUrl=' + encodeURIComponent(return_uri);
        }
      } else {
        //非微信环境
        if (isNull(userId) || isNull(timestamp) || isNull(sign) || ver != oldVer) {
          localStorage.clear();
          let return_uri =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            window.location.search;
          let router =
            '?ver=' +
            ver +
            '&channel=' +
            channel +
            '&versionId=' +
            versionId +
            '&site=' +
            site +
            '';
          window.location.href =
            '/UserLogin' + router + '&redirectUrl=' + encodeURIComponent(return_uri);
        }
      }

      this.setState({
        token: 'ok',
      });

      //this.props.history.push('/login')
    }
  }

  return NewComponent;
}

// 定义一个函数，参数是需要被懒加载的组件
function lazyLoad(component) {
  // 返回一个函数式组件
  return function () {
    // 使用lazy组件，将传入的组件包装成懒加载组件
    const LazyComponent = lazy(component);

    // 使用withRouter组件，注入路由的配置信息
    const LazyRouteComponent = withRouter(LazyComponent);

    return (
      // 使用Suspense组件，为懒加载提供loading样式
      <Suspense
        fallback={
          <div
            style={{
              textAlign: 'center',
              fontSize: '0.3rem',
            }}
          >
            正在加载中....
          </div>
        }
      >
        <LazyComponent />
      </Suspense>
    );
  };
}

function toLink(e) {
  var ver = e.urlParam.ver;
  window.location.href = '/my' + '?ver=' + ver;
}

function wxShare(context) {
  var timestamp = new Date().getTime();
  
  let param = {
    url: window.location.href,
    type: 'common',
  };
  sdk.getJSSDK(param, timestamp, context);
}

export { toLink, checkToken, lazyLoad, wxShare };
