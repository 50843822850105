import React, {Component} from 'react'
import {TabBar} from 'antd-mobile'
import '@/config/run.js'
import {withRouter} from 'react-router-dom'
import ctx from '@/assets/js/ctx'
import {toPage} from '@/assets/js/string'

export class index extends Component {
  static contextType = ctx

  render() {
    const { currentTab } = this.props

    return (
      <footer className={`aui-bar aui-bar-tab aui-footer-bar aui-padded-t-0 aui-padded-b-15 ${'footer-height-' + global.constants.systemFooter } `}>
        <div className={`aui-bar-tab-item ${ currentTab == 'bookshelf' ? 'aui-active' : ''} `} onClick={() => {
          toPage('bookShelf',this.context)
        }}>
          <i className={`aui-iconfont mixs-iconfont mixs-icon-index`}></i>
          <div className={`aui-bar-tab-label aui-footer-text aui-display-inline`}>书架</div>
        </div>
        <div className={`aui-bar-tab-item ${ currentTab == 'home' ? 'aui-active' : ''} `} onClick={() => {
          toPage('home',this.context)
        }}>
          <i className={`aui-iconfont mixs-iconfont mixs-icon-hot`}></i>
          <div className={`aui-bar-tab-label aui-footer-text aui-display-inline`}>精选</div>
        </div>
        <div className={`aui-bar-tab-item ${ currentTab == 'my' ? 'aui-active' : ''} `} onClick={() => {
          toPage('my',this.context)
        }}>
          <i className={`aui-iconfont mixs-iconfont mixs-icon-home`}></i>
          <div className={`aui-bar-tab-label aui-footer-text aui-display-inline`}>我的</div>
        </div>
      </footer>
    )
  }
}

export default index
