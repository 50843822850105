import React, {Component} from 'react'
import {ListView, PullToRefresh} from 'antd-mobile'
import BookListItem from '@/components/BookListItem'

export class index extends Component {

  submitReplayComment = (replayid) => { // 学习挣钱，调用父组件方法
    this.props.submitReplayComment(replayid);
  }

  render() {
    const {dataSource, onEndReached, hasMore, onRefresh, refreshing, isLoading, pagesize,totalCount} = this.props

    const {submitReplayComment} = this

    const antIcon = <div className={`am-icon-dot`}/>
    return (
      <>

        <ListView
          dataSource={dataSource}
          ref={el => this.lv = el}
          useBodyScroll={true}
          initialListSize={totalCount >= pagesize ? 0 : totalCount}
          pageSize={pagesize}
          renderRow={
            (rowData, sectionId, rowID) => {
              return <BookListItem {...this.props} reviewList={rowData}/>
            }
          }
          renderFooter={() => (<div style={{'clear':'both','width':'100%','padding': '0', 'textAlign': 'center'}}>
            {isLoading ? antIcon : (hasMore ? '' : '没有更多')}
          </div>)}
          pullToRefresh={<PullToRefresh
            indicator={{activate: antIcon, deactivate: antIcon, release: antIcon, finish: antIcon}}
            refreshing={refreshing}
            distanceToRefresh={55}
            onRefresh={onRefresh}
          />}
          onEndReached={onEndReached}
          onEndReachedThreshold={0}
          onScroll={this.props.onScroll}
        />
      </>
    )
  }
}

export default index
