import React, { Component, useEffect } from "react";
import { Flex, WhiteSpace, Toast } from "antd-mobile";
import axios from "@/assets/js/axios.js";
import ctx from "@/assets/js/ctx";
import {
  getQueryString,
  toUserReg,
  axiosParam,
  toHome,
} from "@/assets/js/string";
import { Button, Form, Input, List, AutoCenter } from "antd-mobile-v5";
import TopNav from "@/components/TopNav";
import $ from "jquery";
import { getTheme, getSetting } from "@/assets/js/setting";
export class index extends Component {
  static contextType = ctx;

  constructor(contextType) {
    super();
    this.state = {
      redirectUrl: "",
      num: 0,
      code: "",
    };
  }

  onLogin = () => {
    const { stamp, mobile, code } = this.state;
    console.log(mobile);
    var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!myreg.test(mobile)) {
      Toast.fail("手机号格式不正确", 1);
      return;
    }
    if (code.length < 6) {
      Toast.fail("验证码不能为空", 1);
      return;
    }
    let params = {
      mobile: mobile,
      code: code,
      login_type: 14,
    };
    params = $.extend(params, axiosParam(this.context, stamp));
    this.context.axios
      .post(global.constants.apiUrl + `login/postMobileLogin`, params)
      .then((response) => {
        if (response.code.toString() === "1") {
          localStorage.setItem("ver", response.data.ver);
          localStorage.setItem("userId", response.data.userid);
          localStorage.setItem("timestamp", response.data.timestamp);
          localStorage.setItem("sign", response.data.sign);
          localStorage.setItem("site", response.data.site);
          localStorage.setItem("logintype", 14);
          toHome(this.context);
        } else {
          Toast.fail(response.msg, 2);
        }
      });
  };

  onChangeMobile = (value) => {
    var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (myreg.test(value)) {
      this.setState({
        mobile: value,
      });
    }
  };

  onChangeCode = (value) => {
    this.setState({
      code: value,
    });
  };

  onSendCode = (value) => {
    let num = this.state.num;
    if (num > 0) {
      return;
    }
    let a = 30;
    let mobile = this.state.mobile;
    var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (myreg.test(mobile)) {
      const { stamp } = this.state;

      let params = {
        mobile: mobile,
        type: 1,
      };

      params = $.extend(params, axiosParam(this.context, stamp));

      axios
        .post(global.constants.apiUrl + `login/postVerifyCode`, params)
        .then((res) => {
          let code = res.code.toString();
          if (code === "1") {
            this.setState({
              num: a,
            });
            const t1 = setInterval(() => {
              a = a - 1;
              this.setState({
                num: a,
              });
              if (a == 0) {
                clearInterval(t1);
              }
            }, 1000);
            Toast.success(res.msg, 1);
          } else {
            Toast.fail(res.msg, 1);
          }
        });
    } else {
      Toast.fail("手机号格式不正确", 1);
    }
  };

  render() {
    const { onLogin, onChangeMobile, onSendCode, onChangeCode } = this;
    const { num, mobile, code } = this.state;

    return (
      <>
        <TopNav name={"登录"} history={this.props.history} />
        <div
          className={`user-login aui-bg-wx ${getTheme()}`}
          style={{ height: document.documentElement.clientHeight - 45 }}
        >
          <Form
            layout="horizontal"
            mode="card"
            onFinish={onLogin}
            footer={``}
            style={{ marginTop: "45px" }}
          >
            <Form.Item
              label="手机号"
              name="mobile"
              rules={[{ required: true, message: "手机号不能为空" }]}
            >
              <Input
                value={mobile}
                onChange={(val) => {
                  onChangeMobile(val);
                }}
                placeholder="手机号"
              />
            </Form.Item>
            <Form.Item
              label="短信验证码"
              name="code"
              rules={[{ required: true, message: "验证码不能为空" }]}
              extra={
                <a
                  className={`${
                    num > 0 ? "aui-text-color-gray" : "send-code"
                  } `}
                  onClick={onSendCode}
                >
                  {num == 0 ? "发送验证码" : num + "秒后重发"}
                </a>
              }
            >
              <Input
                placeholder="请输入"
                value={code}
                onChange={(val) => {
                  onChangeCode(val);
                }}
              />
            </Form.Item>
            <div className={`aui-padded-10`}>
              <Flex className={`aui-padded-t-5 aui-padded-b-15 `}>
                <Flex.Item>
                  <Button
                    type="primary"
                    className={`btn-large btn-checkout red`}
                  >
                    登录
                  </Button>
                </Flex.Item>
              </Flex>
            </div>
          </Form>
          <div
            className={`aui-font-size-18 aui-text-align-c`}
            onClick={() => {
              toUserReg(this.context);
            }}
          >
            没有账号，去注册>>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    var code = getQueryString("code");
    var ver = getQueryString("ver");
    var channel = getQueryString("channel");
    var versionId = getQueryString("versionId");
    var redirectUrl = getQueryString("redirectUrl");
    var site = getQueryString("site");
    this.setState(
      {
        redirectUrl: redirectUrl,
      },
      () => {}
    );
  }
}

export default index;
