import React, {Component} from 'react'
import axios from '@/assets/js/axios.js'
import {getUserInfo} from '@/assets/js/user'
import HeaderNav from '@/components/HeaderNav'
import WxPay from '@/components/WxPay'
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam, toOrderList, axiosParam, toBookRead, toCatalog,toHome} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {PullToRefresh, Flex, Button, List, ListView,WhiteSpace,WingBlank,Icon,Toast} from 'antd-mobile'
import {Popup,Divider,AutoCenter} from 'antd-mobile-v5'
import TopNav from '@/components/TopNav'
import Title from '@/components/Title'
import {loadingMore} from '@/assets/js/content'
import {toPage} from '@/assets/js/string'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import sdk from '@/routes/wxShare'
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
      showAliPay: true,
      isWechat: false,
      timer: '',
      percent: 0
    }
  }

  toPay = () => {
    Toast.loading('', 0, () => {
      console.log('Load complete !!!');
    });
    const {stamp,userId,ruleId,payMoney} = this.state
    const {payNotify} = this

    let params = {
      ruleId: ruleId,
      payMoney: payMoney,
      ver: this.context.ver,
      channel: this.context.channel,
      versionId: this.context.versionId,
      userId: userId,
      site: this.context.site,
      timestamp: stamp,
      sign: signParam(
        this.context.ver,
        this.context.channel,
        this.context.versionId,
        this.context.userId,
        this.context.site,
        stamp
      )
    };
    
    this.context.axios.post(global.constants.apiUrl + 'pay/preAliPayScan', params).then((res) => {
      Toast.hide()
      if (res.code <= 0) {
        Toast.fail(res.msg, 1)
      }
      else {
        const div=document.createElement('divform');
        div.innerHTML=res.msg;
        document.body.appendChild(div);
        document.forms[0].acceptCharset='utf-8';
        document.forms[0].submit();
      }
    });
  }

  iTimer = () => {
    this.timer = setInterval(
      ()=>{
          let {percent} = this.state
          percent +=1
          this.setState({percent:percent})
          if (percent >= 100){
            clearInterval(this.timer)
          }
      },300
  )
  };

  render() {
    const {isWechat, percent, paySuccess, showAliPay, ruleId, isFirstLoaded, dataSource, pagesize, payMoney, setting} = this.state

    const {getUserInfo, onRefresh, onChangePay, toWxPay, LikeBody} = this
    const antIcon = <div className={`am-icon-dot`}/>
const at = <div style={{'textAlign':'center'}}><span style={{'fontSize':'14px'}}>正在支付...</span><br/>请稍等</div>
    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 订单支付' : ''}>
        </DocumentTitle>
        {isWechat ?
        <div className={`ali-pay`} style={{'height': document.documentElement.clientHeight}}>
        <Flex className={`aui-bg-gray`} style={{'padding':'10px 0px 0px 15%'}}>
          <Flex.Item>
            <div className={`aui-font-size-14 aui-text-color-white aui-text-align-l aui-pull-left`} style={{'width':'180px'}}>
              请点击右上角...<br/>
              请选择【Safari浏览器】打开
            </div>
            <img src={require('@/assets/images/soft_arrow.png?ver=1')} className={`aui-pull-right`} style={{'width':'70px','marginRight':'20px'}}/>
          </Flex.Item>
        </Flex>
        <Flex className={`aui-bg-gray`} style={{'padding':'10px 15%'}}>
          <Flex.Item>
            <img src={require('@/assets/images/browser_icon_android.png?ver=1')}/>
          </Flex.Item>
        </Flex>
        <div>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <AutoCenter>
          <CountdownCircleTimer
              isPlaying
              duration={80}
              size={120}
              strokeWidth={6}
              trailStrokeWidth={3}
              trailColor='#ffae5d'
              colors={['#ffae5d']}
            >
            {({ remainingTime }) => at}
          </CountdownCircleTimer>
          </AutoCenter>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WingBlank>
                <WhiteSpace/>
                <div className={`more-info `}>
                <AutoCenter><Divider>FM高级会员俱乐部</Divider></AutoCenter>
                <div className={`aui-margin-t-0 aui-border-radius-10 aui-bg-white`} style={{'background':'#fdf2e8'}}>
                  <div class="aui-content aui-margin-b-0 aaa" style={{'margin': '0px'}}>
                    <ul class="aui-list aui-media-list aui-bg-none">
                      <li class="aui-list-item aui-bg-none">
                        <div class="aui-media-list-item-inner">
                          <div class="aui-list-item-inner">
                            <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10"><br/>
*充值2万元，加入FM高V俱乐部。<br/>
①获得2万FM点，3000FM券，可用于FM平台版权购买。<br/>
②尊享BOSS直呼，由FM老板一对一，对接服务。<br/>
③由FM老板亲自选品推荐，甄选头部S级作品。
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                </div>
                </WingBlank>
                <AutoCenter>
                <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
          <Button className={`aui-border-radius-10 btn-large aui-margin-t-15`} type="warning" onClick={() => {
              toPage('person',this.context)
            }} style={{'width':'180px'}}>完成支付返回商城
            </Button>
            
            </AutoCenter>
            </div>
        </div>
        : ''}
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    const { ruleid} = this.props.match.params
    
    this.setState({
      stamp: timestamp
    }, () => {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined'){
        this.setState({
          isWechat: true
        })
        // setTimeout(this.iTimer,0);
      }
      else
      {
        var ruleId = getQueryString('ruleId')
        var payMoney = getQueryString('payMoney')
        var userId = getQueryString('userId')
        this.setState({
          isWechat: false,
          ruleId: ruleId,
          payMoney: payMoney,
          userId: userId
        }, () => {
          var sign = signParam(
            this.context.ver,
            this.context.channel,
            this.context.versionId,
            this.context.userId,
            this.context.site,
            timestamp
          )
          localStorage.setItem("ver", this.context.ver)
          localStorage.setItem("userId", userId)
          localStorage.setItem("timestamp", timestamp)
          localStorage.setItem("sign", sign)
          localStorage.setItem("site", this.context.site)
          localStorage.setItem("logintype", 2)
          
          this.toPay()
        })
      }
      
    })
  }
}

export default index
