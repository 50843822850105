import React,{lazy} from 'react';
import './App.scss';
import './assets/css/aui.css'
import './assets/css/style.css'
import './assets/css/style-v3.0.css'
import './assets/css/mixs-iconfont.css'
import './assets/css/style-dark.css'
import routes from './routes.js'
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom'// 与路由相关的内置组件
// import AppNav from './components/AppNav' // 自定义的导航组件
import {getQueryString,signParam} from '@/assets/js/string'
import MyGlobal from './components/MyGlobal'

var ver = getQueryString('ver')
var channel = getQueryString('channel')
var versionId = getQueryString('versionId')
var from = '/?ver=' + ver + '&channel=' + channel + '&versionId=' + versionId + ''

function App() {
  return (

    <MyGlobal>
        <BrowserRouter>
            <Switch>
              {routes.map(e => <Route {...e} />)}
            </Switch>
        </BrowserRouter>
      </MyGlobal>

  )
}

export default App;
