import React, {Component} from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import '@/config/run.js'
import ctx from '@/assets/js/ctx'
import MyGlobal from "../../components/MyGlobal";
import {getQueryString, axiosParam, toPage, toBookCover, toBookRead, toCatalog} from '@/assets/js/string'
import '@/config/run.js'
import {Toast, Modal} from 'antd-mobile'
import $ from 'jquery'

export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      ruleId: 0,
      weChatData: []
    }
  }

  onRef = (ref) => {
    this.child = ref
  }

  payNotify = (e) => {
    this.props.payNotify(e);
  }

  init = (payMoney,ruleId,remark = '') => {
    Toast.loading('', 0, () => {
      console.log('Load complete !!!');
    });
    const {stamp} = this.state
    const {payNotify} = this

    let params = {
      payMoney: payMoney,
      ruleId: ruleId,
      remark: remark
    };
    params = $.extend(params, axiosParam(this.context,stamp))

    this.context.axios.post(global.constants.apiUrl + 'novel/h5/pay/wechat/reCharge', params).then((res) => {
      Toast.hide()
      if (res.code <= 0) {
        Toast.fail(res.msg, 1)
        //window.location.href = '/notfound?ver' + this.context.ver + '&channel=' + this.context.channel + '&versionId=' + this.context.versionId + '';
      }
      else {

        if (typeof WeixinJSBridge === "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
          }
        } else {
          const {appid, time_stamp, nonce_str, package_value, sign_type, sign} = res.data // 服务器返回的参数
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              appId: res.data.appId,
              timeStamp: res.data.timeStamp,
              nonceStr: res.data.nonceStr,
              package: res.data.package,
              signType: res.data.signType,
              paySign: res.data.paySign,
            },
            function (res) {
              if (res.err_msg === "get_brand_wcpay_request:ok") {
                Toast.info('支付成功', 1, () => {
                  payNotify(1);
                })
              } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                Toast.info('用户取消支付', 1, () => {
                  payNotify(2);
                })
              } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                Toast.info('支付失败', 1, () => {
                  payNotify(3);
                })
              }
            }
          )
        }

      }
    });


  }

  render() {
    const {currentTab} = this.props

    return (
      <>
      </>
    )
  }

  componentDidMount() {
    this.props.onRef(this)
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    }, () => {

    })

  }
}

export default index
