import React, {Component} from 'react'
import axios from '@/assets/js/axios'
import Title from '@/components/Title'
import {ListView,Icon,Toast} from 'antd-mobile';
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'
import {axiosParam,toBookCover} from '@/assets/js/string'
import ReactStars from 'react-stars'
import TopNav from '@/components/TopNav'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import sdk from '@/routes/wxShare'

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      page: 0,
      bookId: 0,
      replayId: 0,
      textareaValue: '',
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      hasMore: true,
      isAllLoaded: false,
      isLoading: false,
      dataList: [],
      pagesize: 20,
      totalCount: 0,
      headerNavOpacity: 0,
      starsValue: 5
    }
  }

  reqReviewList = (action) => {
    const {page, stamp, bookId} = this.state
    let params = {
      bookId: bookId,
      page: page + 1,
      limit: this.state.pagesize
    };

    params = $.extend(params, axiosParam(this.context,stamp))

    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'novel/getCommentList', params, this)
    }, 10);
  }

  onRefresh = () => {
    this.setState({refreshing: true, isLoading: true, hasMore: true, page: 0, dataList: []});
    this.reqReviewList();
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqReviewList();
  }

  handleTextareaChange = (e) => {
    this.setState({
      textareaValue: e.target.value
    })
  }

  submitComment = () => {
    const {stamp, bookId, textareaValue, replayId,starsValue} = this.state

    let params = {
      bookId: bookId,
      rid: replayId,
      content: textareaValue,
      scores: starsValue
    };

    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `novel/setComment`, params)
      .then((res) => {
        if (res.code == -1)
          Toast.fail(res.msg, 1);
        else {
          Toast.success(res.msg, 1, () => {
            toBookCover(bookId,this.context)
          });
        }
      })
  }

  submitReplayComment = (replayId) => {
    this.setState({
      replayId: replayId
    })
  }

  handleScroll = (e) => {
    var scrollTop = e.target.documentElement.scrollTop;
    if (scrollTop <= 10) {
      this.setState({
        headerNavOpacity: 0
      });
    }
    else {
      this.setState({
        headerNavOpacity: 1
      });
    }
  }

  handleClearKeyword = (e) => {
    this.setState({
      textareaValue: ""
    })
  }

  onStarsChange = (e) => {
    this.setState({
      starsValue: e
    })
  }

  render() {
    const {resultList, textareaValue, starsValue, dataSource, isLoading, refreshing, totalCount, setting} = this.state
    const {submitComment, handleClearKeyword, handleTextareaChange, pagesize, submitReplayComment, onStarsChange} = this

    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + '|书籍评论' : ''}>
        </DocumentTitle>

        <TopNav name={'发布评论'} history={this.props.history}/>

        <div className={`book-review aui-bg-wx ${getTheme()}`} style={{'marginTop':'45px'}}>
          <div className={`aui-content aui-bg-white aui-margin-15  aui-margin-b-0 aui-border-radius-10`}>
            <div className={`aui-row-padded  aui-padded-t-20 aui-padded-b-20`}>
              <div style={{'width': '155px', 'margin': '0px auto'}}>
                <ReactStars
                  className={`star`}
                  count={5}
                  size={20}
                  value={starsValue}
                  onChange={onStarsChange}
                  color2={'#ffd700'}/>
                <p className={`aui-font-size-12 aui-text-align-c aui-margin-t-5 aui-margin-b-15`}>轻触星星打分</p>
              </div>
            </div>
          </div>
          <div className={`blank`}></div>
          <div class="aui-content aui-bg-white aui-margin-15 aui-margin-t-0 aui-border-radius-10">
            <div class="aui-content aui-bg-white" style={{'width': '100%'}}>
              <ul class="aui-list aui-form-list aui-bg-none">
                <li class="aui-list-item" style={{'background': 'none'}}>
                  <div class="aui-list-item-inner">
                    <div class="aui-list-item-input" style={{'position': 'relative'}}>
                      <textarea placeholder="在此输入评论" id="reviewContent"
                                class="aui-border-color-gray aui-font-size-16 aui-text-gray-light aui-padded-5"
                                value={textareaValue} onChange={handleTextareaChange}
                                style={{'height': '9rem'}}></textarea><Icon onClick={handleClearKeyword} type='cross-circle-o' style={{
                      'position': 'absolute',
                      'right': '20px',
                      'top': '15px'
                    }}/>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="aui-row-padded buy-body aui-padded-l-15 aui-padded-r-15">
                <div class="aui-col-xs-12 aui-pull-left">
                  <div
                    class="aui-btn aui-btn-danger aui-btn-block aui-btn-sm aui-font-size-14 aui-text-color-white btn-buy aui-margin-t-5 aui-margin-b-15"
                    onClick={() => {
                      submitComment()
                    }} style={{
                    'border-radius': '7rem',
                    'margin-bottom': '1rem!important',
                    'height': '2.1rem',
                    'line-height': '2.1rem'
                  }}>提交评论
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  componentDidMount() {
    const {bookid} = this.props.match.params
    var timestamp = new Date().getTime()
    this.setState({
      bookId: bookid,
      stamp: timestamp
    }, () => {
      this.reqReviewList()
    })
  }
}

export default index
