import React, { Component } from 'react';
import axios from '@/assets/js/axios.js';
import { getUserInfo, quitLogin } from '@/assets/js/user';
import ctx from '@/assets/js/ctx';
import {
  getQueryString,
  signParam,
  toPage,
  toOrderPay,
  axiosParam,
  toOneCheckout,
  toOrderDetail,
  toOrderList
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import {
  Flex,
  ListView,
  WingBlank,
  WhiteSpace,
  Grid,
  PullToRefresh,
  Tag,
  Tabs,
  SegmentedControl,
  List,
  InputItem
} from 'antd-mobile';
import TopNav from '@/components/TopNav';
import { getTheme, getThemeValue } from '@/assets/js/setting';
import { CapsuleTabs, Form, Input, TextArea, Button } from 'antd-mobile-v5';
import { Image, Toast } from 'antd-mobile-v5';
import StickyFooter from 'react-sticky-footer';
import Title from '@/components/Title';
import $ from 'jquery';
import { toHome } from '../../assets/js/string'
import sdk from '@/routes/wxShare'
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      userInfo: {},
      siteInfo: {},
      ruleId: 0,
      payMoney: '-',
      page: 0,
      isAllLoaded: false,
      financeRuleList: [],
      financeMonthlyRuleList: [],
      isFirstLoaded: true,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      pagesize: 10,
      isLoading: true,
      refreshing: true,
      down: true,
      hasMore: true,
      useBodyScroll: true,
      cartList: [],
      typeId: 0,
      contractVisible: false,
      contractHtml: '',
      person_truename: '',
      person_broadcastname: '',
      person_card: '',
      person_mobile: '',
      person_email: '',
      company_companyname: '',
      company_address: '',
      company_no: '',
      company_mobile: '',
      company_email: '',
      isModifyPerson: true,
      isModifyCompany: true,
      totalPrice: '0',
      xmlyUrl: ''
    };
  }

  getOrderQuotation = () => {
    const { stamp, articleId, typeId } = this.state;
    let params = {
      siteId: 3,
      articleId: articleId,
      typeId: typeId,
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `order/getOrderQuotation`, params).then(res => {
      if (res.code === '1') {
        this.setState(
          {
            contractHtml: res.data,
            totalPrice: res.msg,
            isFirstLoaded: false,
          },
          () => {}
        );
      } else {
      }
    });
  };

  onPay = (values: any) => {
    let value = values;
    const { stamp, truename, broadcastname, companyname, card, email, address, articleId, typeId,xmlyUrl } =
      this.state;
    let params = {
      articleId: articleId,
      typeId: typeId,
      xmly_url: xmlyUrl
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `order/setOneOrder`, params).then(res => {
      if (res.code === '1') {
        toOrderPay(res.data, this.context);
      } else if (res.code === '2'){
        toOrderDetail(res.data, this.context);
      } else if (res.code === '3'){
        toOrderList(this.context);
      } else {
        Toast.show({
              content: res.msg,
              afterClose: () => {
                // toPage('home',this.context)
              },
            })
      }
    });
  };

  onChangeTab = e => {
    this.setState(
      {
        typeId: e.nativeEvent.selectedSegmentIndex,
      },
      () => {}
    );
  };

  onRefresh = () => {
    this.setState({
      page: 0,
      refreshing: true,
    });
  };

  setValue = (e) => {
this.setState(
      {
        xmlyUrl: e,
      },
      () => {}
    );
  };

  render() {
    const { cartList, typeId, isFirstLoaded, articleId, contractHtml, totalPrice,xmlyUrl } = this.state;

    const { onChangeTab, onRefresh, setOrderQuotation, onPay,setValue } = this;
    const antIcon = <div className={`am-icon-dot`} />;
    const style = {
      bottom: '0px',
      height: document.documentElement.clientHeight - 45,
    };

    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | 有声授权书' : ''}
        ></DocumentTitle>
        <TopNav name={'有声授权书'} history={this.props.history} />
        <div className={`checkout home aui-bg-wx `}>
          <div className={`checkout aui-content aui-bg-wx ${getTheme()}`}>
            {isFirstLoaded ? (
              <div style={{ marginTop: '0px' }}>{antIcon}</div>
            ) : (
              <>
                  <div className={`aui-bg-white aui-border-radius-10`} style={{ marginTop: '45px' }} >
                    <h2 className={`aui-margin-center aui-text-align-c aui-margin-t-5`}>有声授权书</h2>
                    <div className={` aui-padded-10`}>
                      <div dangerouslySetInnerHTML={{ __html: contractHtml }}></div>
                    </div>
                  </div>

                  <WhiteSpace />
                  <div className={`aui-margin-t-0 more-info aui-bg-white`}>
                    <Title name={'授权说明'} />
                    <div class="aui-content aui-margin-b-0 aaa" style={{ margin: '0px' }}>
                      <ul class="aui-list aui-media-list aui-bg-none">
                        <li class="aui-list-item aui-bg-none">
                          <div class="aui-media-list-item-inner">
                            <div class="aui-list-item-inner">
                              <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10">
                                *以上仅为授权预览，授权方公司抬头据具体书籍有所更改，不影响授权购买，以邮箱收件信息为准。
                                <br />
                                *交付的授权文件为：
                                <br />
                                ①FM商城授权书；
                                <br />
                                ②作品版权文件（含作者与网站合同；网站截屏；作品封面；作品TXT）；
                                <br />
                                ③喜马拉雅版权登记流程。
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <WhiteSpace />
                  <Flex className={`aui-padded-10 aui-margin-b-15`}>
                    <Flex.Item>
                <Input
          placeholder='请输入喜马拉雅链接'
          value={xmlyUrl}
          onChange={val => {
            setValue(val)
          }}
                    />
                    </Flex.Item>
                    </Flex>
                  <Flex className={`aui-padded-10 aui-margin-b-15`}>
                    <Flex.Item>
                      <span className={`aui-font-size-20 aui-text-color-pink`}>¥ {totalPrice}</span>
                    </Flex.Item>
                    <Flex.Item>
                      <Button
                        type="primary"
                        className={`btn-large btn-checkout`}
                        onClick={() => {
                          toOneCheckout(articleId, this.context);
                        }}
                      >
                        返回修改
                      </Button>
                    </Flex.Item>
                    <Flex.Item>
                      <Button
                        type="warning"
                        color="danger"
                        className={`btn-large btn-checkout`}
                        style={{'color':'white'}}
                        onClick={() => {
                          onPay(articleId, this.context);
                        }}
                      >
                        立即购买
                      </Button>
                    </Flex.Item>
                  </Flex>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const { articleid, typeid } = this.props.match.params;
    var timestamp = new Date().getTime();
    this.setState(
      {
        stamp: timestamp,
        articleId: articleid,
        typeId: typeid,
      },
      () => {
        getUserInfo(this.context, timestamp).then(res => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context);
            }
          } else {
            this.setState({
              userInfo: res.data,
            });

            if (parseInt(articleid) > 0) {
              this.getOrderQuotation();
            } else {
            }

            sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 有声授权书' : '',url: window.location.href, type: 'common'},timestamp,this.context)
          }
        });
      }
    );
  }
}

export default index;
