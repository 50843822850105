/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { checkToken } from '@/assets/js/hoc.js';
import axios from '@/assets/js/axios';
import Title from '@/components/Title';
import { Toast, Badge } from 'antd-mobile';
import ctx from '@/assets/js/ctx';
import {
  getQueryString,
  signParam,
  string,
  toBookCover,
  toBookRead,
  toCatalog,
  toBookReviewList,
  toBookReview,
  axiosParam,
  toOneCheckout,
  toPage,
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import {
  ListView,
  List,
  WingBlank,
  WhiteSpace,
  Card,
  PullToRefresh,
  Modal,
  Flex,
  Button,
  Tag
} from 'antd-mobile';
import TopNav from '@/components/TopNav';
import ReactStars from 'react-stars';
import BookListItem from '@/components/BookListItem';
import Carousel from 'nuka-carousel';
import { getTheme, getSetting } from '@/assets/js/setting';
import $ from 'jquery';
import StickyFooter from 'react-sticky-footer';
import { Popup } from 'antd-mobile-v5';
import { MessageOutline, AppstoreOutline } from 'antd-mobile-icons';
import sdk from '@/routes/wxShare'
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    let _this = this;
    this.state = {
      articleId: 0,
      headerNavOpacity: 0,
      data: {},
      book: {},
      buytip: {},
      reward: {},
      userInfo: {},
      showGiftModal: false,
      giftPrice: 100,
      giftNum: 1,
      giftId: 'lollipop',
      recommendDataSource: new ListView.DataSource({
        // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      isLoading: true,
      reviewsList: [],
      reviews: [],
      recommendList: [],
      isBlock: false,
      creatBlock: false,
      lastChapter: {},
      showSize: 60,
      rankList: [],
      refreshing: true,
      down: true,
      isFirstLoaded: true,
      pagesize: 4,
      pintuan: [],
      visibleCheckout: true,
      contactVisible: false,
      preChapter: {
        content: '',
      },
      chapterMore: false,
    };
  }

  blockClick = () => {
    this.setState({
      isBlock: !this.state.isBlock,
    });
  };

  onRefresh = () => {
    this.setState({ refreshing: true, recommendList: [], reviews: [] });
    this.initialization();
    this.reqUserReviewList();
    this.reqUserLikeList();
  };

  reqUserReviewList = action => {
    const { page, pagesize, stamp, articleId } = this.state;
    let params = {
      bookId: articleId,
      page: page + 1,
      pagesize: pagesize,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    this.context.axios.post(global.constants.apiUrl + `novel/getCommentList`, params).then(res => {
      if (res.code == 1) {
        this.setState({
          reviews: res.data,
        });
      }
    });
  };

  reqUserLikeList = action => {
    const { page, pagesize, stamp, articleId } = this.state;

    let params = {
      bookId: articleId,
      page: page + 1,
      pagesize: pagesize,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    this.context.axios.post(global.constants.apiUrl + `user/getUserLike`, params).then(res => {
      if (res.code == 1) {
        this.state.recommendList = [...this.state.recommendList, ...res.data];
        this.setState({
          recommendList: res.data,
          recommendDataSource: this.state.recommendDataSource.cloneWithRows(
            this.state.recommendList
          ),
        });
      }
    });
  };

  initialization = () => {
    const { stamp, articleId } = this.state;

    let params = {
      articleId: articleId,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + 'novel/getVoiceNovelCover', params).then(res => {
      this.setState(
        {
          book: res.data.book,
          buytip: res.data.buytip,
          reward: res.data.reward,
          lastChapter: res.data.lastChapter,
          refreshing: false,
          isFirstLoaded: false,
        },
        () => {
          let param = {
            articleId: articleId,
            url: window.location.href,
            type: 'detail'
          }
          sdk.getJSSDK(param,stamp,this.context);
          this.reqBookContent();
        }
      );
    });
  };

  toShowSize = () => {
    this.setState({
      showSize: this.state.showSize == 60 ? 10000 : 60,
    });
  };

  toShowGiftModal = (s, p) => {
    this.setState({
      showGiftModal: true,
      giftId: s,
      giftPrice: p,
    });
  };

  onClose = key => () => {
    this.setState({
      showGiftModal: false,
    });
  };

  onChangeGiftNum = (val, e) => {
    this.setState({
      giftNum: this.state.giftNum + val,
    });
    if (e) {
      this.setState({
        giftNum: e.target.value,
      });
    }
  };

  onChangeGift = (s, p) => {
    this.setState({
      giftId: s,
      giftPrice: p,
    });
  };

  handleScroll = e => {
    var scrollTop = e.target.documentElement.scrollTop;
    if (scrollTop <= 10) {
      this.setState({
        headerNavOpacity: 0,
      });
    } else {
      this.setState({
        headerNavOpacity: 1,
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  MyBody = props => {
    return <div className="am-list-body my-body">{props.children}</div>;
  };

  LikeBody = props => {
    const { book } = this.state;
    return (
      <>
        <div className={`book-list aui-margin-10`}>
          <Title name={'猜你喜欢'} />
          <WingBlank size="sm"> {props.children}</WingBlank>、
        </div>
      </>
    );
  };

  carouselBeforeChange = (from, to) => {};

  carouselAfterChange = () => {};

  valueConversion(str, ratio) {
    if (str / ratio > 1) {
      return (
        <>
          {(str / ratio).toFixed(2)}
          <span className={`aui-font-size-12`}>万</span>
        </>
      );
    } else {
      return <>{((str * 10000) / ratio).toFixed(0)}</>;
    }
  }

  albumConversion(str) {
    if (str == 'free') {
      return (
        <>
          免费
          <span className={`aui-font-size-12`}>专辑</span>
        </>
      );
    } else {
      return (
        <>
          VIP
          <span className={`aui-font-size-12`}>专辑</span>
        </>
      );
    }
  }

  saleTotalConversion(str) {
    if (str == 0) {
      return (
        <>
          首次
          <span className={`aui-font-size-12`}>登记</span>
        </>
      );
    } else {
      return (
        <>
          已被
          <span className={`aui-font-size-12`}>登记</span>
        </>
      );
    }
  }

  addCart = articleId => {
    const { stamp } = this.state;

    let params = {
      articleId: articleId,
      goodsNumber: 1,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `cart/addCart`, params).then(res => {
      Toast.success(res.msg, 1);
    });
  };

  addCase = () => {
    const { book, articleId, stamp } = this.state;

    let params = {
      articleId: articleId,
      chapterId: book.firstchapterid,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `novel/postAddCase`, params).then(res => {
      this.setState(
        {
          book: {
            ...this.state.book,
            is_case: 1,
          },
        },
        () => {
          Toast.success(res.msg, 1);
        }
      );
    });
  };

  toContactVisible = () => {
    this.setState({
      contactVisible: !this.state.contactVisible,
    });
  };

  reqBookContent = () => {
    const { stamp, articleId, book } = this.state;

    let params = {
      bookId: articleId,
      chapterId: book.firstchapterid,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `novel/getVoiceChapterContent`, params).then(res => {
      Toast.hide();
      this.setState(
        {
          preChapter: res.data,
        },
        () => {}
      );
    });
  };

  toChapterMore = () => {
    const { chapterMore } = this.state;
    this.setState({
      chapterMore: !this.state.chapterMore,
    });
  };

  // 回到首页
  toHome = () => {
    toPage('home', this.context);
  };

  render() {
    const {
      book,
      reward,
      showSize,
      giftPrice,
      showGiftModal,
      giftNum,
      recommendDataSource,
      giftId,
      isLoading,
      userInfo,
      refreshing,
      isFirstLoaded,
      reviews,
      preChapter,
      buytip,
      contactVisible,
      visibleCheckout,
      chapterMore,
      recommendList,
    } = this.state;
    const {
      addShelf,
      toShowSize,
      toShowGiftModal,
      onChangeGiftNum,
      onChangeGift,
      valueConversion,
      albumConversion,
      saleTotalConversion,
      toSendGift,
      onRefresh,
      onEndReached,
      LikeBody,
      MyBody,
      carouselBeforeChange,
      carouselAfterChange,
      canTuan,
      onToCheckout,
      toContactVisible,
      toChapterMore,
      addCart,
      addCase,
      toHome,
    } = this;
    const antIcon = <div className={`am-icon-dot`} />;
    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | 作品详情' : ''}
        ></DocumentTitle>

        {isFirstLoaded ? (
          <div className={`aui-bg-wx`}>{antIcon}</div>
        ) : (
          <>
            <TopNav name={book.articlename} history={this.props.history} />

            <div className={`book-cover aui-bg-wx ${getTheme()}`} style={{ marginTop: '45px' }}>
              <div className={`aui-content aui-bg-wx  aui-border-radius-10`}>
                <>
                  <div class="book-cover">
                    <section class="aui-content  aui-bg-white">
                      <div className={`book-base-info aui-card-list`}>
                        <div
                          className={`aui-card-list-content`}
                          onClick={() => {
                            toBookCover(book.articleid, book.chapterid, this.context);
                          }}
                        >
                          <img
                            src={book.thumburl}
                            className={`aui-image`}
                            style={{
                              width: '30%',
                              height: 'auto',
                              margin: '20px auto 0px auto',
                            }}
                            onError={e => {
                              e.target.src = 'http://i.mixs.cn/upload/cover/default.jpeg';
                            }}
                          />
                          {(book.thumburl || []).indexOf('nocover.png') !== -1 ? (
                            <div
                              class="no-cover-block"
                              style={{
                                width: '4.85rem',
                                margin: '0px auto',
                                position: 'relative',
                                bottom: '60px',
                              }}
                            ></div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className={` aui-bg-white`}>
                          <Flex className={`aui-padded-10 aui-padded-b-0`}>
                            <Flex.Item style={{'flex':'3'}}>
                                <div class="aui-text-color-pink aui-font-size-20">
                                  {
                                    book.book_price > 0 ?
                                  
                                  book.market_price != book.book_price ?
                                    <>
                                      <span style={{ textDecoration: 'line-through' }}>￥{book.market_price}</span>
                                  
                                      <span className={`ms-price aui-margin-l-5 aui-margin-r-5`}><Tag
                                        color='primary'
                                        fill='outline'
                                        style={{ '--border-radius': '6px' }}>
                                        秒杀价：<span class="aui-text-color-pink aui-font-size-20">￥{book.book_price}</span>
                                      </Tag>
                                          </span>
                                          <span className={`aui-font-size-12`}> FM点</span>
                                    </>
                                    :
                                    <>
                                    <span>￥{book.book_price}</span>
                                    <span className={`aui-font-size-12`}> FM点</span>
                                        </>
                                      : 
                                      
                                      <>
                                      <span className={`ms-price aui-margin-l-5 aui-margin-r-5 `}><Tag
                                        color='primary'
                                        fill='outline'
                                        style={{ '--border-radius': '6px' }}>
                                        <span class="aui-text-color-pink aui-font-size-20 text-decoration">￥{book.market_price}</span>
                                      </Tag>
                                          </span>
                                        <span className={`aui-font-size-12`}> FM点</span>
                                        <span className={`aui-font-size-14 aui-margin-l-5 `}>年卡免费</span>
                                      </>
                                  }
                                
                              </div>
                            </Flex.Item>
                            <Flex.Item>
                              <div className={`aui-pull-right aui-text-align-c`}>
                                {book.is_case === 1 ? (
                                  <div className={`aui-content`}>
                                    <i
                                      className={`aui-iconfont aui-icon-like aui-text-color-pink`}
                                      style={{ fontSize: '18px' }}
                                    ></i>
                                    <p className={`aui-font-size-12 aui-text-color-pink`}>收藏</p>
                                  </div>
                                ) : (
                                  <div
                                    className={`aui-content`}
                                    onClick={() => {
                                      addCase();
                                    }}
                                  >
                                    <i
                                      className={`aui-iconfont aui-icon-like`}
                                      style={{ fontSize: '18px' }}
                                    ></i>
                                    <p className={`aui-font-size-12`}>收藏</p>
                                  </div>
                                )}
                              </div>
                            </Flex.Item>
                          </Flex>
                          <Flex
                            className={`aui-font-size-12 aui-text-color-pink aui-padded-10 aui-padded-t-0 aui-bg-white`}
                          >
                            <span style={{ marginLeft: '0.2rem' }}>
                              *作品字数因统计方式不同有误差，以Word交付为准。
                            </span>
                          </Flex>
                        </div>
                        <div
                          className={`aui-padded-15`}
                          style={{ minHeight: 'auto' }}
                          onClick={() => {
                            toBookCover(book.articleid, book.chapterid, this.context);
                          }}
                        >
                          <span
                            className={`aui-font-size-16 aui-text-align-l aui-text-color-black`}
                          >
                            <span className={`articlename`} style={{ fontWeight: 'bolder' }}>
                              {string.truncate(book.articlename, 1000, '...')} | {book.author} |{' '}
                              {book.sortname} | {book.fullflag}{' '}
                              {book.sale_total === 0 ? ' | XMLY可登记版权' : ''}
                              {1 === 1 ? ' | 全网可上架' : ''}
                              {book.is_new === 1 ? (<img src={require('@/assets/icon/book/new.png')} style={{ 'width': '30px','display':'inline','marginTop':'3px' }} />) : ''}{' '}
                            </span>
                            {book.is_self === 1 ? (
                              <div
                                className={`aui-font-size-12 sortname aui-margin-r-5`}
                                style={{
                                  borderRadius: '3px',
                                  padding: '0px 3px 0px 3px',
                                  height: '20px',
                                  width: '53px',
                                  display: 'inline',
                                }}
                              >
                                FM自营
                              </div>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                        {buytip.txt.left ? (
                          <div className={`aui-margin-15 aui-margin-t-0`}>
                            <ul
                              class="aui-list aui-list-in"
                              style={{ background: '#fdffdf', borderRadius: '10px' }}
                            >
                              <li class="aui-list-item">
                                <div class="aui-list-item-inner aui-list-item-arrow">
                                  <div class="aui-list-item-title">{buytip.txt.left}</div>
                                  <div class="aui-list-item-right">{buytip.txt.right}</div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </section>
                    <div className={`border-gray  w100`}></div>
                    <div
                      class="aui-row aui-bg-white aui-padded-15"
                      style={{ borderRadius: '0px 0px 6px 6px' }}
                    >
                      <div
                        class="aui-col-xs-2 aui-font-size-16 aui-text-align-l left-title"
                        style={{ lineHeight: '54.5px' }}
                      >
                        信息
                      </div>
                      <div className={`aui-col-xs-10`}>
                        <section class="aui-margin-b-0 aui-bg-none">
                          <div class="aui-row aui-text-align-c">
                            <div class="aui-col-xs-4">
                              <div class="aui-text-color-pink aui-font-size-18 ">
                                {valueConversion(book.size, 1)}
                              </div>
                              <div class="aui-grid-label">
                                <h5>字数</h5>
                              </div>
                            </div>
                            <div class="aui-col-xs-4">
                              <div class="aui-text-color-pink aui-font-size-18">
                                {book.warrant_type}
                                <span className={`aui-font-size-12`}>5年</span>
                              </div>
                              <div class="aui-grid-label">
                                <h5>授权</h5>
                              </div>
                            </div>
                            <div class="aui-col-xs-4">
                              <div class="aui-text-color-pink aui-font-size-18">
                                支持
                                <span className={`aui-font-size-12`}>{book.is_esilver_buy}</span>
                              </div>
                              <div class="aui-grid-label">
                                <h5>支付</h5>
                              </div>
                            </div>
                          </div>
                          <div class="aui-row aui-text-align-c">
                            <div class="aui-col-xs-4">
                              <div class="aui-text-color-pink aui-font-size-18 ">
                                {saleTotalConversion(book.sale_total)}
                              </div>
                              <div class="aui-grid-label">
                                <h5>A+版权</h5>
                              </div>
                            </div>
                            <div class="aui-col-xs-4">
                              <div class="aui-text-color-pink aui-font-size-18">
                                {albumConversion(book.album)}
                              </div>
                              <div class="aui-grid-label">
                                <h5>专辑</h5>
                              </div>
                            </div>
                            <div class="aui-col-xs-4">
                              <div class="aui-text-color-pink aui-font-size-18">
                                全体
                                <span className={`aui-font-size-12`}>平台</span>
                              </div>
                              <div class="aui-grid-label">
                                <h5>上架</h5>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <WhiteSpace></WhiteSpace>
                    <section
                      class="book-intro aui-margin-t-0  aui-bg-white"
                      style={{ borderRadius: '6px 6px 0px 0px' }}
                    >
                      <div class="aui-row  aui-padded-15">
                        <div class="aui-col-xs-2 aui-font-size-16 aui-text-align-l aui-text-dark-white">
                          福利
                        </div>
                        <div class="aui-col-xs-10">
                          <div class="aui-row">
                            <div class="aui-col-xs-12">
                              <span
                                class="aui-font-size-14 aui-pull-left"
                                style={{ marginTop: '3px' }}
                              >
                                <div
                                  className={`aui-font-size-12 sortname aui-margin-r-5`}
                                  style={{
                                    borderRadius: '3px',
                                    padding: '0px 3px 0px 3px',
                                    height: '20px',
                                    width: 'auto',
                                    float: 'left',
                                    marginTop: '1px',
                                  }}
                                >
                                  返FM券
                                </div>
                                <span className={`aui-font-size-14 aui-text-dark-white`}>
                                  FM购买返券，本单可返{' '}
                                  <span className={`aui-text-color-pink`}>
                                    {book.book_price_back} FM券
                                  </span>
                                </span>
                              </span>
                            </div>
                              <div class="aui-col-xs-12">
                                {
                                  this.context.ver === 'v1' && 1===0? 
                                    <span
                                      class="aui-font-size-14 aui-pull-left"
                                      style={{ marginTop: '10px' }}
                                    >
                                      <div
                                        className={`aui-font-size-12 sortname aui-margin-r-5`}
                                        style={{
                                          borderRadius: '3px',
                                          padding: '0px 3px 0px 3px',
                                          height: '20px',
                                          width: 'auto',
                                          float: 'left',
                                          marginTop: '1px',
                                        }}
                                      >
                                        送推荐位
                                      </div>
                                      <span className={`aui-font-size-14 aui-text-dark-white`}>
                                        作品上架后，免费申请FM推荐
                                      </span>
                                    </span>
                                    : ''
                                }
                              </div>
                              {buytip.wxqun.right !== '' ?
                                <div class="aui-col-xs-12">
                                  <span
                                    class="aui-font-size-14 aui-pull-left"
                                    style={{ marginTop: '10px' }}
                                  >
                                    <div
                                      className={`aui-font-size-12 sortname aui-margin-r-5`}
                                      style={{
                                        borderRadius: '3px',
                                        padding: '0px 3px 0px 3px',
                                        height: '20px',
                                        width: 'auto',
                                        float: 'left',
                                        marginTop: '1px',
                                      }}
                                    >
                                      { buytip.wxqun['left'] }
                                    </div>
                                    <span className={`aui-font-size-14 aui-text-dark-white`}>
                                      { buytip.wxqun['right'] }
                                    </span>
                                  </span>
                                </div>
                                : ''}
                          </div>
                        </div>
                      </div>
                    </section>

                    <div className={`border-gray  w100`}></div>
                    <section class="book-intro aui-margin-t-0  aui-bg-white">
                      <div class="aui-row aui-padded-15 ">
                        <div class="aui-col-xs-12">
                          <div class="aui-book-intro-box aui-padded-b-0">
                            <div class="aui-book-intro aui-font-size-16 aui-font aui-text-align-l aui-text-dark-white">
                              {string.truncate(book.intro, showSize, '...')}
                            </div>
                            <i
                              class="aui-iconfont aui-font-size-14"
                              className={`aui-iconfont aui-font-size-14 ${
                                showSize == 60
                                  ? 'aui-iconfont aui-font-size-14 aui-icon-down'
                                  : 'aui-iconfont aui-font-size-14 aui-icon-top'
                              }`}
                              style={{
                                position: 'absolute',
                                top: 'auto',
                                right: '15px',
                                bottom: '-4px',
                              }}
                              onClick={() => {
                                toShowSize();
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className={`border-gray  w100`}></div>
                    <section
                      class="aui-content chapter-list aui-bg-white  aui-padded-15 "
                      style={{ borderRadius: '0px 0px 6px 6px' }}
                    >
                      <div class="aui-row">
                        <div class="aui-col-xs-2 aui-font-size-16 aui-text-align-l aui-text-dark-white">
                          目录
                        </div>
                        <div
                          class="aui-col-xs-10 aui-text-align-r aui-text-dark-white aui-text-dark-white"
                          onClick={() => {
                            toCatalog(book.articleid, this.context);
                          }}
                        >
                          {book.chapterupdate ? (
                            <span
                              class="aui-font-size-16"
                              style={{
                                paddingTop: '0rem',
                                color: '#757575',
                                marginTop: '-0.8rem',
                              }}
                            >
                              {string.truncate(book.lastchapter, 14, '...')}
                              <i
                                class="aui-iconfont fa fa-update aui-font-size-16"
                                style={{
                                  top: '0.4rem',
                                  'padding-left': '0.4rem',
                                  fontSize: '1.8rem',
                                }}
                              ></i>
                            </span>
                          ) : (
                            <span
                              class="aui-font-size-16"
                              style={{
                                paddingTop: '0.12rem',
                                color: '#757575',
                              }}
                            >
                              {string.truncate(book.lastchapter, 14, '...')}· {book.lastupdate}
                            </span>
                          )}
                        </div>
                      </div>
                    </section>
                    <WhiteSpace></WhiteSpace>
                    <section
                      class="book-intro aui-margin-t-0 aui-margin-b-0 aui-bg-white"
                      style={{ borderRadius: '6px 6px 6px 6px' }}
                    >
                      <div class="aui-row  aui-padded-15">
                        <div class="aui-col-xs-12">
                          <div
                            className={`aui-font-size-16 aui-text-align-l aui-text-dark-white aui-text-bold`}
                          >
                            {preChapter.chaptername}
                          </div>
                          <div
                            class="chapter-content aui-marign-padded aui-text-dark-white"
                            style={{ height: chapterMore ? 'auto' : '15rem', overflow: 'hidden' }}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: preChapter.chaptercontent }}
                            ></div>
                          </div>
                          {!chapterMore ? (
                            <div
                              className={`aui-font-size-14 aui-margin-t-10 aui-padded-b-5 aui-text-color-pink aui-text-align-c`}
                              onClick={() => {
                                toChapterMore();
                              }}
                            >
                              继续阅读>>
                            </div>
                          ) : (
                            <div
                              className={`aui-font-size-14 aui-margin-t-10 aui-padded-b-5 aui-text-color-pink aui-text-align-c`}
                              onClick={() => {
                                toBookRead(book.articleid, book.nextchapterid, this.context);
                              }}
                            >
                              继续阅读下一章>>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                    <WhiteSpace></WhiteSpace>
                    <section class="content">
                        <img src={require('@/assets/images/detail/4.jpeg')} className={`aui-border-radius-10`}/>
                    </section>

                    {book.editormessage ? (
                      <section class="aui-grid aui-margin-b-0 aui-margin-t-0 aui-bg-none">
                        <WingBlank>
                          <div
                            className={`book-editor aui-border-radius-10 aui-padded-10 aui-text-color-pink`}
                          >
                            {string.truncate(book.editormessage, showSize, '...')}
                          </div>
                        </WingBlank>
                        <WhiteSpace size="sm" />
                      </section>
                    ) : (
                      ''
                    )}

                    {book.tags ? (
                      <section class="aui-content chapter-list aui-bg-none aui-padded-b-10">
                        {book.tags.map((e, index) => (
                          <Badge
                            text={e}
                            style={{
                              marginLeft: 12,
                              padding: '0px 5px',
                              backgroundColor: '#fd6974',
                              color: '#fffff',
                              borderRadius: 2,
                              fontSize: '14px',
                            }}
                          ></Badge>
                        ))}
                      </section>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              </div>
            </div>
            <StickyFooter
              bottomThreshold={0}
              normalStyles={{
                background: '#f1f1f1',
                width: '100%',
                paddingBottom: '0px',
                borderTop: '1px solid #f1f1f1',
              }}
              stickyStyles={{
                background: '#f1f1f1',
                width: '100%',
                maxWidth: '750px',
                paddingBottom: '0px',
                borderTop: '1px solid #f1f1f1',
              }}
            >
              <div class="aui-row margin-c">
                  <div class="aui-col-xs-3 aui-padded-b-5 aui-font-size-18 aui-text-align-c">
                  <Flex className={`aui-padded-10`}>
                      {1 === 0 ? 
                      <>
                    <Flex.Item>
                      <div onClick={toHome} className={'aui-margin-center aui-margin-l-10'}>
                        <AppstoreOutline fontSize={30} />
                        <p className={`aui-font-size-12`} style={{'textIndent':'2px'}}>首页</p>
                      </div>
                        </Flex.Item>
                          </>
                        : ''}
                    {(this.context.ver === 'v1') ? (
                      <Flex.Item>
                        <div
                          onClick={() => {
                            toContactVisible();
                          }}
                          className={' aui-margin-center aui-text-align-c'}
                        >
                          <MessageOutline fontSize={23} />
                          <p className={`aui-font-size-12`} style={{'lineHeight':'0.2rem'}}>客服</p>
                        </div>
                      </Flex.Item>
                    ) : (
                      ''
                    )}
                  </Flex>
                </div>
                <div class="aui-col-xs-9 aui-padded-b-10">
                  <Flex className={`aui-padded-10 aui-margin-b-10`}>
                    <Flex.Item>
                      <Button
                        className={`btn-middle btn-checkout yellow aui-font-size-14`}
                        onClick={() => {
                          toBookRead(book.articleid, book.chapterid, this.context);
                        }}
                        style={{ borderRadius: '25px' }}
                      >
                        试读章节
                      </Button>
                    </Flex.Item>
                    <Flex.Item>
                      
                      <Button
                        type="warning"
                        color="danger"
                        className={`btn-middle btn-checkout red aui-font-size-14`}
                        onClick={() => {
                          toOneCheckout(book.articleid, this.context);
                        }}
                        style={{ borderRadius: '25px' }}
                        >
                          {
                            book.book_price > 0 ?
                              '立即购买'
                              : '立即领取'
                          }
                            </Button>
                            
                    </Flex.Item>
                  </Flex>
                </div>
              </div>
            </StickyFooter>
            <Popup
              visible={contactVisible}
              onMaskClick={() => {
                toContactVisible();
              }}
              bodyStyle={{ height: '40vh' }}
            >
              <img
                src={buytip.qrcode}
                className={`aui-margin-center`}
                style={{ height: '100%', width: 'auto' }}
              />
            </Popup>
          </>
        )}
      </>
    );
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    var timestamp = new Date().getTime();

    this.setState(
      {
        articleId: id,
        stamp: timestamp,
      },
      () => {
        this.initialization();
        this.reqUserReviewList();
        this.reqUserLikeList();
        window.addEventListener('scroll', this.handleScroll);
      }
    );
  }
}

export default index;
