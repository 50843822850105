import MD5 from 'crypto-js/md5'
import MyGlobal from "../../components/MyGlobal";

export const string = {
  truncate: function(str, length, truncation) {
    if (str) {
      length = length || 30
      truncation = typeof truncation === "string" ? truncation : "..."
      return str.length > length ? str.slice(0, length - truncation.length) + truncation : String(str)
    }
    else
    {
      return '';
    }
  },
  isNull: function (value) {
    if (typeof(value) == "undefined"){
      return true;
    }
    return false;
  },
  delHtmlTag: function(str) {
    return str.replace(/<[^>]+>/g,"");
  },
};

export function getUrlToken(name, str) {
  const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return  decodeURIComponent(r[2]); return null;
}

export function getQueryString(key) {
  var after = window.location.search;
  if (after.indexOf('?') === -1) return null; //如果url中没有传参直接返回空

  //key存在先通过search取值如果取不到就通过hash来取
  after = after.substr(1) || window.location.hash.split("?")[1];

  if (after) {
    var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
    var r = after.match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    else {
      return null;
    }
  }
}

export function signParam(ver,channel,versionId,userId,site,timestamp) {
  return MD5(ver + '&' + channel + '&' + versionId + '&' + userId + '&' + site + '&' + timestamp + '&' + global.constants.key).toString()
}

export function toPage(page,e) {
  window.location.href = '/' + page + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toBookCover(id,e) {
  window.location.href = '/detail/' + id + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toBookRead(bookid,chapterid,e) {
  window.location.href = '/read/' + bookid + '/' + chapterid + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toBookReviewList(bookid,e) {
  window.location.href = '/reviewList/' + bookid + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toBookReview(bookid,e) {
  window.location.href = '/review/' + bookid + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toCatalog(bookid,e) {
  window.location.href = '/catalog/' + bookid + '/?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toBuy(page,e,typeId) {
  window.location.href = '/' + page + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&typeId=' + typeId + '&site=' + e.site;
}


export function toPay(ruleId,e) {
  window.location.href = '/pay/' + ruleId + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toFenSiRankList(bookid,e) {
  window.location.href = '/fenSiList/' + bookid + '/?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toCart(e) {
  window.location.href = '/cart?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toCheckout(e) {
  window.location.href = '/checkout?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toOneCheckout(id,e) {
  window.location.href = '/checkout/'+id+'?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toPayOrder(id,e) {
  window.location.href = '/pay/' + id + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toOrderList(e) {
  window.location.href = '/orderList?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toOrderDetail(id,e) {
  window.location.href = '/orderDetail/' + id + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toViewContract(id,typeid,e) {
  window.location.href = '/contract/' + id + '/'+typeid+'?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toOrderPay(id,e) {
  window.location.href = '/pay/' + id + '?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site
}

export function toVoiceBuy(e,typeId) {
  window.location.href = '/buy?typeId=' + typeId + '&ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site;
}

export function toTrainPay(e) {
  window.location.href = '/pay/1?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site;
}

export function toUserRankIntro(e) {
  window.location.href = '/userRankIntro?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site;
}

export function toUserReg(e) {
  window.location.href = '/reg?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site;
}

export function toUserLogin(e) {
  window.location.href = '/userLogin?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site;
}

export function toHome(e) {
  window.location.href = '/home?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site;
}

export function toAliPay(ruleid,payMoney,userid,e) {
  window.location.href = '/alipay?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site + '&ruleId=' + ruleid + '&payMoney='+payMoney+'&userId=' + userid + ''
}

export function toDownload(orderid,e) {
  window.location.href = '/download?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site + '&userId=' + e.userId + '&orderId=' + orderid + ''
}

export function toSearch(key,e) {
  window.location.href = '/search?ver=' + e.ver + '&channel=' + e.channel + '&versionId=' + e.versionId + '&site=' + e.site + '&keyword=' + key
}

export function isNull(value) {
  if (!value && typeof value != "undefined" && value != 0) {
    return true;
  } else {
    return false;
  }
}

export function getNowFormatDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

export function ifArrVal(arr,value){//多维数组判断是否存在某值
  for(var i = 0;i < arr.length;i++){
    if(arr[i] instanceof Array){//判断是否为多维数组
      return ifArrVal(arr[i],value);
    }else{
      if(arr[i].hasOwnProperty(value)){
        return 1;//存在
      }
    }
  }
  return -1;//不存在
}

export function axiosParam(context,stamp) {
  return {
    ver: context.ver,
    channel: context.channel,
    versionId: context.versionId,
    userId: context.userId,
    site: context.site,
    timestamp: stamp,
    sign: signParam(
      context.ver,
      context.channel,
      context.versionId,
      context.userId,
      context.site,
      stamp
    ),
  }
}