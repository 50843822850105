import React, {Component} from 'react'
import '@/config/run.js'
import {toPage, toBookCover, toBookRead} from '@/assets/js/string'
import ctx from '@/assets/js/ctx'

export class index extends Component {
  static contextType = ctx
  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const {goBack} = this
    const {name,isFixed} = this.props

    return (
      <header className={`aui-bar aui-bar-nav aui-bar-light header ${'header-bg-' + global.constants.system } ${isFixed ? 'aui-pos-fix' : ''}`}>
        <a class="aui-pull-left aui-btn cur" onClick={() => {
          goBack()
        }}>
          <span class="aui-iconfont aui-icon-left"></span>
        </a>
        <div class="aui-title">{name}</div>
        <a class="aui-pull-right aui-btn" onClick={() => {
          toPage('home', this.context)
        }}>
          <span class="aui-iconfont aui-icon-home"></span>
        </a>
      </header>
    )
  }
}

export default index
