import React, {Component} from 'react'
import styles from './index.module.scss'
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {List} from 'antd-mobile'

const Item = List.Item;
const Brief = Item.Brief;

export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      resultList: []
    }
  }

  toUrl = (url) => {
    window.location.href = url
  }

  reqSiteList = (action) => {
    this.context.axios.post(global.constants.apiUrl + 'site/getSiteList', {}).then((res) => {

      if (res.code == 1) {
        this.setState({
          resultList: res.data
        })
      }

    })
  }

  render() {
    const {resultList} = this.state
    const {toUrl} = this
    return (
      <>
        <DocumentTitle title='页面不见了'>
        </DocumentTitle>
        <div className={styles.page}>

          <div className={styles.content}>
            <div className={styles.img}></div>
            <div className={styles.tipblock}>
              <div className={styles.tip1}>真的很抱歉，页面不见了</div>
              <div className={styles.tip2}>客服：微信 a15361983371</div>
              <div className={styles.tip2}>您可以进入以下站点继续畅读</div>
              {resultList && resultList.map((e, i) =>
                <div className={styles.siteitem} onClick={() => {
                  toUrl(e.url)
                }}>
                  {e.name}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    }, () => {
      localStorage.clear();
      this.reqSiteList()
    })
  }
}

export default index
