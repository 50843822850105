import axios from '@/assets/js/axios'
import ctx from '@/assets/js/ctx'
import {toHome, signParam} from '@/assets/js/string'
import { Modal } from 'antd-mobile'
const alert = Modal.alert;

function getUserInfo(context,stamp) {
  return axios.post(global.constants.apiUrl + 'user/getVoiceUserInfo', {
    ver: context.ver,
    channel: context.channel,
    versionId: context.versionId,
    userId: context.userId,
    timestamp: stamp,
    site: context.site,
    sign: signParam(context.ver, context.channel, context.versionId, context.userId, context.userid, stamp)
  })

}

function quitLogin (context) {
  localStorage.clear();
  alert('退出', '已退出登录，刷新重新进入', [
          { text: '好的', onPress: () => toHome(context) },
  ])
}


export {
  getUserInfo,
  quitLogin
}

