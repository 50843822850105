import React, {Component} from 'react'
import styles from './index.module.scss'
import NavTxt from '@/components/NavTxt'
import FinanceListItem from '@/components/FinanceListItem'
import {ListView} from 'antd-mobile'
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam, string} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'

export class index extends Component {
  static contextType = ctx

  render () {
    const { classList, classId, filterId, bookStatus, bookSorts, bookTags, sortId, statusId, dataSource, isLoading, refreshing, hasMore, tagId, pagesize, title, bookStat, isFirstLoad, modelId, warTypeId, warTypeList, bookEsilver, esilverId, bookPrice, bookPriceFirst,priceId, bookSize, sizeId, saleTotalId, bookSaleTotal, classIds, bookPriceSort, priceSortId,bookSource,sourceId } = this.props

    const { checkBookSize, checkBookClass, checkBookSorts, onEndReached, checkBookTags, onRefresh, handleScroll, onRef, checkBookModel, checkBookWarType, checkBookEsilver, checkBookPrice, checkBookSaleTotal, checkBookPriceSort,checkBookSource} = this.props

    return (
      <>
        <div className={`aui-grid sort-category`}>
          <div className={`aui-row`}>
            {bookPriceFirst && bookPriceFirst.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'priceItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8 ? '0px' : '0.5px solid #ff0000' }}>
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${priceId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookPrice(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        {classList && classList.map((item, index) =>
        <div className={`aui-grid sort-category aui-bg-white`}>
            <div className={`aui-row`}>
              <div class="aui-padded-5 aui-col-xs-3" key={'classItem-' + index} style={{'borderBottom': '0px' }}>
                <div class="aui-grid-label current">
                  <div class="aui-label aui-font-size-14 cur aui-bg-white"
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${modelId == item.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`} onClick={() => {
                      checkBookClass(index,-1,item.id)
                    }}>{item.name}
                  </div>
                </div>
              </div>
             {item.list && item.list.map((iitem, iindex) =>
              <div class="aui-padded-5 aui-col-xs-3" key={'classItem-' + index + '-' + iindex} style={{'borderBottom': '0.5px solid #ff0000' }}>
                <div class="aui-grid-label current">
                  <div class="aui-label aui-font-size-14 cur aui-bg-white"
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${classId == iitem.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookClass(index,iindex,iitem.id)
                    }}>{iitem.name}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        )}
        <div className={`aui-grid sort-category`}>
          <div className={`aui-row`}>
            {bookSize && bookSize.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'sizeItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8 ? '0px' : '0.5px solid #ff0000' }}>
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${sizeId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookSize(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`aui-grid sort-category`}>
          <div className={`aui-row`}>
            {bookPrice && bookPrice.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'priceItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8 ? '0px' : '0.5px solid #ff0000' }}>
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${priceId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookPrice(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`aui-grid sort-category`}>
          <div className={`aui-row`}>
            {bookSorts && bookSorts.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'sortItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8  ? '0px' : '0.5px solid #ff0000' }}>
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${sortId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookSorts(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>
                
        <div className={`aui-grid sort-category`}>
          <div className={`aui-row`}>
            {bookPriceSort && bookPriceSort.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'priceSortItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8  ? '0px' : '0.5px solid #ff0000' }}>
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${priceSortId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookPriceSort(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`aui-grid sort-category`}>
          <div className={`aui-row`}>
            {bookEsilver && bookEsilver.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'esilverItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8  ? '0px' : '0.5px solid #ff0000' }}>
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${esilverId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookEsilver(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`aui-grid sort-category`}>
          <div className={`aui-row`}>
            {warTypeList && warTypeList.map((e, index) =>
              <div class="aui-col-xs-3 aui-padded-5" key={'warTypeItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8  ? '0px' : '0.5px solid #ff0000' }}>
                <div class="aui-grid-label current aui-margin-b-5">
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${warTypeId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookWarType(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`aui-grid sort-category aui-bg-white`}>
          <div className={`aui-row`}>
            {bookSaleTotal && bookSaleTotal.map((item, index) =>
              <div class="aui-col-xs-3 aui-padded-5" key={'saleTotalItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8  ? '0px' : '0.5px solid #ff0000' }}>
                <div class="aui-grid-label current">
                  <div class="aui-label aui-font-size-14 cur aui-bg-white"
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${saleTotalId == item.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookSaleTotal(item.id)
                    }}>{item.name}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`aui-grid sort-category aui-bg-white`}>
          <div className={`aui-row`}>
            {bookSource && bookSource.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'sourceItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8 ? '0px' : '0.5px solid #ff0000' }} >
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${sourceId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookSource(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`aui-grid sort-category aui-bg-white`}>
          <div className={`aui-row`}>
            {bookTags && bookTags.map((e, index) =>
              <div className={`aui-col-xs-3 aui-padded-5`} key={'tagsItem-' + index} style={{ 'borderBottom':  index === 0 || index === 4 || index === 8 ? '0px' : '0.5px solid #ff0000' }} >
                <div className={`aui-grid-label current aui-margin-b-5`}>
                  <div
                    className={`aui-label aui-font-size-14 cur aui-bg-white ${tagId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold border-pink" : ''}`}
                    onClick={() => {
                      checkBookTags(e.id)
                    }}>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>

      </>
    )
  }
}

export default index
