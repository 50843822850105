import React, {Component} from 'react'
import {getBookList} from '@/assets/js/content'
import ctx from '@/assets/js/ctx'
import {axiosParam, signParam, toPage, toBookCover, toBookRead, toCatalog} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'
import {ListView, NavBar, WhiteSpace} from 'antd-mobile'
import FinanceListItem from '@/components/FinanceListItem'
import axios from '@/assets/js/axios'
import {StickyContainer, Sticky} from 'react-sticky';
import {getTheme, getSetting} from '@/assets/js/setting'
import sdk from '@/routes/wxShare'
import $ from 'jquery'

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      bookId: 0,
      sortby: 'ASC',
      totalcount: 0,
      system: 'ios',
      currentchapterid: 0,
      page: 1,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      dataType: 'cataLogList',
      useBodyScroll: false,
      refreshing: false,
      isLoading: true,
      hasMore: true,
      pagesize: 20,
      isFirstLoad: true
    }
  }

  text = () => {
    const num = this.state.bookList.length / 100
    const intNum = (parseInt(num))
    const arr = []
    for (let i = 0; i < intNum; i++) {
      let str = `${i * 10}1~${(i + 1) * 100}章`
      arr.push(str)
    }
    const str = String(num)
    if (str.indexOf('.')) {
      const index = str.indexOf('.')
      let odd = (num + '').substring(index + 1) * 1
      if (String(odd).length == 1) {
        odd *= 10
      }
      const lastStr = `${intNum * 10}1~${(intNum) * 100 + (odd)}章`
      arr.push(lastStr)
    }
    this.setState({
      chapterText: arr
    })
  }

  slice = (num) => {
    const {bookList} = this.state

    let end = 100 * num
    let start = end - 100
    this.setState({
      sliceList: bookList.slice(start, end)
    })
  }

  onClose = key => () => {
    this.setState({
      [key]: false,
    });
  }

  modalOpen = () => {
    this.setState({
      modal: true,
    });
  }

  chosenChapter = (index) => {
    this.slice(index + 1)
    this.setState({
      modal: false,
      isIndex: index + 1
    });
  }

  listSort = () => {
    const {sliceList, isReverse} = this.state
    this.setState({
      sliceList: sliceList.reverse(),
      isReverse: !isReverse
    })
  }

  reqChapterList = (action) => {
    const {page, stamp, sortby} = this.state
    let params = {
      page: page,
      sortby: sortby,
      bookId: this.state.bookId
    }

    params = $.extend(params, axiosParam(this.context,stamp))

    setTimeout(() => {
      if (!this.state.hasMore) {
        return
      }

      axios.post(global.constants.apiUrl + 'novel/getVoiceBookChapterList', {...params}).then(res => {
        if (res.data.list.data.length > 0) {
          this.state.dataList = [...this.state.dataList, ...res.data.list.data]
          this.setState({
            pagesize: res.data.pagesize,
            totalcount: res.data.totalcount,
            page: this.state.page + 1,
            dataList: this.state.dataList,
            dataSource: this.state.dataSource.cloneWithRows(this.state.dataList),
            isLoading: false
          });
          if (this.state.isFirstLoad) {
            this.scrollToAnchor('chapter-' + res.data.currentchapterid)
          }
          this.setState({
            isFirstLoad: false
          })
        } else {
          this.setState({
            hasMore: false,
            isLoading: false,
            isFirstLoad: false
          })
        }
        this.setState({
          refreshing: false
        })
      })

    }, 10);


  }

  onRefresh = () => {
    this.setState({isFirstLoad:true,refreshing: true, isLoading: true, hasMore: true, page: 1, dataList: []});
    this.reqChapterList();
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqChapterList();
  }

  goBack = () => {
    this.props.history.goBack()
  }

  changeSort = () => {
    this.setState({
      page: 1,
      sortby: (this.state.sortby == 'ASC') ? 'DESC' : 'ASC'
    }, () => {
      this.onRefresh();
    })
  }

  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView(
          {behavior: 'auto'}
        );
      }
    }
  }

  onRef = (ref) => {
    this.financeListItem = ref
  }

  render() {
    const {dataSource, totalcount, sortby, hasMore, refreshing, isLoading, pagesize, dataType, useBodyScroll, setting} = this.state
    const {changeSort, goBack, onRefresh, onEndReached, onRef} = this
    const style = {'marginTop': '45px', 'height': document.documentElement.clientHeight - 45}

    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 目录' : ''}>
        </DocumentTitle>

        <div className={`catalog aui-content aui-bg-wx ${getTheme()}`}>
          <StickyContainer>
            <Sticky>
              {({
                  style
                }) => (
                <NavBar
                  mode="light"
                  className={`top-nav aui-bg-wx ${getTheme()}`}
                  icon={<div className={`aui-iconfont aui-icon-left aui-text-color-pink`}/>}
                  onLeftClick={goBack}
                  rightContent={[
                    <div
                      class="aui-pull-right aui-text-color-pink aui-padded-t-5 aui-padded-b-5 aui-text-align-c aui-font-size-14"
                      onClick={() => {
                        changeSort()
                      }}>{sortby == 'DESC' ? '倒序' : '正序'}</div>
                  ]}
                >共{totalcount}章</NavBar>
              )}
            </Sticky>
          </StickyContainer>

          <div className={`aui-margin-15 aui-margin-t-5 aui-margin-b-0 chapter-list aui-border-radius-10`}>
            <ul>
              <FinanceListItem onEndReached={onEndReached} hasMore={hasMore}
                               dataSource={dataSource} onRefresh={onRefresh} isLoading={isLoading}
                               refreshing={refreshing} onRef={onRef} totalCount={totalcount} pagesize={pagesize}
                               dataType={dataType} style={style} useBodyScroll={useBodyScroll} {...this.props}/>
            </ul>
          </div>
        </div>
      </>
    )
  }

  componentDidMount() {
    const {id} = this.props.match.params
    var timestamp = new Date().getTime()
    this.setState({
      bookId: id,
      stamp: timestamp
    }, () => {
      this.reqChapterList()

      sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 目录' : '',url: window.location.href, type: 'common'},timestamp,this.context)
    })
  }
}

export default index
