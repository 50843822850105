var system;
var systemFooter;
var systemFooterHeight;
var u = navigator.userAgent, app = navigator.appVersion;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
if (isAndroid) {
  system = 'andriod';
}
if (isIOS) {
  system = 'ios';
}
systemFooter = 'normal';
systemFooterHeight = 65;

var isIPhoneX = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 375 && window.screen.height === 812;
var isIPhoneXSMax = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 414 && window.screen.height === 896;
var isIPhoneXR = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 2 && window.screen.width === 414 && window.screen.height === 896;
if (isIPhoneX || isIPhoneXSMax || isIPhoneXR) {
  systemFooter = 'taller';
  systemFooterHeight = 65;
}

global.constants = {
  system: system,
  systemFooter: systemFooter,
  systemFooterHeight: systemFooterHeight,
  key: '26669703apia',
  screen:{
    height: window.screen.height,
    width: window.screen.width
  },
  apiUrl: '/fm/'
}