import axios from '@/assets/js/axios'
import ctx from '@/assets/js/ctx'
import {toHome, signParam} from '@/assets/js/string'
import { Modal } from 'antd-mobile'

function isWexin(){
  return (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined')
}

export {
  isWexin
}
