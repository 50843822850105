import React, {Component} from 'react'
import styles from './index.module.scss'
import NavTxt from '@/components/NavTxt'
import FinanceListItem from '@/components/FinanceListItem'
import {PullToRefresh, Button, WhiteSpace, ListView, WingBlank} from 'antd-mobile'
import ctx from '@/assets/js/ctx'
import {axiosParam, toVoiceBuy, string} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'
import BookListItem from '@/components/BookListItem'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import moment from 'moment'
import TopNav from '@/components/TopNav';
import Title from '@/components/Title'
import sdk from '@/routes/wxShare'

function MyBody(props) {
  return (
    <div className={`aui-margin-10 aui-margin-b-0 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}>
      <ul className={``}>
      {props.children}
      </ul>
    </div>
  );
}

function LikeBody(props) {
  return (
    <>
      <div className={`book-list`}>
        <WingBlank size="sm"> {props.children}</WingBlank>
      </div>
    </>
  );
}


@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {

    super()

    let _this = this
    this.state = {
      typeid: '',
      warTypeList: [],
      classList: [],
      bookSorts: [],
      bookStatus: [],
      bookStat: [],
      bookTags: [],
      bookEsilver: [],
      bookPrice: [],
      allBookTags: [],
      bookSize: [],
      modelId: 0,
      warTypeId: 0,
      classId: 0,
      statusId: 0,
      sortId: 0,
      tagId: 0,
      priceId: 0,
      size: 0,
      esilverId: 0,
      defaltState: 1,
      sizeId:0,
      className: '',
      filterId: 0,
      classids: '',
      msale: 0,
      page: 0,
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      refreshing: false,
      isLoading: true,
      hasMore: true,
      pagesize: 10,
      dataType: 'classifyList',
      isFirstLoad: true,
      title: 'FM书库'
    }
  }


  reqBookList = (action) => {
    let {page, sizeId, sortId, statusId, price, classId, tagId, stamp,warTypeId,modelId,esilverId,priceId,typeid,msale} = this.state
    
    let params = {
      page: page + 1,
      size: sizeId,
      sorts: classId,
      status: statusId,
      sorting: sortId,
      tag: tagId,
      wartype: warTypeId,
      model: modelId,
      price: priceId,
      esilver: esilverId,
      classids: '',
      msale: msale,
      typeid: typeid
    };

    params = $.extend(params, axiosParam(this.context,stamp))

    // eslint-disable-next-line default-case
    
        this.setState({
          msale: 1,
          title: '初级会员'
        }, () => {
          params['msale'] = 1
        })
       
    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'sort/getVoiceBookList', params, this)
    }, 10);
  }

  onRefresh = () => {
    this.setState({refreshing: true, isLoading: true, hasMore: true, page: 0, dataList: []});
    this.reqBookList();
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqBookList();
  }

  reqClassfyTags = () => {
    const {stamp,typeid,sortId,warTypeId,msale} = this.state

    let params = {
      classids: '',
      model: '',
      typeid: typeid,
      type: 2,
      sorting: sortId,
      wartype: warTypeId,
      msale: msale
    }
    params = $.extend(params, axiosParam(this.context,stamp))
   
    params['msale']  = 1
    this.context.axios.post(global.constants.apiUrl + 'sort/getVoiceSortList', params
          ).then((res) => {
      this.setState({
        classList: res.data.bookClass,
        bookSorts: res.data.bookSorts,
        bookStatus: res.data.bookStatus,
        bookStat: res.data.bookStat,
        bookTags: res.data.bookTags,
        allBookTags: res.data.bookTags,
        warTypeList: res.data.bookWarType,
        bookEsilver: res.data.bookEsilver,
        bookPrice: res.data.bookPrice,
        bookSize: res.data.bookSize,
        isFirstLoad: false
      })

      this.handleTags()
    })
  }

  checkBookModel = (id) => {
    this.setState({
      modelId: id,
      page: 0,
      filterId: 0
    }, () => {
      this.onRefresh()
    })
  }

  checkBookWarType = (id) => {
    this.setState({
      warTypeId: id,
      page: 0,
      filterId: 0
    }, () => {
      this.onRefresh()
    })
  }

  checkBookEsilver = (id) => {
    this.setState({
      esilverId: id,
      page: 0,
      filterId: 0
    }, () => {
      this.onRefresh()
    })
  }


  checkBookPrice = (id) => {
    this.setState({
      priceId: id,
      page: 0,
      filterId: 0
    }, () => {
      this.onRefresh()
    })
  }

  //书籍类型筛选
  checkBookClass = (id) => {
    this.setState({
      classId: id,
      page: 0,
      filterId: 0
    }, () => {
      this.onRefresh()
    })
  }

  //书籍字数筛选
  checkBookStatus = (id) => {
    this.setState({
      statusId: id,
      page: 0
    }, () => {
      this.onRefresh()
    })
  }

  //书籍更新状态筛选
  checkBookSorts = (id) => {
    this.setState({
      sortId: id,
      page: 0
    }, () => {
      this.onRefresh()
    })
  }

  //书籍标签筛选
  checkBookTags = (id) => {
    this.setState({
      tagId: id,
      page: 0
    }, () => {
      this.onRefresh()
    })
  }

  checkBookSize = (id) => {
    this.setState({
      sizeId: id,
      page: 0
    }, () => {
      this.onRefresh()
    })
  }

  clickFilter = (id) => {
    this.setState({
      filterId: id
    })
  }

  handleScroll = (e) => {
    var scrollTop = e.target.scrollTop;
    if (!(scrollTop <= 10)) {
      this.setState({
        defaltState: 0
      });
    }
    else {
      this.setState({
        defaltState: 1
      });
    }
  }

  onRef = (ref) => {
    this.financeListItem = ref
  }

  handleTags = (e) => {

    $('.sort-tag .aui-grid-label').last().after("<div class='aui-grid-label current aui-margin-b-0 aui-pull-left op-tag'><div class='aui-iconfont aui-font-size-14 aui-icon-top aui-padded-l-5'></div></div>")

    var maxwidth = $('.sort-tag').width();
    var w = 0;
    var index = 0;
    var j = 0
    $('.sort-tag .aui-grid-label').each(function(i,item){
      w += $(this).width()
      if (w > maxwidth)
      {
        w = $(this).width()
        index++
        if (index > 2)
        {
          j = i - 1
          return false
        }
      }

    });

    $('.sort-tag .aui-grid-label').each(function(i,item){
      if (i >= j)
      {
        $(this).hide()
      }
    })

    $('.sort-tag .aui-grid-label').eq(j).after("<div class='aui-grid-label current aui-margin-b-0 aui-pull-left op-tag'><div class='aui-iconfont aui-font-size-14 aui-icon-down aui-padded-l-5'></div></div>")


    $(".sort-tag").on("click",".aui-grid-label .aui-iconfont",function(){
      if ($(this).hasClass('aui-icon-top')) {
        $('.sort-tag .aui-grid-label').each(function(i,item){
          if (i >= j)
          {
            $(this).hide()
          }
        })
        $('.sort-tag .aui-grid-label.op-tag .aui-icon-down').parent().show()
        $('.sort-tag .aui-grid-label.op-tag .aui-icon-top').parent().hide()
      }
      else
      {
        $('.sort-tag .aui-grid-label').each(function(i,item){
          $(this).show()
        })

        $('.sort-tag .aui-grid-label.op-tag .aui-icon-down').parent().hide()
        $('.sort-tag .aui-grid-label.op-tag .aui-icon-top').parent().show()
      }
    });
  }

  changeHomeListFirstHeight = (e) => {
  };

  render() {
    const {classList, classId, filterId, bookStatus, bookSorts, bookTags, sortId, statusId, dataSource, isLoading, refreshing, hasMore, tagId, pagesize, page, bookStat, isFirstLoad,modelId,warTypeId,warTypeList,bookEsilver,esilverId,bookPrice,priceId,typeid,title,sizeId,bookSize} = this.state

    const {checkBookStatus, checkBookClass, checkBookSorts, onEndReached, checkBookTags, onRefresh, handleScroll, onRef,checkBookModel,checkBookWarType,checkBookSize,checkBookPrice,changeHomeListFirstHeight} = this
    const antIcon = <div className={`am-icon-dot`}/>

    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | ' + title : ''}>
        </DocumentTitle>
        {/* nav */}
        <TopNav name={title} history={this.props.history} />
        {/* nav */}
        {!isFirstLoad ?
        <div className={`classify aui-bg-wx ${getTheme()}`}>
          <ListView
            ref={el => this.lv = el}
            dataSource={dataSource}
            pageSize={pagesize}
            useBodyScroll={false}
            style={{'marginTop': '45px', 'height': document.documentElement.clientHeight - 45}}
            renderHeader={(sectionData, sectionID) => (
              <>
                <div className={`aui-content aui-border-radius-10 `}>
                  <div className={`aui-grid sort-category`}>
                      <div className={`aui-row`}>
                        {bookSize && bookSize.map((e, index) =>
                          <div className={`aui-col-5 aui-padded-5`} key={'sizeItem-' + index}>
                            <div className={`aui-grid-label current aui-margin-b-5`}>
                              <div
                                className={`aui-label aui-font-size-14 cur aui-bg-white ${sizeId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold" : ''}`}
                                onClick={() => {
                                  checkBookSize(e.id)
                                }}>{e.name}</div>
                            </div>
                          </div>
                        )}
                      </div>
                  </div>
                  
                    <div className={`aui-grid sort-category`}>
                      <div className={`aui-row`}>
                        {bookPrice && bookPrice.map((e, index) =>
                          <div className={`aui-col-5 aui-padded-5`} key={'priceItem-' + index}>
                            <div className={`aui-grid-label current aui-margin-b-5`}>
                              <div
                                className={`aui-label aui-font-size-14 cur aui-bg-white ${priceId == e.id ? "aui-text-color-pink aui-label-danger aui-text-bold" : ''}`}
                                onClick={() => {
                                  checkBookPrice(e.id)
                                }}>{e.name}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={`aui-grid sort-category total-statics aui-text-align-l`}>
                      <div className={`aui-row aui-text-align-l`}>
                        <div class="aui-col-xs-9 aui-padded-5 aui-padded-l-5 aui-font-size-14" style={{'textAlign':'left'}}><span>截止{moment().format("DD/MM/YYYY")}，{title}共{bookStat.totalCount}本。</span>
                        </div>
                        <div className={`aui-col-xs-3 aui-padded-0`}>
                        <Button inline size="small" className={`btn-small aui-margin-r-5`} type="warning" onClick={() => {
                            toVoiceBuy(this.context)
                          }}>开通</Button>
                        </div>
                      </div>
                    </div>
              </div>
              <WhiteSpace/>
              <div className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white image`}>
                  <div class="aui-content aui-margin-b-0 aaa aui-padded-10" style={{ 'margin': '0px' }}>
                  <img src={require('@/assets/images/cjhy.png')}  />
                </div>
              </div>
              </>
            )}
            renderBodyComponent={() => <MyBody/>}
            renderSectionBodyWrapper={sectionID => <LikeBody/>}
            renderRow={
              (rowData, sectionId, rowID) => {
                return <BookListItem {...this.props} deleteUserBook={this.deleteUserBook} homeList={rowData} rowID={rowID}  changeHomeListFirstHeight={changeHomeListFirstHeight}/>
              }
            }
            renderFooter={() => (<div style={{'clear': 'both', padding: 0, textAlign: 'center'}}>
              {isLoading ? antIcon : (hasMore ? '' : '没有更多')}
            </div>)}
            pullToRefresh={<PullToRefresh
              indicator={{activate: antIcon, deactivate: antIcon, release: antIcon, finish: antIcon}}
              refreshing={refreshing}
              distanceToRefresh={55}
              onRefresh={onRefresh}
            />}
            onEndReached={onEndReached}
            onEndReachedThreshold={20}
          />
        </div>
        : '' }
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    const { typeid } = this.props.match.params;
    this.setState({
      typeid: typeid,
      stamp: timestamp
    }, () => {
      //window.addEventListener('scroll', this.handleScroll);
      this.reqClassfyTags()
      this.reqBookList()
      sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | ' + this.state.title : '',url: window.location.href, type: 'common'},timestamp,this.context)
    })


  }
}

export default index
