/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable default-case */
import React, { Component } from 'react';
import axios from '@/assets/js/axios.js';
import { getUserInfo, quitLogin } from '@/assets/js/user';
import HeaderNav from '@/components/HeaderNav';
import WxPay from '@/components/WxPay';
import ctx from '@/assets/js/ctx';
import {
  getQueryString,
  signParam,
  toOrderList,
  axiosParam,
  toBookRead,
  toCatalog,
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import {
  PullToRefresh,
  Flex,
  Button,
  List,
  ListView,
  WhiteSpace,
  WingBlank,
  Result,
  Icon,
  Toast,
  SegmentedControl,
} from 'antd-mobile';
import { Input } from 'antd-mobile-v5';
import TopNav from '@/components/TopNav';
import Title from '@/components/Title';
import { loadingMore } from '@/assets/js/content';
import { string } from '@/assets/js/string';
import { getTheme, getSetting } from '@/assets/js/setting';
import $ from 'jquery';
import { toPage, toAliPay, toHome } from '@/assets/js/string'
import sdk from '@/routes/wxShare'
import copy from "copy-to-clipboard";  
const Item = List.Item;
const Brief = Item.Brief;

function MyBody(props) {
  return <div className="am-list-body my-body">{props.children}</div>;
}

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      pageTitle: '充值中心',
      userInfo: {},
      siteInfo: {},
      ruleId: 0,
      typeId: 2,
      payMoney: '-',
      payCustomMoney: '',
      page: 0,
      isAllLoaded: false,
      financeRuleList: [],
      financeMonthlyRuleList: [],
      isFirstLoaded: true,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      pagesize: 10,
      isLoading: true,
      refreshing: true,
      down: true,
      hasMore: true,
      useBodyScroll: true,
      paySuccess: false,
      showAliPay: true,
      payType: 0,
      payTypeList: ['支付宝', '高V支付'],
    };
  }

  reqFinanceList = action => {
    const { stamp, typeId, orderId } = this.state;
    let params = {
      siteId: 1,
      orderId: orderId,
      typeId: typeId,
      pos: 'list',
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'pay/getVoiceFinanceList', params, this);
    }, 10);
  };

  onRefresh = () => {
    this.setState({ dataList: [], isFirstLoaded: true, refreshing: true });
    this.reqFinanceList();
  };

  onChangePay = (payMoney, ruleId) => {
    this.setState(
      {
        payCustomMoney: '',
        payMoney: payMoney,
        ruleId: ruleId,
      },
      () => {
        for (var j = 0; j <= this.state.dataList.length - 1; j++) {
          if (this.state.dataList[j].money == this.state.payMoney) {
            this.setState({
              payMoney: this.state.dataList[j]['money'],
            });
            break;
          }
        }
      }
    );
  };

  onRef = ref => {
    this.child = ref;
  };

  toWxPay = e => {
    const { ruleId, typeId, payMoney } = this.state;
      toAliPay(ruleId, payMoney, this.state.userInfo.userid, this.context);
  };

  payNotify = e => {
    window.location.reload();
  };

  onChangeCustomPay = (payMoney, ruleId) => {
    const { typeId } = this.state;
    switch (typeId) {
      case 1:
        if (payMoney > 6000) {
          Toast.fail('最大6000元', 1);
          payMoney = 6000;
        }
        break;
      case 2:
        break;
    }
    this.setState(
      {
        payMoney: payMoney * 100,
        payCustomMoney: payMoney,
        ruleId: ruleId,
      },
      () => {}
    );
  };

  onTabChange = e => {
    switch (e.nativeEvent.selectedSegmentIndex) {
      case 0:      
        this.setState(
          {
            typeId: 2,
            payType: 0,
            payCustomMoney: '',
            ruleId: 0,
          },
          () => {
            this.onRefresh();
          }
        );
        break;
      case 1:
        this.setState(
          {
            typeId: 5,
            payType: 1,
            payCustomMoney: '',
            ruleId: 0,
          },
          () => {
            this.onRefresh();
          }
        );
        break;
    }
  };

  copyToClipboard = e => {
    copy('公司名（账户名称）：蜜阅（杭州）科技有限公司,开户行：杭州银行城西支行,账号：3301040160005666980');  
    Toast.success('账户信息已复制，请使用手机银行转账功能支付转账', 1);
  }
  
  LikeBody = props => {
    const { orderId, payType, payCustomMoney, payTypeList } = this.state;
    return (
      <>
        <div className={`blank`}></div>
        <SegmentedControl
          values={payTypeList}
          tintColor={'#ff0000'}
          selectedIndex={payType}
          style={{ height: '45px', margin: '0px 10px 10px 10px' ,border:'1px solid #ff0000',borderRadius:'5px'}}
          onChange={this.onTabChange}
        />
        <div className={`aui-margin-t-0 aui-bg-white free-list`}>
          <div class="aui-content aui-pay-body">
            <section class="aui-grid">
              <div class="aui-row">
                <WhiteSpace size="sm" />
                {props.children}
                {payType === 1 ?
                  <List
                    className={`aui-margin-t-10 btn-large ${payCustomMoney !== '' ? 'current' : ''
                      }`}
                  >
                      <Input
                        placeholder="*任意金额无任何点/券，非邀请请勿充值。"
                        value={payCustomMoney}
                        onChange={val => {
                          this.onChangeCustomPay(val, 143);
                        }}
                        style={{
                          '--placeholder-color': '#ff0000',
                          '--color': '#ff0000',
                          fontSize: '14px',
                          height: '44px',
                          padding: '0px 15px',
                        }}
                      />
                  </List>
                  : ''}
                <WhiteSpace size="sm" />
              </div>
            </section>
          </div>
        </div>
        
          <div className={`aui-row-padded buy-body aui-margin-l-15 aui-margin-r-15`}>
            <div className={` aui-margin-t-10`} style={{ marginTop: '20px!important' }}>
              <Button
                className={`aui-border-radius-5 btn-large`}
                type="warning"
                onClick={() => {
                  this.toWxPay();
                }}
              >
                立即充值：{this.state.payMoney / 100} 元
              </Button>
            </div>
          </div>
        {payType === 1 ?
          <>
            <WhiteSpace />
            <div className={`aui-margin-t-0 more-info aui-bg-white`}>
              <Title name={'转账说明'} />
              <div class="aui-content aui-margin-b-0 aaa" style={{ margin: '0px' }}>
                <ul class="aui-list aui-media-list aui-bg-none">
                  <li class="aui-list-item aui-bg-none">
                    <div class="aui-media-list-item-inner">
                      <div class="aui-list-item-inner">
                        <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10">
                          <div className={` aui-text-color-pink`}>*为保障资金安全，请使用手机银行转账功能支付转账。</div>
                        </div>
                        <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10" onClick={() => {this.copyToClipboard()}}>
                          &nbsp;公司名（账户名称）：蜜阅（杭州）科技有限公司<br/>
                          &nbsp;开户行：杭州银行城西支行<br/>
                          &nbsp;账号：3301040160005666980
                        </div>
                        <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10" onClick={() => { this.copyToClipboard() }}>
                            &nbsp;转账完成后，联系客服进行人工入账FM点。
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            </>
          : ''}
        {orderId !== '1' ?
          <>
            <WhiteSpace />
            <div className={`aui-margin-t-0 more-info aui-bg-white`}>
              <Title name={'充值说明'} />
              <div class="aui-content aui-margin-b-0 aaa" style={{ margin: '0px' }}>
                <ul class="aui-list aui-media-list aui-bg-none">
                  <li class="aui-list-item aui-bg-none">
                    <div class="aui-media-list-item-inner">
                      <div class="aui-list-item-inner">
                        <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10">
                          *1元=1 FM点。FM券为系统赠送，可用于指定书籍购买。
                          <br />
                          *FM点为虚拟产品，充值后不可退款。
                          <br />
                          *若充值后FM点未到账，请及时与客服联系。菜单栏-客服。
                          <br />
                          *工作时间：周一至周五，9点～6点。
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            </>
          : ''}
      </>
    );
  };

  render() {
    const {
      userInfo,
      refreshing,
      paySuccess,
      showAliPay,
      ruleId,
      isFirstLoaded,
      dataSource,
      pagesize,
      pageTitle,
      setting,
      dataList,
    } = this.state;

    const { onTabChange, onRefresh, onChangePay, toWxPay, LikeBody } = this;
    const antIcon = <div className={`am-icon-dot`} />;

    dataList.forEach(item => {
      if (isFirstLoaded && item.isDefault == 1) {
        this.setState(
          {
            ruleId: item.ruleid,
            payMoney: item.money,
            isFirstLoaded: false,
          },
          () => {}
        );
      }
    });

    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | ' + pageTitle : ''}
        ></DocumentTitle>
        <TopNav name={pageTitle} history={this.props.history} />

        <div className={`aui-pay-index aui-bg-wx ${getTheme()}`}>
          {!paySuccess ? (
            <ListView
              ref={el => (this.lv = el)}
              dataSource={dataSource}
              useBodyScroll={false}
              pageSize={pagesize}
              style={{ marginTop: '45px', height: document.documentElement.clientHeight - 45 }}
              renderSectionHeader={(sectionData, sectionID) => (
                <>
                  <Flex className={`userinfo aui-bg-white aui-margin-t-0`}>
                    <Flex.Item>
                      <List className={`avatar`}>
                        <Item
                          arrow="horizontal"
                          thumb={userInfo.avatar}
                          multipleLine
                          arrow=""
                          onClick={() => {}}
                        >
                          {userInfo.nickname}
                          <Brief>ID:{userInfo.userid}</Brief>
                        </Item>
                      </List>
                    </Flex.Item>
                    <Flex.Item>
                      <List className="my-list">
                        <Item arrow="horizontal" multipleLine arrow="">
                          <span className={`aui-font-size-14`}>
                            {this.context.siteinfo && this.context.siteinfo.currencyname}：
                            {userInfo.egold} 点
                          </span>
                          <Brief>
                            <span className={`aui-font-size-14`}>
                              {this.context.siteinfo && this.context.siteinfo.esilvername}：
                              {userInfo.esilver} 劵
                            </span>
                          </Brief>
                        </Item>
                      </List>
                    </Flex.Item>
                  </Flex>
                </>
              )}
              renderBodyComponent={() => <MyBody />}
              renderSectionBodyWrapper={sectionID => <LikeBody />}
              renderRow={(rowData, sectionId, rowID) => {
                switch (rowData.typeid) {
                  case 1:
                    return (
                      <List
                        className={`${rowID > 0 ? 'aui-margin-t-10' : ''} btn-large ${
                          ruleId == rowData.ruleid ? 'current' : ''
                        }`}
                        onClick={() => {
                          onChangePay(rowData.money, rowData.ruleid);
                        }}
                      >
                        <Item
                          thumb={ruleId == rowData.ruleid ? require('@/assets/icon/right.png') : ''}
                          extra={<span className={`aui-font-size-14`}>{rowData.name}</span>}
                        >
                          <span className={`aui-font-size-14`}>
                            {rowData.egold > 0 ? rowData.egold + ' ' + (this.context.siteinfo && this.context.siteinfo.currencyname) + ' ' : ''}
                            {rowData.esilver > 0
                              ? '+ 送 ' +
                                rowData.esilver +
                                ' ' +
                                (this.context.siteinfo && this.context.siteinfo.esilvername) +
                                ''
                              : ''}
                          </span>
                        </Item>
                      </List>
                    );
                    break;
                  case 2:
                    return (
                      <List
                        className={`aui-margin-t-10 btn-large ${
                          ruleId == rowData.ruleid ? 'current' : ''
                        }`}
                        onClick={() => {
                          onChangePay(rowData.money, rowData.ruleid);
                        }}
                      >
                        <Item
                          thumb={ruleId == rowData.ruleid ? require('@/assets/icon/right.png') : ''}
                          extra={
                            <span className={`aui-font-size-14`}>
                              {string.delHtmlTag(rowData.name)}
                            </span>
                          }
                        >
                          <span className={`aui-font-size-14`}>
                            {string.delHtmlTag(rowData.desc)}
                            {rowData.desc_3
                              ? '（' +
                                (rowData.desc_3.indexOf('送') != -1
                                  ? rowData.desc_3 + '点'
                                  : rowData.desc_2) +
                                '）'
                              : ''}
                          </span>
                        </Item>
                      </List>
                    );
                    break;
                  case 4:
                  case 3:
                    return (
                      <List
                        className={`${rowID > 0 ? 'aui-margin-t-10' : ''} btn-large ${
                          ruleId == rowData.ruleid ? 'current' : ''
                        }`}
                        onClick={() => {
                          onChangePay(rowData.money, rowData.ruleid);
                        }}
                      >
                        <Item
                          thumb={ruleId == rowData.ruleid ? require('@/assets/icon/right.png') : ''}
                          extra={<span className={`aui-font-size-14`}>{rowData.name}</span>}
                        >
                          <span className={`aui-font-size-14`}>
                            {rowData.desc_2} 
                          </span>
                        </Item>
                      </List>
                    );
                    break;
                }
              }}
              pullToRefresh={
                <PullToRefresh
                  indicator={{
                    activate: antIcon,
                    deactivate: antIcon,
                    release: antIcon,
                    finish: antIcon,
                  }}
                  refreshing={refreshing}
                  distanceToRefresh={55}
                  onRefresh={onRefresh}
                />
              }
            />
          ) : (
            <>
              <WhiteSpace />
              <div
                className={`aui-margin-15 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}
              >
                <div class="aui-content aui-pay-body">
                  <section class="aui-grid">
                    <div class="aui-row">
                      <div className="sub-title">支付成功</div>
                      <Result
                        img={
                          <Icon
                            type="check-circle"
                            className="spe"
                            style={{ fill: '#ff0000', width: '3rem', height: '3rem' }}
                          />
                        }
                        title="支付成功"
                        message="我们将在24小时内发送授权文件至指定邮箱，请注意查收。节假日顺延。"
                      />
                      <Button
                        className={`aui-border-radius-10 btn-large`}
                        type="warning"
                        onClick={() => {
                          toOrderList(this.context);
                        }}
                      >
                        查看订单
                      </Button>
                    </div>
                  </section>
                </div>
              </div>
              <WhiteSpace />
            </>
          )}
        </div>
        <WxPay onRef={this.onRef} payNotify={this.payNotify}></WxPay>
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();
    const { orderid } = this.props.match.params;
    this.setState(
      {
        stamp: timestamp,
        orderId: orderid,
      },
      () => {
        getUserInfo(this.context, timestamp).then(res => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context);
            }
          } else {
            this.setState({
              userInfo: res.data,
            });
            this.reqFinanceList();
            sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | ' + this.state.pageTitle : '',url: window.location.href, type: 'common'},timestamp,this.context)
          }
        });
      }
    );
  }
}

export default index;
