import MD5 from 'crypto-js/md5'
import MyGlobal from "../../components/MyGlobal";

export function getTheme(v) {
  try {
    let setting = JSON.parse(localStorage.getItem('setting'))
    let thememode = setting['themeMode']
    let darkmode = setting['darkMode']
    if (thememode == 'system') {
      if (new Date().getHours() <= 6 || new Date().getHours() >= 16) {
        return 'day'
      }
    }
    else {
      return darkmode
    }
  }
  catch (err) {
    let setting = {
      themeMode: 'system',
      darkMode: 'normal'
    }
    localStorage.setItem('setting', JSON.stringify(setting))
  }
}

export function getThemeValue(v) {
  try {
    let setting = JSON.parse(localStorage.getItem('setting'))
    let thememode = setting['themeMode']
    let darkmode = setting['darkMode']
    if (thememode == 'system') {
      if (new Date().getHours() <= 6 || new Date().getHours() >= 16) {
        return '#1f1f1f'
      }
    }
    else {
      return '#ffffff'
    }
  }
  catch (err) {
    let setting = {
      themeMode: 'system',
      darkMode: 'normal'
    }
    localStorage.setItem('setting', JSON.stringify(setting))
  }
}

export function getReadTheme(v) {
  try {
    let setting = JSON.parse(localStorage.getItem('setting'))
    let thememode = setting['themeMode']
    let darkmode = setting['darkMode']
    if (thememode == 'system') {
      /* if (new Date().getHours() <= 6 || new Date().getHours() >= 16) {
        return 'night'
      }
      else {*/
        return 'day'
      //}
    }
    else {
      if (darkmode == 'normal') {
        return 'day'
      }
      else {
        return 'night'
      }
    }
  }
  catch (err) {
    let setting = {
      themeMode: 'system',
      darkMode: 'normal'
    }
    localStorage.setItem('setting', JSON.stringify(setting))
  }
}

export function getSetting(v) {
  try {
    return JSON.parse(localStorage.getItem('setting'))
  }
  catch (err) {
    let setting = {
      themeMode: 'system',
      darkMode: 'normal'
    }
    localStorage.setItem('setting', JSON.stringify(setting))
  }
}


export function getOpRecord(v) {
  try {
    return JSON.parse(localStorage.getItem('opRecord'))
  }
  catch (err) {
    let setting = {
      classIfy: [
        
      ]
    }
    localStorage.setItem('setting', JSON.stringify(setting))
  }
}