import React, {Component} from 'react'
import {Provider} from '@/assets/js/ctx' // Provider组件
import axios from '@/assets/js/axios'
import ctx from "../../assets/js/ctx"; // 配置好的axios
import {getQueryString,signParam,isNull} from '@/assets/js/string'
import $ from 'jquery'
export class MyGlobal extends Component {
  constructor() {
    super()
    //localStorage.setItem("openid","oi0GuwGcOig0HQb3XBjNT5ow7MPw")

    this.state = {
      ver: getQueryString('ver'),
      channel: getQueryString('channel'),
      versionId: getQueryString('versionId'),
      userId: localStorage.getItem("userId"),
      site: getQueryString('site'),
      axios,
      commonUrl: 'http://localhost:8899'
    }
  }

  render() {
    const {axios, commonUrl, ver, channel, versionId, userId, site, siteinfo} = this.state
    return (
      /*
          使用Provider组件，给子组件注入数据，数据存放在value属性中
          这里的子组件就是整个项目的组件，所以在这里提供的数据，每个组件都能获取
      */
      <Provider value={{
        axios,
        commonUrl,
        ver,
        channel,
        versionId,
        userId,
        site,
        siteinfo
      }}>
        {this.props.children}
      </Provider>
    )
  }

  componentDidMount() {
    if (isNull(sessionStorage.getItem('siteinfo'))) {
      var stamp = new Date().getTime()
      
      axios.post(global.constants.apiUrl + `site/getSiteBaseInfo`, {
        ver: getQueryString('ver'),
        channel: getQueryString('channel'),
        versionId: getQueryString('versionId'),
        userId: localStorage.getItem("userId"),
        site: getQueryString('site'),
        timestamp: stamp,
        sign: signParam(this.context.ver, this.context.channel, this.context.versionId, this.context.userId, this.context.site, stamp)
      })
        .then((res) => {
          if (res.code == 1) {
            var siteEntity = {
              domain: res.data.domain,
              ver: res.data.ver,
              currencyname: res.data.currencyname,
              esilvername: res.data.esilvername,
              name: res.data.name,
              title: res.data.title,
              wxmpname: res.data.wxmpname,
              wxcode: res.data.wxcode
            };
            sessionStorage.setItem('siteinfo', JSON.stringify(siteEntity));

            this.setState({
              ...this.state,
              siteinfo: siteEntity
            })
          }
        })
    }
    else
    {
      var siteEntity = JSON.parse(sessionStorage.getItem('siteinfo'));
      this.setState({
        ...this.state,
        siteinfo: siteEntity
      })
    }
  }
}

export default MyGlobal

