import React, {Component} from 'react'
import '@/config/run.js'
import ctx from '@/assets/js/ctx'
import {toPage, toPay, toBookRead,toHome} from '@/assets/js/string'
import {StickyContainer, Sticky} from 'react-sticky';
import {Tabs, Flex,Icon} from 'antd-mobile'
import {getUserInfo,quitLogin} from '@/assets/js/user'
import {getTheme, getSetting} from '@/assets/js/setting'

export class index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
      userInfo: {},
      isWechat: true
    }
  }

  toHome = () => {
    this.props.history.push('/home')
  }
  quit = (context) => {
    localStorage.clear();
    toHome(context)
  }

  renderTabBar = (props) => {
    const { userInfo } = this.state
    return (<Sticky>
      {({style}) => <div style={{...style, zIndex: 1}}>
        <div className="nav-txt aui-bg-wx">
          <Flex>
            <Flex.Item style={{'flex': '2'}}>
              <Tabs.DefaultTabBar {...props} />
            </Flex.Item>
            <Flex.Item style={{'flex': '1'}}>
              <div className={`aui-pull-right aui-margin-r-15`} style={{'padding': '16px 0'}}><img src={userInfo.avatar}
                                                                                                   className={`aui-pull-left userinfo avatar`}
                                                                                                   onClick={() => {
                                                                                                     toPage('person', this.context)
                                                                                                   }}/>
                <div
                  className={`aui-iconfont aui-icon-search aui-pull-left aui-margin-l-15 search`}
                  onClick={() => {
                    toPage('search', this.context)
                  }}/>
              </div>
            </Flex.Item>
          </Flex></div>
      </div>}
    </Sticky>);
  }

  onTabClick = (index, tab) => {
    switch (tab) {
      case 0:
        toPage('home', this.context)
        break;
      case 1:
        toPage('make', this.context)
        break;
      case 2:
        toPage('bookshelf', this.context)
        break;
    }
  }

  render() {
    const {currentTab,fixed} = this.props
    const {quit, onTabClick} = this
    const {userInfo, isWechat} = this.state
    const tabs = [
      {title: '版权', key: 'home'},
      {title: '书库', key: 'classify'},
      {title: '书架', key: 'bookshelf'},
      {title: '订单', key: 'orderList'}
    ];

    return (
      <>

          <div className={`nav-txt ${this.context.ver === 'v1' ? 'v1' : ''} aui-bg-wx ${getTheme()}`} style={fixed === '1' ? {'position': 'relative','top':'auto','zIndex':'1'} : {'position': 'absolute'}}>
            <Flex>
              <Flex.Item style={{'flex': '3'}}>
                <ul style={{'padding':'10px 0px'}}>
                  <li className={`${ currentTab == 'home' ? 'current' : ''} `} onClick={() => {
                    toPage('home',this.context)
                }}>版权</li>
                {this.context.ver === 'v1' ?
                  <li className={`${currentTab == 'train' ? 'current' : ''} `} onClick={() => {
                    toPage('train', this.context)
                  }}>配音</li>
                  : ''}
                  <li className={`${ currentTab == 'make' ? 'current' : ''} `} onClick={() => {
                    toPage('make',this.context)
                  }}>赚钱</li>
                   <li className={`${ currentTab == 'orderList' || currentTab == 'bookshelf' ? 'current' : ''} `} onClick={() => {
                    toPage('orderList',this.context)
                  }}>订单</li>
                </ul>
              </Flex.Item>
            <Flex.Item style={{ 'flex': '2','marginLeft':'0px' }}>
              {!isWechat ?
                <div className={`aui-pull-right aui-margin-r-15 quit`} style={{ 'padding': '10px 0' }} on={() => {
                  quit(this.context)
                }}>退出</div>
                : ''}
              
              <div className={`aui-pull-right aui-margin-r-15`} style={{ 'padding': '12px 0','position':'relative','marginLeft':'0.7rem'}}>
                <Flex>
                  <Flex.Item>
                  <img src={userInfo.avatar}
                                                                                                     className={`aui-pull-left userinfo avatar`}
                                                                                                     onClick={() => {
                                                                                                       toPage('person', this.context)
                      }} />
                    <span className={`my-txt`}>我的</span>
                  </Flex.Item>
                </Flex>
              </div>
              <div className={`search-input aui-font-size-12 aui-text-color-pink`} onClick={() => {
                      toPage('search', this.context)
              }}><Icon type="search" size={`xxs`}/><span>搜一搜</span></div>
              </Flex.Item>
            </Flex></div>

      </>
    )
  }
  componentDidMount() {
    var timestamp = new Date().getTime()
    //this.onRefresh();
    this.setState({
      stamp: timestamp
    }, () => {
      getUserInfo(this.context, timestamp)
        .then((res) => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context)
            }
          }
          else{
            this.setState({
              userInfo: res.data,
              isWechat: (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined') ? true : false
            }, () => {
             
            })
          }
        })

    });
  }
}

export default index
