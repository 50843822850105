import React, {Component} from 'react'
import axios from '@/assets/js/axios'
import FinanceListItem from '@/components/FinanceListItem'
import {PullToRefresh, ListView, Toast, WingBlank, WhiteSpace} from 'antd-mobile'
import HeaderNav from '@/components/HeaderNav'
import ctx from '@/assets/js/ctx'
import {axiosParam, getQueryString, string} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'
import TopNav from '@/components/TopNav'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import BookListItem from '@/components/BookListItem'
import sdk from '@/routes/wxShare'

function MyBody(props) {
  return (
   <div className={`aui-margin-10 aui-margin-b-0 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}>
      <ul className={``}>
      {props.children}
      </ul>
    </div>
  );
}

function LikeBody(props) {
  return (
    <>
      <div className={`book-list`}>
        <WingBlank size="sm"> {props.children}</WingBlank>
      </div>
    </>
  );
}

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      step: 1,
      hotList: [],
      keywordList: [],
      historyList: [],
      Hotrecomm: [],
      isUpdateHk: false,
      selectedKey: [],
      isSearch: false,
      keyword: '',
      searchResult: [''],
      page: 1,
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      refreshing: false,
      isLoading: false,
      hasMore: false,
      dataType: 'searchList',
      useBodyScroll: false,
      totalCount: 0,
      pagesize: 10
    }
  }

  //搜索框onChange事件
  onChange = (e) => {
    this.setState({
      keyword: e.target.value
    })
  };

  //获取热搜词组
  reqHistory = () => {
    const {stamp} = this.state

    let params = {};

    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `search/getHistory`, params).then((res) => {
      const list = res.data
      this.setState({
        hotList: res.data.searchHot,
        historyList: res.data.searchHistory,
        isLoadFinished: true
      })
    })
  }

  reqSearchList = (action) => {
    const {page, stamp, keyword} = this.state
    let params = {
      page: page,
      keyword: keyword
    };

    params = $.extend(params, axiosParam(this.context,stamp))

    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'search/getBookList', params, this)
    }, 10);
  }

  onRefresh = () => {
    this.setState({refreshing: true, isLoading: true, hasMore: true, page: 0, dataList: []});
    this.reqSearchList();
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqSearchList();
  }

  /* 删除关键词 */
  clearKeyword = () => {
    this.setState({
      value: '',
      step: 1,
      keyword: '',
      isLoading: false,
      dataList: [],
      hasMore: false,
      refreshing: false,
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      })
    }, () => {
      this.reqHistory()
    })
  }

  /* 搜索 */
  search = () => {
    if (this.state.keyword == '') {
      Toast.info('请输入关键词', 1)
      return
    }
    this.setState({
      step: 2
    }, () => {
      this.onRefresh()
    })
  }

  searchKeyword = (value) => {
    this.setState({
      keyword: value,
      step: 2
    }, () => {
      this.onRefresh()
    })
  }

  //清空历史记录
  clearHistory = () => {
    const {stamp} = this.state

    let params = {};

    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `search/clearHistoryKeyword`, params).then((res) => {
      this.setState({
        bookList: [],
        historyList: [],
        step: 1,
      }, () => {

      })
    })
  }

  onCancel = () => {
    this.setState({
      dataList: [],
      selectedKey: [],
      isNoBook: false,
      value: ''
    })
  }

  onRef = (ref) => {
    this.financeListItem = ref
  }

  changeHomeListFirstHeight = (e) => {
    
  };
  render() {
    const {clearKeyword, searchKeyword, clearHistory, search, onEndReached, onRefresh, onRef,changeHomeListFirstHeight} = this
    const {step, hotList, historyList, totalcount, dataSource, isLoading, refreshing, hasMore, dataType, useBodyScroll, setting, pagesize, keyword} = this.state
    const style = {'marginTop': '0px', 'height': document.documentElement.clientHeight - 45}
const antIcon = <div className={`am-icon-dot`}/>
    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 搜索' : ''}>
        </DocumentTitle>
        <TopNav name={'搜索'} history={this.props.history}/>

        <div className={`search aui-bg-wx ${getTheme()}`}>
          <ListView
            ref={el => this.lv = el}
            dataSource={dataSource}
            pageSize={pagesize}
            useBodyScroll={false}
            style={{'marginTop': '45px', 'height': document.documentElement.clientHeight - 45}}
              renderHeader={(sectionData, sectionID) => (
              <>
                  <div class={`aui-searchbar`} id="search">
                    <div class="aui-searchbar-input aui-border-radius" tapmode>
                      <i class="aui-iconfont aui-icon-search"></i>
                      <input type="search" value={this.state.keyword} placeholder="书名/作者/书号"
                            onChange={this.onChange.bind(this)}
                            defaultValue={this.state.keyword}/>
                      <span class="fa fa-del aui-pull-right clear-input" style={{'position': 'absolute', 'right': '5px'}}
                            onClick={() => {
                              clearKeyword()
                            }}></span>
                    </div>
                    <div class="aui-btn aui-btn-danger cur" onClick={() => {
                      search()
                    }}>搜索
                    </div>
                  </div>
                  {step == 1 ?
                    <>
                      <WhiteSpace size="md" />
                      <section class="aui-content">
                        <div class="aui-card-list aui-border-radius-10 aui-margin-b-0 aui-margin-t-0">
                          <div class="aui-card-list-header aui-font-size-16 aui-text-gray-light">
                            <div class="aui-font-size-18 aui-margin-l-0 aui-text-color-black">
                              <span class="fa fa-shutiao aui-text-danger" style={{ 'margin-left': '-0.2rem' }}></span>热门搜索
                            </div>
                          </div>
                          <div class="aui-content">
                            <ul class="aui-list aui-media-list">
                              {hotList && hotList.map((el, index) =>
                                <li class="aui-list-item aui-list-item-middle aui-pull-left"
                                  style={{ 'width': '50%', 'background': 'none' }} onClick={() => {
                                    searchKeyword(el.articlename)
                                  }}>
                                  <div class="aui-media-list-item-inner">
                                    <div class="aui-list-item-media aui-padded-r-5" style={{ 'width': '2.5rem' }}>
                                      <img src={el.cover} class="aui-image-s-s" style={{ 'height': '2.5rem' }}
                                        onerror="this.src='/images/nocover.jpg';" />
                                    </div>
                                    <div class="aui-list-item-inner">
                                      {string.truncate(el.articlename, 20, '...')}
                                    </div>
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </section>
                      <WhiteSpace size="md" />
                      <section class="aui-content">
                        <div class="aui-card-list aui-border-radius-10 aui-margin-t-0 aui-bg-white">
                          <div class="aui-card-list-header aui-font-size-16 aui-text-gray-light">
                            <div class="aui-font-size-18 aui-margin-l-0 aui-text-color-black">
                              <span class="fa fa-shutiao aui-text-danger" style={{ 'margin-left': '-0.2rem' }}></span>历史记录
                            </div>
                            <div class="aui-list-item-right aui-text-gray-light aui-font-size-18" onClick={() => {
                              clearHistory()
                            }}
                              ms-on-tap="@btnClearHistoryKeywordKey">
                              <i class="aui-iconfont aui-icon-trash aui-font-size-18"></i>
                            </div>
                          </div>
                          <div class="aui-card-list-content-padded">
                            <div class="history-list">
                              {historyList && historyList.map((el, index) =>
                                <div
                                  class="aui-label aui-label-outlined aui-margin-b-10 aui-font-size-16 aui-pull-left cur"
                                  onClick={() => {
                                    searchKeyword(el.keyword)
                                  }}>{string.truncate(el.keyword, 12, '...')}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                    : ''}
                  
                </>
                
            )}
            renderBodyComponent={() => <MyBody/>}
            renderSectionBodyWrapper={sectionID => <LikeBody/>}
            renderRow={
              (rowData, sectionId, rowID) => {
                return <BookListItem {...this.props} deleteUserBook={this.deleteUserBook} homeList={rowData} rowID={rowID}  changeHomeListFirstHeight={changeHomeListFirstHeight}/>
              }
            }
            renderFooter={() => (<div style={{'clear': 'both', padding: 0, textAlign: 'center'}}>
              {isLoading ? antIcon : (hasMore ? '' : '没有更多')}
            </div>)}
            pullToRefresh={<PullToRefresh
              indicator={{activate: antIcon, deactivate: antIcon, release: antIcon, finish: antIcon}}
              refreshing={refreshing}
              distanceToRefresh={55}
              onRefresh={onRefresh}
            />}
            onEndReached={onEndReached}
            onEndReachedThreshold={20}
          />
        </div>
        
      </>

    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    var keyword = getQueryString('keyword')
    this.setState({
      stamp: timestamp,
      keyword: keyword
    }, () => {
      this.reqHistory()
      this.onRefresh()
      sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 搜索' : '',url: window.location.href, type: 'common'},timestamp,this.context)
    })
  }
}

export default index
