import React, { Component } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import ctx from "@/assets/js/ctx";
import { getUserInfo, quitLogin } from "@/assets/js/user";
import { isWexin } from "@/assets/js/tool";
import axios from "@/assets/js/axios";
import Menu from "@/components/Menu";
import {
  axiosParam,
  signParam,
  toPage,
  toHome,
  toBookRead,
  toUserRankIntro,
  toVoiceBuy,
  toOrderPay,
} from "@/assets/js/string";
import DocumentTitle from "react-document-title";
import { checkToken } from "@/assets/js/hoc.js";
import {
  PullToRefresh,
  WingBlank,
  WhiteSpace,
  List,
  Card,
  Button,
  Grid,
} from "antd-mobile";
import TopNav from "@/components/TopNav";
import { getTheme, getSetting } from "@/assets/js/setting";
import $ from "jquery";
import sdk from "@/routes/wxShare";
import NavigatorTabbar from "@/components/NavigatorTabbar";
import WxPay from "@/components/WxPay";
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class Index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      userInfo: [],
      myInfo: {
        monthly: {
          day: "未开通",
          date: "开通",
        },
        user_readtime: 0,
      },
      siteInfo: [],
      height: document.documentElement.clientHeight,
      isLoadFinished: false,
    };
  }

  //用户信息
  reqMyInfo = () => {
    const { stamp } = this.state;

    let params = {};
    params = $.extend(params, axiosParam(this.context, stamp));

    axios
      .post(global.constants.apiUrl + `user/getUserHome`, params)
      .then((res) => {
        this.setState({
          myInfo: res.data,
          isLoadFinished: true,
          refreshing: false,
        });
      });
  };

  onRefresh = () => {
    this.setState({
      page: 0,
      refreshing: true,
    });
    this.reqMyInfo();
  };
  onRef = (ref) => {
    this.child = ref;
  };

  toWxPay = (e) => {
    this.child.init(this.state.payMoney, this.state.ruleId);
  };

  payNotify = (e) => {
    window.location.reload();
  };
  onOp(type, link, e) {
    switch (type) {
      case 1:
        toPage(link, e);
        break;

      case 2:
        toVoiceBuy(e, link);
        break;
    }
    window.location.href =
      "/" +
      page +
      "?ver=" +
      e.ver +
      "&channel=" +
      e.channel +
      "&versionId=" +
      e.versionId +
      "&site=" +
      e.site;
  }

  toQuit(content) {
    localStorage.clear();
    toHome(content)
  }

  render() {
    const { myInfo, userInfo, refreshing, height, setting } = this.state;
    const { onRefresh, set, onOp, toQuit } = this;
    const antIcon = <div className={`am-icon-dot`} />;
    let accountList = Array.from([
      {
        title: userInfo.egold,
        tag: this.context.siteinfo && this.context.siteinfo.currencyname,
      },
      {
        title: userInfo.esilver,
        tag: this.context.siteinfo && this.context.siteinfo.esilvername,
      },
      {
        title: myInfo.monthly.date,
        tag: "VIP阅读",
      },
      {},
    ]);

    for (var i in userInfo["monthCard"]) {
      accountList.push({
        title: userInfo["monthCard"][i]["txt"],
        tag: userInfo["monthCard"][i]["day"],
      });
    }

    const moduleList = Array.from([
      {
        icon: require("@/assets/icon/activity/wxpay-red.png"),
        name: "活动付款",
        type: 1,
        link: "Activity/pay",
      },
      {
        icon: require("@/assets/icon/setting.png"),
        name: "我的设置",
        type: 1,
        link: "setting",
      },
      {
        icon: require("@/assets/icon/ali.png"),
        name: "支付宝支付",
        type: 1,
        link: "Activity/alipay",
      },
      {
        icon: require("@/assets/icon/999独家.png"),
        name: "初级会员",
        type: 1,
        link: "topList/cj",
      },
      {
        icon: require("@/assets/icon/垃圾桶.png"),
        name: "券兑换点",
        type: 1,
        link: "exchange/point",
      },
      {
        icon: require("@/assets/icon/czfm.png"),
        name: "支付记录",
        type: 1,
        link: "user/financeLog",
      },
      {
        icon: require("@/assets/icon/czfm.png"),
        name: "短篇训练营",
        type: 2,
        link: "3",
      },
    ]);

    if (this.context.ver !== "v1") {
      moduleList.push({
        icon: require("@/assets/icon/pay.png"),
        name: "小额支付",
        type: 1,
        link: "Activity/custompay",
      });
    }

    return (
      <>
        <DocumentTitle
          title={
            this.context.siteinfo
              ? this.context.siteinfo.title + " | 我的"
              : "我的"
          }
        ></DocumentTitle>

        <div className={`my aui-bg-wx aui-bg-white ${getTheme()}`}>
          <WhiteSpace style={{}} />

          <List
            className={`userinfo aui-bg-white aui-padded-t-10 aui-padded-b-10 aui-border-radius-10`}
          >
            <Item
              thumbStyle={{ width: "70px", height: "70px" }}
              thumb={userInfo.avatar}
              multipleLine
              extra={isWexin() === false ?
                <><Button
                  type="warning"
                  inline
                  size="small"
                  activeStyle={false}
                  className={`btn-small`}
                  onClick={() => {
                    toQuit(this.context);
                  }}
                >
                  退出
                </Button></>
                : ''
              }
            >
              {userInfo.nickname}
              <Brief>ID:{userInfo.userid}</Brief>
              <Brief className={`vip`}>
                {(() => {
                  switch (userInfo.level) {
                    case 1:
                      return (
                        <>
                          <img
                            src={require("@/assets/icon/user/chujihuiyuan.png")}
                            style={{ float: "left" }}
                          />
                          新晋会员
                        </>
                      );
                    case 2:
                      return (
                        <>
                          <img
                            src={require("@/assets/icon/user/gjhy-v.png")}
                            style={{ float: "left" }}
                          />
                          充值会员
                        </>
                      );
                    case 3:
                      return (
                        <>
                          <img
                            src={require("@/assets/icon/user/gjhy_v.png")}
                            style={{ float: "left" }}
                          />
                          高V会员
                        </>
                      );
                      break;
                  }
                })()}
              </Brief>
            </Item>
          </List>

          <div className={`blank`}></div>

          <div className={`aui-bg-white account-block aui-border-radius-10`}>
            <Card className={`account aui-padded-b-0`}>
              <Card.Header
                title="我的账户"
                thumbStyle={{ width: "22px", height: "22px" }}
                extra={
                  <Button
                    type="warning"
                    activeStyle={false}
                    className={`aui-margin-t-5 btn-small`}
                    inline
                    size="small"
                    onClick={() => {
                      toOrderPay(0, this.context);
                    }}
                  >
                    充值
                  </Button>
                }
              />
            </Card>

            <Grid
              data={accountList}
              columnNum={4}
              hasLine={false}
              itemStyle={{ height: "80px" }}
              renderItem={(dataItem) => (
                <div>
                  <div className={`aui-font-size-14`}>{dataItem.title}</div>
                  <div
                    className={`aui-margin-t-5 aui-text-color-gray aui-font-size-14`}
                  >
                    {dataItem.tag}
                  </div>
                </div>
              )}
            />
          </div>

          <div className={`blank`}></div>

          <div className={`aui-bg-white month-block aui-border-radius-10`}>
            <Card className={`month aui-padded-b-0`}>
              <Card.Header
                title="我的包月"
                thumbStyle={{ width: "22px", height: "22px" }}
                extra={
                  <Button
                    type="warning"
                    className={`btn-small`}
                    inline
                    size="small"
                    onClick={() => {
                      toVoiceBuy(this.context, 2);
                    }}
                  >
                    开通
                  </Button>
                }
              />
            </Card>

            <div
              className={`module`}
              style={{
                height: document.documentElement.clientHeight - 300.25 - 75,
              }}
            >
              <Grid
                data={moduleList}
                columnNum={4}
                hasLine={false}
                square={true}
                onClick={(el) => onOp(el.type, el.link, this.context)}
                renderItem={(dataItem) => (
                  <div className={`aui-text-align-c`}>
                    <img
                      src={dataItem.icon}
                      className={`aui-margin-center aui-margin-t-10`}
                      style={{ width: "23px", height: "23px" }}
                      alt=""
                    />
                    <div className={`aui-font-size-14 aui-margin-t-10`}>
                      <span>{dataItem.name}</span>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <NavigatorTabbar selectedTab="person"></NavigatorTabbar>
        <WxPay onRef={this.onRef} payNotify={this.payNotify}></WxPay>
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();
    this.setState(
      {
        stamp: timestamp,
      },
      () => {
        getUserInfo(this.context, timestamp).then((res) => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context);
            }
          } else {
            this.setState(
              {
                userInfo: res.data,
              },
              () => {
                this.reqMyInfo();
                sdk.getJSSDK(
                  {
                    title: this.context.siteinfo
                      ? this.context.siteinfo.title + " | 我的"
                      : "",
                    url: window.location.href,
                    type: "common",
                  },
                  timestamp,
                  this.context
                );
              }
            );
          }
        });
      }
    );
  }
}

export default Index;
