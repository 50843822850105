import React, {Component} from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import ctx from '@/assets/js/ctx'
import {getUserInfo,quitLogin} from '@/assets/js/user'
import axios from '@/assets/js/axios'
import {getTheme, getSetting} from '@/assets/js/setting'
import {getQueryString, signParam, toPage, toBookCover, toBookRead, toCatalog, toBuy} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {PullToRefresh, WingBlank, WhiteSpace, List, Card, Button, Grid} from 'antd-mobile';
import TopNav from '@/components/TopNav'
import sdk from '@/routes/wxShare'

const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class Index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
      flag: true
    }
  }

  set = (type, value) => {
    switch (type) {
      case 'mode':
        var setting = getSetting()
        setting['darkMode'] = value
        localStorage.setItem('setting', JSON.stringify(setting))
        this.setState({
          flag: new Date().getTime()
        }, () => {

        })
        break;
      case 'theme':
        var setting = getSetting()
        setting['themeMode'] = getSetting()['themeMode'] == 'system' ? '' : 'system'
        localStorage.setItem('setting', JSON.stringify(setting))
        this.setState({
          flag: new Date().getTime()
        }, () => {

        })
        break;
    }
  }


  render() {
    const {myInfo, userInfo, refreshing, height, setting, flag} = this.state
    const {set} = this

    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + '|我的' : '我的'}>
        </DocumentTitle>
        <TopNav name={'设置'} history={this.props.history}/>

        <div className={`setting aui-bg-wx ${getTheme(flag)}`} style={{'marginTop': '45px','minHeight':(global.constants.screen.height - 45)+ 'px'}}>

          <WingBlank>
            <WhiteSpace style={{}}/>

            <div className={`aui-border-radius-10 aui-bg-white setting-block`}>

              <ul class="aui-list">
                <li class="aui-list-item">
                  <div class="aui-list-item-inner">
                    <div class="aui-list-item-title">跟随系统<br/>
                      <span className={`aui-font-size-12`}>开启后，将跟随系统打开或关闭深色模式</span></div>
                    <div class="aui-list-item-right">
                      <input name="a" type="checkbox" class="aui-switch" onClick={() => {
                        set('theme')
                      }} checked={getSetting()['themeMode'] === 'system' ? true : false}/>
                    </div>
                  </div>
                </li>
              </ul>

              <div className={`blank`}></div>
              <div className={`aui-border-radius-10 aui-bg-white setting-block`}>
                <ul class="aui-list aui-list-in">
                  <li class="aui-list-header">
                    手动选择
                  </li>
                  <li class="aui-list-item">
                    <div class="aui-list-item-inner">
                      <div class="aui-list-item-title">白天模式</div>
                      <div class="aui-list-item-right">
                        <input name="a" type="checkbox" class="aui-switch" onClick={() => {
                          set('mode', 'normal')
                        }} checked={getSetting()['darkMode'] === 'normal' ? true : false}/>
                      </div>
                    </div>
                  </li>
                  <li class="aui-list-item">
                    <div class="aui-list-item-inner">
                      <div class="aui-list-item-title">夜间模式</div>
                      <div class="aui-list-item-right">
                        <input name="a" type="checkbox" class="aui-switch" onClick={() => {
                          set('mode', 'dark')
                        }} checked={getSetting()['darkMode'] === 'dark' ? true : false}/>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>


            </div>
            <WhiteSpace/>
          </WingBlank>
        </div>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    }, () => {

    })
  }
}

export default Index

