import React, {Component} from 'react'
import '@/config/run.js'
import ctx from '@/assets/js/ctx'
import {toPage, toPay, toBookRead,toHome} from '@/assets/js/string'
import {Tabs, Flex,Icon} from 'antd-mobile'
import {getUserInfo,quitLogin} from '@/assets/js/user'
import {getTheme, getSetting} from '@/assets/js/setting'
import { TabBar } from 'antd-mobile'
import bookhot from '@/assets/icon/book-hot.png';


export class index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
      userInfo: {},
      isWechat: true
    }
  }

  onTabClick = (url) => {
        toPage(url, this.context)
  }

  render () {
    const { onTabClick} = this
    const {selectedTab} = this.props
    return (
      <>
        <div className={`navigator-tabbar aui-font-size-12`}>
             <TabBar
          unselectedTintColor="#949494"
          tintColor="#ff0000"
          barTintColor="white"
          tabBarPosition="bottom"
          hidden={this.state.hidden}
          prerenderingSiblingsNumber={0}
        >
          <TabBar.Item
            title="版权"
            key="home"
            icon={<div style={{
                width: '25px',
                height: '25px',
                background: `url('${require('@/assets/icon/navigatortabbar/copyright.png')}') center center /  25px 25px no-repeat`}}
            />
            }
            selectedIcon={<div style={{
              width: '25px',
              height: '25px',
              background: `url('${require('@/assets/icon/navigatortabbar/copyright-hover.png')}') center center /  25px 25px no-repeat`}}
            />
            }
              selected={selectedTab === 'home'}
            onPress={() => {
              onTabClick('home')
            }}
          >
          </TabBar.Item>
                    <TabBar.Item
            icon={<div style={{
                width: '25px',
                height: '25px',
                background: `url('${require('@/assets/icon/navigatortabbar/order.png')}') center center /  25px 25px no-repeat`}}
            />
            }
            selectedIcon={<div style={{
              width: '25px',
              height: '25px',
              background: `url('${require('@/assets/icon/navigatortabbar/order-hover.png')}') center center /  25px 25px no-repeat`}}
            />
            }
            title="订单"
            key="orderList"
              selected={selectedTab === 'orderList'}
            onPress={() => {onTabClick('orderList')}}
            >
              </TabBar.Item>
          <TabBar.Item
            icon={<div style={{
                width: '25px',
                height: '25px',
                background: `url('${require('@/assets/icon/navigatortabbar/发现灰.png')}') center center /  20px 20px no-repeat`}}
            />
            }
            selectedIcon={<div style={{
              width: '25px',
              height: '25px',
              background: `url('${require('@/assets/icon/navigatortabbar/发现红.png')}') center center /  20px 20px no-repeat`}}
            />
            }
            title="充值"
            key="money"
            selected={selectedTab === 'money'}
            onPress={() => {toPay(0,this.context)}}
          >
            </TabBar.Item>
          
            <TabBar.Item
           icon={<div style={{
                width: '25px',
                height: '25px',
                background: `url('${require('@/assets/icon/navigatortabbar/my.png')}') center center /  25px 25px no-repeat`}}
            />
            }
            selectedIcon={<div style={{
              width: '25px',
              height: '25px',
              background: `url('${require('@/assets/icon/navigatortabbar/my-hover.png')}') center center /  25px 25px no-repeat`}}
            />
            }
            title="我的"
            key="person"
              selected={selectedTab === 'person'}
            onPress={() => {onTabClick('person')}}
          >
            
          </TabBar.Item>
        </TabBar>
         </div>

      </>
    )
  }
  componentDidMount() {
    var timestamp = new Date().getTime()
    //this.onRefresh();
    this.setState({
      stamp: timestamp
    }, () => {
      

    });
  }
}

export default index
