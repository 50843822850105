/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React, {Component} from 'react'
import {toPage, axiosParam, toBookRead} from '@/assets/js/string'
import ctx from '@/assets/js/ctx'
import { Carousel, WingBlank } from 'antd-mobile'
import { Swiper } from 'antd-mobile-v5'
import { SwiperRef } from 'antd-mobile-v5/es/components/swiper'
import $ from 'jquery'

export class Group extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      stamp: '',
      list: []
    }
  }

  reqBannerList = (action) => {
    const {stamp} = this.state
    const {positionId} = this.props
    let params = {
      positionId: positionId
    }
    params = $.extend(params, axiosParam(this.context,stamp))

    this.context.axios.post(global.constants.apiUrl + `recommend/getBannerList`, params).then((res) => {
      if (res.code == 1) {
        this.setState({
          list: res.data
        })
      }
    })
  }

  toLink = (url) => {
    location.href = url
  }
  render() {
    
    const { toLink } = this
    const {list} = this.state
    const {positionId,con} = this.props
    return (
      <>
        <Swiper autoplay loop={true}>
           {list && list.map((item, index) => 
          <Swiper.Item key={index}>
            <div className={`aui-card-list aui-bg-wx`} style={{'position': 'relative','marginBottom':'0px'}} onClick={() => {
          if(item.link_type === 2)
            toPage(item.link_url, this.context)
          else if (item.link_type === 1)
            toLink(item.link_url)
          else
            return
             }} key={'item-' + index}>
                    <div className={`aui-card-list-content`}>
                   <img src={item.image} className={`aui-border-radius-10`}/>
                    </div>
                  </div>
          </Swiper.Item>
        )}
         </Swiper>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()

    this.setState({
      stamp: timestamp
    }, () => {
      this.reqBannerList();
    })
  }
}

export default Group
