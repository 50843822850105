import React, {Component} from 'react'
import {NavBar, Icon} from 'antd-mobile';
import {toPage} from '@/assets/js/string'
import ctx from '@/assets/js/ctx'
import { StickyContainer, Sticky } from 'react-sticky';
import {getTheme, getSetting} from '@/assets/js/setting'

export class index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
    }
  }

  goBack = () => {
    this.props.history.goBack()
  }
  // 回到首页
  toHome = () => {
    toPage('home', this.context)
  }

  
  render() {
    const {goBack, toHome} = this
    const {setting}  = this.state
    const {name} = this.props
    return (
      <>
        {name &&
        <>
          <StickyContainer>
            <Sticky>
              {({
                  style
                }) => (
                <NavBar
                  mode="light"
                  className={`top-nav aui-bg-wx ${getTheme()}`}
                  style={{maxWidth:'750px'}}
                  icon={<div className={`aui-iconfont aui-icon-left aui-text-color-pink`}/>}
                  onLeftClick={goBack}
                  rightContent={[
                    <div className={`aui-iconfont aui-icon-home aui-text-color-pink aui-font-size-18`} onClick={toHome}/>
                  ]}
                >{name}</NavBar>
              )}
            </Sticky>
          </StickyContainer>
        </>
        }
      </>
    )
  }
}

export default index
