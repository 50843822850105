import React, { Component } from "react";
import FinanceListItem from "@/components/FinanceListItem";
import {
  ListView,
  WingBlank,
  WhiteSpace,
  PullToRefresh,
  SegmentedControl,
  List,
  Button,
  Flex,
  Toast,
} from "antd-mobile";
import ctx from "@/assets/js/ctx";
import {
  axiosParam,
  toOrderDetail,
  toPage,
  toBookCover,
  toDownload,
  string,
  toOrderPay,
} from "@/assets/js/string";
import DocumentTitle from "react-document-title";
import { checkToken } from "@/assets/js/hoc.js";
import { loadingMore } from "@/assets/js/content";
import TopNav from "@/components/TopNav";
import { getTheme, getSetting } from "@/assets/js/setting";
import { Grid } from "antd-mobile-v5";
import Title from "@/components/Title";
import axios from "@/assets/js/axios.js";
import NavTxt from "@/components/NavTxt";
import Countdown from "react-countdown";
import $ from "jquery";
import sdk from "@/routes/wxShare";
import NavigatorTabbar from "@/components/NavigatorTabbar";
const Item = List.Item;
const Brief = Item.Brief;

function LikeBody(props) {
  return (
    <div className={`aui-margin-t-5 aui-bg-white aui-border-radius-10`}>
      <ul className={``}>{props.children}</ul>
    </div>
  );
}

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      defaltState: 1,
      className: "",
      filterId: 0,
      page: 0,
      dataSource: new ListView.DataSource({
        // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      refreshing: false,
      isLoading: true,
      hasMore: true,
      dataType: "readLogList",
      totalCount: 0,
      pagesize: 10,
      useBodyScroll: false,
    };
  }

  onRefresh = () => {
    this.setState({
      refreshing: true,
      isLoading: true,
      hasMore: true,
      page: 0,
      dataList: [],
    });
    this.reqOrderList();
  };

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true,
    });

    this.reqOrderList();
  };

  reqOrderList = (action) => {
    const { page, stamp } = this.state;
    let params = {
      page: page + 1,
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    setTimeout(() => {
      loadingMore(
        global.constants.apiUrl + "order/getVoiceOrderList",
        params,
        this
      );
    }, 0);
  };

  toReview = (orderid, val) => {
    const { stamp } = this.state;
    let params = {
      siteId: 3,
      orderId: orderid,
      score: val,
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    axios
      .post(global.constants.apiUrl + `order/setOrderView`, params)
      .then((res) => {
        if (res.code === "1") {
          Toast.success("感谢您的评论", 1);
          window.location.reload();
        } else {
          Toast.fail(res.msg, 1);
        }
      });
  };

  onTabChange = (e) => {
    // eslint-disable-next-line default-case
    switch (e.nativeEvent.selectedSegmentIndex) {
      case 1:
        toPage("bookshelf", this.context);
        break;
      case 0:
        break;
    }
  };

  render() {
    const {
      dataSource,
      isLoading,
      refreshing,
      hasMore,
      dataType,
      totalCount,
      pagesize,
      useBodyScroll,
      setting,
    } = this.state;

    const { onEndReached, onRef, onRefresh, toReview, onTabChange } = this;
    const antIcon = <div className={`am-icon-dot`} />;
    const style = {
      marginTop: "55px",
      height: document.documentElement.clientHeight - 55,
    };

    function MyBody(props) {
      return (
        <>
          <SegmentedControl
            values={["我的订单", "我的书架"]}
            tintColor={"#ff0000"}
            selectedIndex={0}
            style={{ height: "40px", margin: "15px 15px 15px 15px" }}
            onChange={onTabChange}
          />
          <div className="am-list-body my-body">{props.children}</div>
        </>
      );
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <></>;
      } else {
        // Render a countdown
        return (
          <>
            <div
              className={`count-down pull-right aui-margin-r-5`}
              style={{ width: "6rem" }}
            >
              <Grid columns={3} gap={3}>
                <Grid.Item>
                  <div>{hours}</div>
                </Grid.Item>
                <Grid.Item>
                  <div>{minutes}</div>
                </Grid.Item>
                <Grid.Item>
                  <div
                    style={{
                      border: "0px",
                      color: "#ffffff",
                      lineHeight: "1.43rem",
                      background: "#ff0000",
                    }}
                  >
                    {seconds}
                  </div>
                </Grid.Item>
              </Grid>
            </div>
          </>
        );
      }
    };

    return (
      <>
        <DocumentTitle
          title={
            this.context.siteinfo
              ? this.context.siteinfo.title + " | 我的订单"
              : ""
          }
        ></DocumentTitle>
        <>
          <div
            className={`aui-content user-order-list  aui-bg-wx ${getTheme()}`}
          >
            <ListView
              ref={(el) => (this.lv = el)}
              dataSource={dataSource}
              useBodyScroll={useBodyScroll}
              initialListSize={pagesize}
              pageSize={pagesize}
              style={{
                marginTop: "0px",
                height: document.documentElement.clientHeight - 75,
                overflow: "hidden",
                overflowY: "scroll",
              }}
              renderBodyComponent={() => <MyBody />}
              renderSectionBodyWrapper={(sectionID) => <LikeBody />}
              renderRow={(readLogList, sectionId, rowID) => {
                return (
                  <>
                    <div
                      className={`aui-list aui-media-list history-book aui-bg-tran aui-margin-t-0`}
                    >
                      <span className={`aui-font-size-14 aui-text-bold`}>
                        <Title name={readLogList.sn} />
                      </span>
                      {readLogList.booklist.map((item, index) => {
                        return (
                          <>
                            <li className={`aui-list-item aui-bg-none`}>
                              <div className={`aui-media-list-item-inner`}>
                                <div
                                  className={`aui-list-item-media aui-margin-0 aui-padded-t-5`}
                                  style={{ width: "auto" }}
                                >
                                  <img
                                    src={item.thumburl}
                                    className={`square`}
                                    onError={(e) => {
                                      e.target.src =
                                        "http://i.mixs.cn/upload/cover/default.jpeg";
                                    }}
                                  />
                                </div>
                                <div
                                  className={`aui-list-item-inner aui-padded-r-5 aui-padded-t-0`}
                                >
                                  <div className={`aui-list-item-text`}>
                                    <span
                                      className={`aui-font-size-14 aui-text-color-black aui-text-bold`}
                                    >
                                      {string.truncate(
                                        item.articlename,
                                        12,
                                        "..."
                                      )}
                                    </span>
                                    <span>
                                      {readLogList.order_type === 2
                                        ? "年卡订单"
                                        : ""}
                                    </span>
                                  </div>
                                  <Brief>
                                    <div
                                      className={`aui-margin-t-10 aui-text-align-l`}
                                    >
                                      <span className={`aui-font-size-14`}>
                                        {readLogList.created_at}
                                      </span>
                                    </div>
                                  </Brief>
                                  <WhiteSpace style={{}} />
                                  <Brief
                                    style={{
                                      position: "absolute",
                                      right: "15px",
                                      top: "20px",
                                    }}
                                  >
                                    <div
                                      className={`aui-list-item-text aui-margin-t-0 aui-text-align-r all-sale`}
                                    >
                                      <span className={`aui-font-size-14`}>
                                        {readLogList.order_fee > 0 ? (
                                          <>
                                            <span
                                              className={` aui-text-color-pink`}
                                            >
                                              ¥{readLogList.order_fee}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span
                                              className={`text-decoration aui-text-color-pink`}
                                            >
                                              ¥{readLogList.market_price}
                                            </span>
                                          </>
                                        )}
                                        <br />
                                        <span className={`aui-font-size-12`}>
                                          FM点
                                        </span>
                                      </span>
                                    </div>
                                  </Brief>
                                  <Brief>
                                    <div
                                      className={`aui-margin-t-10 aui-text-align-r`}
                                    >
                                      <Flex
                                        className={`aui-font-size-14 aui-float-right`}
                                      >
                                        {(() => {
                                          switch (readLogList.order_status) {
                                            case -1:
                                              return (
                                                <Flex.Item>
                                                  <Button
                                                    size="small"
                                                    type="warning"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 pull-right`}
                                                    onClick={() => {
                                                      toOrderDetail(
                                                        readLogList.order_id,
                                                        this.context
                                                      );
                                                    }}
                                                  >
                                                    等待审核
                                                  </Button>
                                                </Flex.Item>
                                              );
                                            case -2:
                                              return (
                                                <Flex.Item>
                                                  <Button
                                                    size="small"
                                                    type="warning"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 pull-right`}
                                                    onClick={() => {
                                                      toOrderDetail(
                                                        readLogList.order_id,
                                                        this.context
                                                      );
                                                    }}
                                                  >
                                                    审核失败
                                                  </Button>
                                                </Flex.Item>
                                              );
                                            case 0:
                                              return (
                                                <>
                                                  <Flex.Item>
                                                    <Button
                                                      size="small"
                                                      type="warning"
                                                      inline
                                                      className={`aui-padded-l-5 aui-padded-r-5 pull-right`}
                                                      onClick={() => {
                                                        toOrderPay(
                                                          readLogList.order_id,
                                                          this.context
                                                        );
                                                      }}
                                                    >
                                                      立即付款
                                                    </Button>
                                                    <Countdown
                                                      date={
                                                        readLogList.payendtime
                                                      }
                                                      intervalDelay={0}
                                                      precision={3}
                                                      renderer={renderer}
                                                    />
                                                  </Flex.Item>
                                                </>
                                              );
                                            case 1:
                                              return (
                                                <Flex.Item>
                                                  {readLogList.export_novel ===
                                                  0 ? (
                                                    <Button
                                                      size="small"
                                                      type="warning"
                                                      inline
                                                      className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                      onClick={() => {
                                                        toDownload(
                                                          readLogList.order_id,
                                                          this.context
                                                        );
                                                      }}
                                                    >
                                                      导出txt
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      inline
                                                      className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                    >
                                                      已导出
                                                    </Button>
                                                  )}
                                                  <Button
                                                    size="small"
                                                    type="warning"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 pull-right aui-margin-r-5 `}
                                                  >
                                                    等待授权
                                                  </Button>
                                                  <Button
                                                    size="small"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-10 pull-right`}
                                                  >
                                                    已付款
                                                  </Button>
                                                </Flex.Item>
                                              );
                                            case 2:
                                              return (
                                                <Flex.Item>
                                                  {readLogList.export_novel ===
                                                  0 ? (
                                                    <Button
                                                      size="small"
                                                      type="warning"
                                                      inline
                                                      className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                      onClick={() => {
                                                        toDownload(
                                                          readLogList.order_id,
                                                          this.context
                                                        );
                                                      }}
                                                    >
                                                      导出txt
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      inline
                                                      className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                    >
                                                      已导出
                                                    </Button>
                                                  )}
                                                  <Button
                                                    size="small"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                  >
                                                    已授权
                                                  </Button>
                                                  <Button
                                                    size="small"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                  >
                                                    已付款
                                                  </Button>
                                                </Flex.Item>
                                              );
                                            case 3:
                                              return (
                                                <Flex.Item>
                                                  {readLogList.export_novel ===
                                                  0 ? (
                                                    <Button
                                                      size="small"
                                                      type="warning"
                                                      inline
                                                      className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                      onClick={() => {
                                                        toDownload(
                                                          readLogList.order_id,
                                                          this.context
                                                        );
                                                      }}
                                                    >
                                                      导出txt
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      inline
                                                      className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                    >
                                                      已导出
                                                    </Button>
                                                  )}
                                                  <Button
                                                    size="small"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                  >
                                                    已授权
                                                  </Button>
                                                  <Button
                                                    size="small"
                                                    inline
                                                    className={`aui-padded-l-5 aui-padded-r-5 aui-margin-r-5 pull-right`}
                                                  >
                                                    已付款
                                                  </Button>
                                                </Flex.Item>
                                              );
                                              break;
                                            case -4:
                                              return (<Flex.Item>
                                                <Button
                                                  size="small"
                                                  type="warning"
                                                  inline
                                                  className={`aui-padded-l-5 aui-padded-r-5 pull-right`}
                                                >
                                                  已退订
                                                </Button>
                                              </Flex.Item>);
                                              break;
                                            case 4:
                                              return "";
                                            default:
                                              return null;
                                          }
                                        })()}
                                      </Flex>
                                    </div>
                                  </Brief>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </div>
                  </>
                );
              }}
              renderFooter={() => (
                <div style={{ clear: "both", padding: 0, textAlign: "center" }}>
                  {isLoading ? antIcon : hasMore ? "" : "没有更多"}
                </div>
              )}
              pullToRefresh={
                <PullToRefresh
                  indicator={{
                    activate: antIcon,
                    deactivate: antIcon,
                    release: antIcon,
                    finish: antIcon,
                  }}
                  refreshing={refreshing}
                  distanceToRefresh={55}
                  onRefresh={onRefresh}
                />
              }
              onEndReached={onEndReached}
              onEndReachedThreshold={20}
              onScroll={this.props.onScroll}
            />
          </div>
          <NavigatorTabbar selectedTab="orderList"></NavigatorTabbar>
        </>
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();
    this.setState(
      {
        stamp: timestamp,
      },
      () => {
        this.reqOrderList();
        sdk.getJSSDK(
          {
            title: this.context.siteinfo
              ? this.context.siteinfo.title + " | 订单列表"
              : "",
            url: window.location.href,
            type: "common",
          },
          timestamp,
          this.context
        );
      }
    );
  }
}

export default index;
