import React, {Component} from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import ctx from '@/assets/js/ctx'
import {getUserInfo,quitLogin} from '@/assets/js/user'
import axios from '@/assets/js/axios'
import Menu from '@/components/Menu'
import {axiosParam,toOrderPay} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {PullToRefresh, WingBlank, WhiteSpace, List, Card, Button, Grid,Toast} from 'antd-mobile';
import { CapsuleTabs, Form, Input } from 'antd-mobile-v5';
import TopNav from '@/components/TopNav'
import {getTheme, getSetting} from '@/assets/js/setting'
import $ from 'jquery'
import sdk from '@/routes/wxShare'
import NavigatorTabbar  from '@/components/NavigatorTabbar'
import Title from '@/components/Title';
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class Index extends Component {
  static contextType = ctx

  constructor() {
    super()

    this.state = {
      userInfo: [],
      myInfo: {
        monthly: {
          day: "未开通",
          date: "开通"
        },
        user_readtime: 0
      },
      siteInfo: [],
      height: document.documentElement.clientHeight,
      isLoadFinished: false,
      esilver:10
    }
  }

  //用户信息
  reqMyInfo = () => {
    const {stamp} = this.state

    let params = {};
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `user/getUserHome`, params).then((res) => {
      this.setState({
        myInfo: res.data,
        isLoadFinished: true,
        refreshing: false
      })
    })
  }

  onRefresh = () => {
    this.setState({
      page: 0,
      refreshing: true
    });
    this.reqMyInfo();
  }

  onExchange = () => {
     const {stamp,esilver} = this.state

    let params = {
      type: 1,
      esilver: esilver
    };
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `user/setUserExchange`, params).then((res) => {
      if (res.code <= 0) {
        Toast.fail(res.msg, 1)
      }
      else
      {
        Toast.success('兑换成功', 1, () => {
          window.location.reload();
        });
      }
    })
  }
  onChangeExchange = (e) => {
    this.setState(
      {
        esilver: e
      },
      () => {}
    );
  }

  render() {
    const {myInfo, userInfo, refreshing, height, esilver} = this.state
    const {onRefresh, onExchange} = this
    const antIcon = <div className={`am-icon-dot`}/>
    let accountList = Array.from([{
      title: userInfo.egold,
      tag: this.context.siteinfo && this.context.siteinfo.currencyname
    }, {
      title: userInfo.esilver,
      tag: this.context.siteinfo && this.context.siteinfo.esilvername
    }, {}]);
  
    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 我的' : '我的'}>
        </DocumentTitle>
       
        <div className={`my aui-bg-wx aui-bg-white ${getTheme()}`}>

              <WhiteSpace style={{}}/>

              <div className={`aui-bg-white account-block`}>
                <Card
                  className={`account aui-padded-b-0`}>
                  <Card.Header
                    title="我的账户"
                    thumbStyle={{'width': '22px', 'height': '22px'}}
                    extra={<Button type="warning" activeStyle={false} className={`aui-margin-t-5 btn-small`}
                                   inline
                                   size="small" onClick={() => {
                                    toOrderPay(0,this.context)
                    }}>充值</Button>}
                  />
            </Card>
            <Grid data={accountList}
                      columnNum={4}
                      hasLine={false}
                      itemStyle={{height: '80px'}}
                      renderItem={dataItem => (
                        <div>
                          <div className={`aui-font-size-14`}>
                            {dataItem.title}
                          </div>
                          <div className={`aui-margin-t-5 aui-text-color-gray aui-font-size-14`}>
                            {dataItem.tag}
                          </div>
                        </div>
                      )}
                />
              </div>

              <div className={`blank`}></div>
                <div className={`aui-margin-15 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}>
                  <div class="aui-content aui-pay-body">
                    <section class="aui-grid">
                      <div class="aui-row">
                                <Input
                                  className={`aui-bar-light aui-padded-t-10 aui-padded-b-10`}
                                  placeholder={
                                    '兑换金额'
                                  }
                                  onChange={val => {
                                    this.onChangeExchange(val);
                                  }}
                                  value={esilver}
                                />
                              </div>
                    </section>
                  </div>
                </div>

              <div className={`aui-row-padded buy-body aui-margin-l-15 aui-margin-r-15`}>
                <div className={` aui-margin-t-10`} style={{'marginTop': '20px!important'}}>
                  <Button className={`aui-border-radius-10 btn-large`} type="warning" onClick={() => {
                    this.onExchange()
                  }}>立即兑换：{this.state.esilver / 10} 点
                  </Button>
                </div>
                </div>
          
<WhiteSpace />
          <div className={`aui-margin-t-0 more-info aui-bg-white`}>
            <Title name={'兑换说明'} />
            <div class="aui-content aui-margin-b-0 aaa" style={{ margin: '0px' }}>
              <ul class="aui-list aui-media-list aui-bg-none">
                <li class="aui-list-item aui-bg-none">
                  <div class="aui-media-list-item-inner">
                    <div class="aui-list-item-inner">
                      <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10">
                        *FM券，兑换FM点，兑换比例为10 FM券=1 FM点。
                        <br />      
*FM券，不兑换FM点，消费比例为1 FM券=1 FM点。
                        <br />                
*FM券，一旦兑换FM点，将不可以再重新兑换成FM券。请谨慎使用兑换功能。
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>

          <NavigatorTabbar selectedTab="person"></NavigatorTabbar>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    }, () => {
      getUserInfo(this.context, timestamp)
        .then((res) => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context)
            }
          } else {
            this.setState({
              userInfo: res.data
            }, () => {
              this.reqMyInfo()
              sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 我的' : '',url: window.location.href, type: 'common'},timestamp,this.context)
            })
          }
        })
    })
  }
}

export default Index

