import React, { Component } from 'react';
import axios from '@/assets/js/axios.js';
import { getUserInfo,quitLogin } from '@/assets/js/user';
import ctx from '@/assets/js/ctx';
import {
  getQueryString,
  signParam,
  toPayOrder,
  toOrderPay,
  string,
  toOneCheckout,
  toHome
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import {
  Flex,
  ListView,
  WingBlank,
  WhiteSpace,
  Grid,
  PullToRefresh,
  Tag,
  Tabs,
  SegmentedControl,
  List,
  InputItem,Modal
} from 'antd-mobile';
import TopNav from '@/components/TopNav';
import { getTheme } from '@/assets/js/setting';
import { CapsuleTabs, Form, Input, TextArea, Button } from 'antd-mobile-v5';
import { Image, Toast } from 'antd-mobile-v5';
import StickyFooter from 'react-sticky-footer';
import Title from '@/components/Title'
import sdk from '@/routes/wxShare'
const Item = List.Item;
const Brief = Item.Brief;
@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      userInfo: {},
      siteInfo: {},
      ruleId: 0,
      payMoney: '-',
      page: 0,
      isAllLoaded: false,
      financeRuleList: [],
      financeMonthlyRuleList: [],
      isFirstLoaded: false,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      pagesize: 10,
      isLoading: true,
      refreshing: true
    };
  }


  render() {
    const {
      cartList,
      typeId,
      isFirstLoaded,
      articleId,
      contractHtml,
    } = this.state;

    const { onChangeTab, onRefresh ,setOrderQuotation,onPay} = this;
    const antIcon = <div className={`am-icon-dot`} />;
    const style = {
      bottom: '0px',
      height: document.documentElement.clientHeight - 45,
    };

    return (
      <>
        <DocumentTitle
          title={
            this.context.siteinfo
              ? this.context.siteinfo.title + ' | 用户等级'
              : ''
          }
        ></DocumentTitle>
        <TopNav name={'用户等级'} history={this.props.history} />
        <div className={`checkout home aui-bg-wx`}>
          <div className={`checkout aui-content aui-bg-wx ${getTheme()}`}>
            {isFirstLoaded ? (
              <div style={{ marginTop: '0px' }}>{antIcon}</div>
            ) : (
              <>
                <WingBlank>
                  <WhiteSpace style={{ marginTop: '45px' }} />
                  <div className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white`}>
                  <Title name={'用户等级'}/>
                  <div class="aui-content aui-margin-b-0 aaa" style={{'margin': '0px'}}>
                    <ul class="aui-list aui-media-list aui-bg-none">
                      <li class="aui-list-item aui-bg-none">
                        <div class="aui-media-list-item-inner">
                          <div class="aui-list-item-inner">
                            <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10">
        *本作品可登记文字版权，不会被下架。详见菜单栏-版权登记。<br/>
        *订单付款后24小时，授权文件发送至指定邮箱。节假日顺延。<br/>
        *有声书版权购买后不可退款，可联系售后客服，补差价人工更换其他高维书籍。<br/>
        *FM点为蜜阅FM平台唯一结算单位。1元=1 FM点。
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
        
                <WhiteSpace/>
                

    </WingBlank>
                </>
            )} 
        
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const { articleid,typeid } = this.props.match.params;
    var timestamp = new Date().getTime();
    this.setState(
      {
        stamp: timestamp,
        articleId: articleid,
        typeId: typeid
      },
      () => {
        getUserInfo(this.context, timestamp).then((res) => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context)
            }
          } else {
            this.setState({
              userInfo: res.data,
            });
          }
        });
      }
    );
  }
}

export default index;
