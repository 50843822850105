//引入路由组件
import Home from './views/Home'
import Make from './views/Make'
import NotFound from './views/NotFound'
import Detail from './views/Detail'
import Catalog from './views/Catalog'
import Read from './views/Read'
import Classify from './views/Classify'
import Login from './views/Login'
import UserLogin from './views/UserLogin'
import BookShelf from './views/BookShelf'
import Search from './views/Search'
import ReadLog from './views/ReadLog'
import RankList from './views/RankList'
import My from './views/My'
import ReviewList from './views/ReviewList'
import Review from './views/Review'
import Complaint from './views/Complaint'
import TopList from './views/TopList'
import Setting from './views/Setting'
import VoiceCheckout from './views/Checkout'
import VoicePay from './views/Pay'
import OrderDetail from './views/OrderDetail'
import VoiceOrderList from './views/OrderList'
import VoiceContract from './views/Contract'
import VoiceBuy from './views/Buy'
import UserRankIntro from './views/UserRankIntro'
import Reg from './views/Reg'
import ActivityXt from './views/Activity/Xt'
import ActivityPay from './views/Activity/Pay'
import ActivityCustomPay from './views/Activity/CustomPay'
import ActivityAliPay from './views/Activity/AliPay'
import ActivityYearPay from './views/Activity/YearPay'
import AliPay from './views/AliPay'
import Download from './views/Download'
import Train from './views/Train'
import Money from './views/Money'
import ExchangePoint from './views/Exchange/Point'
import UserFinanceLog from './views/User/FinanceLog'

// 引入路由懒加载文件
import {lazyLoad} from './assets/js/hoc'
// 使用路由懒加载
// const Classify = lazyLoad(() => import('./views/Classify'))

//配置路由的路径、名称
const routes = [
  {
    path: '/home',
    key: 'home',
    component: Home
  },{
    path: '/make',
    key: 'make',
    component: Make
  },
  {
    path: '/detail/:id',
    key: 'detail',
    component: Detail
  },
  {
    path: '/catalog/:id',
    key: 'catalog',
    component: Catalog
  },
  {
    path: '/read/:bid/:cid',
    key: 'read',
    component: Read
  },
  {
    path: '/classify',
    key: 'classify',
    component: Classify
  },
  {
    path: '/readLog',
    key: 'readLog',
    component: ReadLog
  },
  {
    path: '/login',
    key: 'login',
    component: Login
  },
  {
    path: '/userlogin',
    key: 'userlogin',
    component: UserLogin
  },
  {
    path: '/reg',
    key: 'reg',
    component: Reg
  },
  {
    path: '/bookShelf',
    key: 'bookShelf',
    component: BookShelf
  },
  {
    path: '/search',
    key: 'search',
    component: Search
  },
  {
    path: '/money',
    key: 'money',
    component: Money
  },
  {
    path: '/person',
    key: 'person',
    component: My
  },
  {
    path: '/rankList',
    key: 'rankList',
    component: RankList
  },
  {
    path: '/reviewList/:bookid',
    key: 'reviewList',
    component: ReviewList
  },
  {
    path: '/review/:bookid',
    key: 'review',
    component: Review
  },
  {
    path: '/complaint',
    key: 'complaint',
    component: Complaint
  },
  {
    path: '/topList/:typeid',
    key: 'topList',
    component: TopList
  },
  {
    path: '/setting',
    key: 'setting',
    component: Setting
  },
  {
    path: '/checkout/:articleid',
    key: 'voicecheckout',
    component: VoiceCheckout
  },
  {
    path: '/checkout',
    key: 'voicecheckout',
    component: VoiceCheckout
  },
  {
    path: '/contract/:articleid/:typeid',
    key: 'voicecontract',
    component: VoiceContract
  },
  {
    path: '/pay/:orderid',
    key: 'voicepay',
    component: VoicePay
  },
  {
    path: '/orderDetail/:orderid',
    key: 'orderdetail',
    component: OrderDetail
  },
  {
    path: '/orderList',
    key: 'voiceorderlist',
    component: VoiceOrderList
  },
  {
    path: '/userRankIntro',
    key: 'userrankintro',
    component: UserRankIntro
  },
  {
    path: '/buy',
    key: 'voicebuy',
    component: VoiceBuy
  },
  {
    path: '/Activity/yearpay',
    key: 'ActivityYearPay',
    component: ActivityYearPay
  },
  {
    path: '/Activity/alipay',
    key: 'ActivityAliPay',
    component: ActivityAliPay
  },
  {
    path: '/Activity/custompay',
    key: 'ActivityCustomPay',
    component: ActivityCustomPay
  },
  {
    path: '/Activity/pay',
    key: 'ActivityPay',
    component: ActivityPay
  },
  {
    path: '/Activity/:typeid',
    key: 'ActivityXt',
    component: ActivityXt
  },
  {
    path: '/aliPay',
    key: 'aliPay',
    component: AliPay
  },
  {
    path: '/download',
    key: 'download',
    component: Download
  },
  {
    path: '/train',
    key: 'train',
    component: Train
  },
  {
    path: '/exchange/point',
    key: 'exchange-point',
    component: ExchangePoint
  },
  {
    path: '/user/financeLog',
    key: 'user-financeLog',
    component: UserFinanceLog
  },
  {
    path: '',
    key: 'notfound',
    component: NotFound
  }
]

export default routes