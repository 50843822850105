/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable default-case */
import React, { Component } from 'react';
import axios from '@/assets/js/axios.js';
import { getUserInfo, quitLogin } from '@/assets/js/user';
import HeaderNav from '@/components/HeaderNav';
import WxPay from '@/components/WxPay';
import ctx from '@/assets/js/ctx';
import {
  getQueryString,
  signParam,
  toOrderList,
  axiosParam,
  toBookRead,
  toCatalog,
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import {
  PullToRefresh,
  Flex,
  Button,
  List,
  ListView,
  WhiteSpace,
  WingBlank,
  Result,
  Icon,
  Toast,
  SegmentedControl,
} from 'antd-mobile';
import { Input } from 'antd-mobile-v5';
import TopNav from '@/components/TopNav';
import Title from '@/components/Title';
import { loadingMore } from '@/assets/js/content';
import { string } from '@/assets/js/string';
import { getTheme, getSetting } from '@/assets/js/setting';
import $ from 'jquery';
import { toPage, toAliPay, toHome } from '@/assets/js/string'
import sdk from '@/routes/wxShare'

const Item = List.Item;
const Brief = Item.Brief;

function MyBody(props) {
  return <div className="am-list-body my-body">{props.children}</div>;
}

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      pageTitle: '小额支付',
      userInfo: {},
      siteInfo: {},
      ruleId: 143,
      typeId: 1,
      payMoney: '0',
      payCustomMoney: '',
      page: 0,
      isAllLoaded: false,
      financeRuleList: [],
      financeMonthlyRuleList: [],
      isFirstLoaded: true,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      pagesize: 10,
      isLoading: true,
      refreshing: true,
      down: true,
      hasMore: true,
      useBodyScroll: true,
      paySuccess: false,
      showAliPay: true,
      payType: 0,
      payTypeList: ['微信支付'],
    };
  }

  onChangePay = (payMoney, ruleId) => {
    this.setState(
      {
        payCustomMoney: '',
        payMoney: payMoney,
        ruleId: ruleId,
      },
      () => {
        for (var j = 0; j <= this.state.dataList.length - 1; j++) {
          if (this.state.dataList[j].money == this.state.payMoney) {
            this.setState({
              payMoney: this.state.dataList[j]['money'],
            });
            break;
          }
        }
      }
    );
  };

  onRef = ref => {
    this.child = ref;
  };

  toWxPay = e => {
    const { ruleId, typeId, payMoney } = this.state;
    if (typeId === 2 || typeId === 5) {
      toAliPay(ruleId, payMoney, this.state.userInfo.userid, this.context);
    } else {
      this.child.init(payMoney, ruleId);
    }
  };

  payNotify = e => {
    window.location.reload();
  };

  onChangeCustomPay = (payMoney, ruleId) => {
    const { typeId } = this.state;
    switch (typeId) {
      case 1:
        if (payMoney < 1) {
          Toast.fail('最小1元', 1);
          payMoney = 1;
        }
        if (payMoney > 6000) {
          Toast.fail('最大6000元', 1);
          payMoney = 6000;
        }
        break;
      case 2:
       if (payMoney > 6000) {
          Toast.fail('最大6000元', 1);
          payMoney = 6000;
        }
        break;
    }
    this.setState(
      {
        payMoney: payMoney * 100,
        payCustomMoney: payMoney,
        ruleId: ruleId,
      },
      () => {}
    );
  };

  onTabChange = e => {
    switch (e.nativeEvent.selectedSegmentIndex) {
      case 0:
        this.setState(
          {
            typeId: 1,
            payType: 0,
            payCustomMoney: '',
            ruleId: 0,
          },
          () => {
          }
        );
        break;
      case 1:
        this.setState(
          {
            typeId: 2,
            payType: 1,
            payCustomMoney: '',
            ruleId: 0,
          },
          () => {
          }
        );
        break;
    }
  };


  render() {
    const {
      userInfo,
      refreshing,
      paySuccess,
      showAliPay,
      ruleId,
      isFirstLoaded,
      dataSource,
      pagesize,
      pageTitle,
      setting,
      dataList,
    } = this.state;

    const { onTabChange, onRefresh, onChangePay, toWxPay, LikeBody } = this;
    const antIcon = <div className={`am-icon-dot`} />;

    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | ' + pageTitle : ''}
        ></DocumentTitle>
        <TopNav name={pageTitle} history={this.props.history} />

        <div className={`aui-pay-index aui-bg-wx ${getTheme()}`}
          style={{ marginTop: '45px' }}>
          <WhiteSpace size="sm" />
          <div className={`aui-margin-t-0 aui-bg-white free-list`}>
            <div class="aui-content aui-pay-body">
              <section class="aui-grid">
                <div class="aui-row">
                  <WhiteSpace size="sm" />
                  
                    <List
                      style={{marginTop:'50px'}}
                      className={`btn-large ${this.state.payCustomMoney !== '' ? 'current' : ''
                        }`}
                    >
                      <Input
                        placeholder="*请输入你想要充值的金额。"
                        value={this.state.payCustomMoney}
                        onChange={val => {
                          this.onChangeCustomPay(val, 143);
                        }}
                        style={{
                          '--placeholder-color': '#ff0000',
                          '--color': '#ff0000',
                          fontSize: '14px',
                          height: '44px',
                          padding: '0px 15px',
                        }}
                      />
                    </List>
                  <WhiteSpace size="sm" />
                </div>
              </section>
            </div>
          </div>

          <div className={`aui-row-padded buy-body aui-margin-l-15 aui-margin-r-15`}>
            <div className={` aui-margin-t-10`} style={{ marginTop: '20px!important' }}>
              <Button
                className={`aui-border-radius-10 btn-large`}
                type="warning"
                onClick={() => {
                  this.toWxPay();
                }}
              >
                立即充值：{this.state.payMoney / 100} 元
              </Button>
            </div>
          </div>
             
          <WhiteSpace />
          <div className={`aui-margin-t-0 more-info aui-bg-white`}>
            <Title name={'充值说明'} />
            <div class="aui-content aui-margin-b-0 aaa" style={{ margin: '0px' }}>
              <ul class="aui-list aui-media-list aui-bg-none">
                <li class="aui-list-item aui-bg-none">
                  <div class="aui-media-list-item-inner">
                    <div class="aui-list-item-inner">
                      <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10">
                        *1元=1 FM点。FM券为系统赠送，可用于指定书籍购买。
                        <br />
                        *FM点为虚拟产品，充值后不可退款。
                        <br />
                        *若充值后FM点未到账，请及时与客服联系。菜单栏-客服。
                        <br />
                        *工作时间：周一至周五，9点～6点。
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <WxPay onRef={this.onRef} payNotify={this.payNotify}></WxPay>
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();
    const { orderid } = this.props.match.params;
    this.setState(
      {
        stamp: timestamp,
        orderId: orderid,
      },
      () => {
        getUserInfo(this.context, timestamp).then(res => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context);
            }
          } else {
            this.setState({
              userInfo: res.data,
            });
           
              this.setState({
                pageTitle: '小额支付',
              });
            sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | ' + this.state.pageTitle : '',url: window.location.href, type: 'common'},timestamp,this.context)
          }
        });
      }
    );
  }
}

export default index;
