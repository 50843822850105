import React, { Component } from 'react';
import ctx from '@/assets/js/ctx';
import { checkToken } from '@/assets/js/hoc.js';
import Title from '@/components/Title';
import NavTxt from '@/components/NavTxt';
import { PullToRefresh, Grid, ListView, WingBlank, WhiteSpace, Carousel } from 'antd-mobile';
import { getUrlToken } from '@/assets/js/string';
import { toCart, axiosParam, toPage, toBookRead, string, toBookCover } from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { loadingMore } from '@/assets/js/content';
import BookListItem from '@/components/BookListItem';
import CountDown from '@/components/CountDown';
import { List } from 'antd-mobile/lib/index';
import BannerGroup from '@/components/BannerGroup';
import { getTheme, getSetting } from '@/assets/js/setting';
import moment from 'moment';
import $ from 'jquery'
import sdk from '@/routes/wxShare'

const Item = List.Item;
const Brief = Item.Brief;

function MyBody(props) {
  return (
    <div className={`aui-margin-b-0 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}>
      <ul className={``}>{props.children}</ul>
    </div>
  );
}

function LikeBody(props) {
  return <div className={`book-list`}>{props.children}</div>;
}

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();
    this.state = {
      stamp: '',
      dataSource: new ListView.DataSource({
        // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      isLoading: true,
      userLikeList: [],
      bookSortList: [],
      flashList: [],
      bookRecommendList: [],
      editorRecommendList: [],
      userOrderList: [],
      userHistoryList: [],
      freeList: [],
      newList: [],
      subList: [],
      hotList: [],
      refreshing: true,
      down: true,
      hasMore: true,
      isFirstLoaded: true,
      pagesize: 40,
      page: 0,
      useBodyScroll: true,
      sortSegmentIndex: 0,
      sorting: 6,
      status: 0,
      day1: '',
      totalCount: 10,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
    };
  }

  reqUserHistoryList = action => {
    const { page, pagesize, stamp } = this.state;
    let params = {};
    params = $.extend(params, axiosParam(this.context, stamp));
    this.context.axios
      .post(global.constants.apiUrl + `user/reqUserHistoryList`, params)
      .then(res => {
        if (res.code == 1) {
          this.setState({
            userHistoryList: res.data,
          });
        }
      });
  };

  reqUserOrderList = action => {
    const { page, pagesize, stamp } = this.state;
    let params = {
      page: page + 1,
      pagesize: pagesize,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    this.context.axios.post(global.constants.apiUrl + `user/getUserOrderList`, params).then(res => {
      if (res.code == 1) {
        this.setState({
          userOrderList: res.data,
        });
      }
    });
  };

  reqUserLikeBookList = action => {
    const { page, pagesize, stamp } = this.state;
    let params = {
      page: page + 1,
      pagesize: pagesize,
      price: 2
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    this.context.axios.post(global.constants.apiUrl + `user/getUserLike`, params).then(res => {
      if (res.code == 1) {
        if (res.data.length > 0) {
          this.state.userLikeList = [...this.state.userLikeList, ...res.data];
          this.setState({
            page: page + 1,
            userLikeList: this.state.userLikeList,
            dataSource: this.state.dataSource.cloneWithRows(this.state.userLikeList),
          });

          this.setState({
            isFirstLoad: false,
          });
        } else {
          this.setState({
            hasMore: false,
            isLoading: false,
            isFirstLoad: false,
          });
        }
        this.setState({
          refreshing: false,
        });
      }
    });
  };

  reqBookRecommendList = action => {
    const { stamp, sorting } = this.state;
    let params = {};
    params = $.extend(params, axiosParam(this.context, stamp));

    this.context.axios
      .post(
        global.constants.apiUrl + `recommend/getList`,
        (params = $.extend(params, axiosParam(this.context, stamp)))
      )
      .then(res => {
        if (res.code == 1) {
          switch (action) {
            case 1:
              this.setState({
                subList: res.data.sub,
              });
              break;
            case 2:
              this.setState({
                bookRecommendList: res.data.new,
              });
              break;
            case 3:
              this.setState({
                newList: res.data.discount,
              });
              break;
            case 4:
              this.setState({
                hotList: res.data.hot,
              });
              break;
            default:
              this.setState({
                subList: res.data.sub,
                bookRecommendList: res.data.new,
                newList: res.data.discount,
                hotList: res.data.hot,
                totalCount: res.data.total,
                isLoading: false,
              });
          }
        }
      });
  };

  onRefresh = () => {
    this.setState({
      page: 0,
      refreshing: true,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      userLikeList: [],
    });
    this.reqUserOrderList();
    this.reqBookRecommendList();
    this.reqUserLikeBookList();
  };

  getGridStyle = index => {
    if (index % 4 == 0) {
      return { paddingLeft: '0.75rem' };
    } else if ((index + 3) % 4 == 0) {
      return { paddingLeft: '0.6rem', paddingRight: '0.3rem' };
    } else if ((index + 2) % 4 == 0) {
      return { paddingLeft: '0.3rem', paddingRight: '0.6rem' };
    } else if ((index + 1) % 4 == 0) {
      return { paddingRight: '0.75rem' };
    }
  };

  toLink = (url, e) => {
    switch (url) {
      case 'topList/tz':
        if (e.ver === 'v1') location.href = 'http://i.mixs.cn/upload/fm_mixs_cn/tzys/index.html';
        else location.href = 'http://i.mixs.cn/upload/fm_mixs_cn/tzys/fx.html';
        break;
      case 'ysbqs':
        if (e.ver === 'v1') location.href = 'http://i.mixs.cn/upload/fm_mixs_cn/ysbqs/index.html';
        else location.href = 'http://i.mixs.cn/upload/fm_mixs_cn/ysbqs/dl.html';
        break;
      default:
        toPage(url, e);
        break;
    }
  };
  
  changeHomeListFirstHeight = (e) => {
  };
  render() {
    const {
      hasMore,
      userOrderList,
      dataSource,
      refreshing,
      isLoading,
      pagesize,
      bookSortList,
      flashList,
      bookRecommendList,
      userHistoryList,
      subList,
      hotList,
      editorRecommendList,
      newList,
      setting,
      totalCount,
    } = this.state;
    const {
      onRefresh,
      reqUserLikeBookList,
      reqEditorRecommendBookList,
      onEndReached,
      toLink,
      reqBookRecommendList,changeHomeListFirstHeight
    } = this;
    const antIcon = <div className={`am-icon-dot`} />;
    var day3 = new Date();
    day3.setTime(day3.getTime());
    var s3 = day3.getMonth() + 1 + '月' + day3.getDate() + '日';

    const drbTitle = <div className={`aui-font-size-18 aui-text-color-black`}>新人必选</div>;

    const moduleList = Array.from([
      {
        icon: require('@/assets/icon/xs.png'),
        name: '投资A+',
        link: 'topList/tz',
      },
      {
        icon: require('@/assets/icon/pxzs.png'),
        name: '80万字+',
        link: 'topList/tzsk',
      },
      {
        icon: require('@/assets/icon/jpxs.png'),
        name: '精品小说',
        link: 'topList/good',
      },
      {
        icon: require('@/assets/icon/czfm.png'),
        name: '充值FM点',
        link: 'pay/0',
      }
    ]);

    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | 版权商城' : ''}
        ></DocumentTitle>

        <NavTxt currentTab="make" {...this.props} />

        <div className={`home aui-bg-wx`}>
          <div className={`aui-content aui-bg-wx ${getTheme()}`}>
            {isLoading ? (
              <div style={{ marginTop: '0px' }}>{antIcon}</div>
            ) : (
              <div className={`classify aui-bg-wx ${getTheme()}`}>
                <ListView
                  ref={el => (this.lv = el)}
                  dataSource={dataSource}
                  pageSize={pagesize}
                  useBodyScroll={false}
                  style={{ marginTop: '52px', height: document.documentElement.clientHeight - 52 }}
                  renderHeader={(sectionData, sectionID) => (
                    <>
                      <WhiteSpace style={{}} />
                      {this.context.ver === 'v1' ?
                        <img src={require('@/assets/icon/zq_ysbq_fm.jpeg')} style={{ 'width': '100%' }} onClick={el => toLink('ysbqs', this.context)}/>
                        : <img src={require('@/assets/icon/zq_ysbq.png')} style={{ 'width': '100%' }} onClick={el => toLink('ysbqs', this.context)}/>}
                      <WhiteSpace style={{}} />
                      <div className={`aui-bg-white content  aui-border-radius-10`}>
                        <div className={`module`}>
                          <Grid
                            data={moduleList}
                            columnNum={5}
                            hasLine={false}
                            square={true}
                            onClick={el => toLink(el.link, this.context)}
                            style={{ height: 'auto' }}
                            renderItem={dataItem => (
                              <div className={`aui-text-align-c`}>
                                <img
                                  src={dataItem.icon}
                                  className={`aui-margin-center aui-margin-t-10`}
                                  style={{ width: '55px', height: '55px' }}
                                  alt=""
                                />
                                <div className={`aui-font-size-14 aui-margin-t-10`}>
                                  <span className={`aui-text-color-black aui-text-bold`}>
                                    {dataItem.name}
                                  </span>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>

                    </>
                  )}
                  renderBodyComponent={() => <MyBody />}
                  renderSectionBodyWrapper={sectionID => <LikeBody />}
                  renderRow={(rowData, sectionId, rowID) => {
                    return <BookListItem {...this.props} homeList={rowData} rowID={rowID}  changeHomeListFirstHeight={changeHomeListFirstHeight}/>;
                  }}
                  renderFooter={() => (
                    <div style={{ clear: 'both', padding: 0, textAlign: 'center' }}>
                      {isLoading ? antIcon : hasMore ? '' : '没有更多'}
                    </div>
                  )}
                  pullToRefresh={
                    <PullToRefresh
                      indicator={{
                        activate: antIcon,
                        deactivate: antIcon,
                        release: antIcon,
                        finish: antIcon,
                      }}
                      refreshing={refreshing}
                      distanceToRefresh={55}
                      onRefresh={onRefresh}
                    />
                  }
                  onEndReached={reqUserLikeBookList}
                  onEndReachedThreshold={20}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();

    var day1 = new Date();
    day1.setTime(day1.getTime() + 24 * 60 * 60 * 1000);
    var s1 = day1.getFullYear() + '/' + (day1.getMonth() + 1) + '/' + day1.getDate();

    this.setState(
      {
        stamp: timestamp,
        day1: s1,
      },
      () => {
        this.reqUserHistoryList();
        this.reqUserOrderList();
        this.reqBookRecommendList();
        this.reqUserLikeBookList();
        sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 版权商城' : '',url: window.location.href, type: 'common'},timestamp,this.context)
      }
    );
  }
}

export default index;
