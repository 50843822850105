import React, {Component} from 'react'
import {checkToken} from '@/assets/js/hoc.js'
import {getUserInfo,quitLogin} from '@/assets/js/user'
import {Toast, Modal, Grid, Button, Flex, List, WingBlank, WhiteSpace, Card} from 'antd-mobile'
import axios from '@/assets/js/axios'
import ctx from '@/assets/js/ctx'
import {
  getQueryString,
  signParam,
  toPage,
  toBookCover,
  toBookRead,
  toCatalog,
  axiosParam,
  isNull,
  toOneCheckout,
  toHome
} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import WxPay from '@/components/WxPay'
import BannerGroup from '@/components/BannerGroup'
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss'
import {getTheme, getSetting, getReadTheme} from '@/assets/js/setting'
import TopNav from '@/components/TopNav'
import $ from 'jquery'
import sdk from '@/routes/wxShare'

const Item = List.Item;
const Brief = Item.Brief;

const params = {
  slidesPerView: 3.3,
  centeredSlides: false,
  spaceBetween: 10,
  grabCursor: true,
  roundLengths: true,
  slidesPerColumnFill: 'column'
}

@checkToken
class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      content: '',
      bookId: 0,
      chapterId: 0,
      book: {},
      param: {
        size: {
          current: 20,
          Max: 26,
          Min: 12
        },
        bg: 'weixin',
        night: 'day',
        bar: {
          all: false,
          gear: false
        }
      },
      bookList: [],
      userInfo: {},
      siteInfo: {},
      financeRuleList: [],
      financeMonthlyRuleList: [],
      recommendList: [],
      buyInfo: {
        type: 0,
        ruleId: 0,
        chapterprice: 0,
        buynum: 100,
        paybtntxt: '立即购买',
        paymoney: 0,
        btntxt: '批量购买后100章',
        payegold: 0,
        payesilver: 0,
        monthlypaymoney: 0
      },
      chapteInfo: '',
      chapterName: '',
      chapterIndex: 1,
      pageIndex: 0,
      pageCount: '',
      isBlock: true,
      pageWidth: 0,
      pageHeight: 0,
      isJump: false,
      bookName: '',
      buttonArr: ['目录', '夜间', '设置', '封面'],
      isNav: false,
      isScroll: false,
      isCatalog: false,
      isModal: false,
      cotalogList: [],
      isSort: false,
      isSet: false,
      isLR: true,
      checkColor: [
        {
          name: 'light',
          color: '#f4f4f4'
        },
        {
          name: 'pink',
          color: '#f5dce4'
        },
        {
          name: 'green',
          color: '#d5efd2'
        },
        {
          name: 'yellow',
          color: '#fec'
        },
        {
          name: 'night',
          color: '#383d42'
        },
      ],
      fontStore: [
        {
          fontSize: '13',
          padding: '-8',
          lineHeight: '36',
        },
        {
          fontSize: '16',
          padding: '-14',
          lineHeight: '29',
        },
        {
          fontSize: '20',
          padding: '-10',
          lineHeight: '23',
        },
        {
          fontSize: '23',
          padding: '-8',
          lineHeight: '20',
        }
      ],
      fontIndex: 2,
      skinIndex: 3,
      storeId: '',
      isShowCaseTip: false,
      refreshing: true,
      down: true,
      isFirstLoaded: true,
      slideIndex: 0,
      flag: new Date().getTime(),
      lastBanner: [],
      isShowLastBanner: false,
      followData: {
        isShow: false,
        keyword: '',
        qrcode: ''
      }
    }

  }

  reqRecommendList = () => {
    const {stamp, bookId, chapterId} = this.state

    let params = {}
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `recommend/getReadLastBannerRecommendList`, params)
      .then((res) => {
        this.setState({
          recommendList: res.data
        })
      })
  }

  reqBookContent = () => {
    const {stamp, bookId, chapterId} = this.state

    let params = {
      bookId: bookId,
      chapterId: chapterId,
    }
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `novel/getVoiceChapterContent`, params)
      .then((res) => {
        Toast.hide();
        this.setState({
          book: res.data,
          isFirstLoaded: false,
          refreshing: false
        }, () => {
          window.scrollTo(0, 0)
          this.readTime()
          this.getUserReadChapterCount()
          this.getNovelChapterFollow()

          if (res.data.nextchapterid == 0) {
            this.reqRecommendList()
          }
        })
      })
  }

  Initialization = (bookId, chapterId) => {
    const readBookParam = JSON.parse(localStorage.getItem('readBookParam'))
    if (!isNull(readBookParam)) {
      this.setState({
        param: readBookParam
      }, () => {
        this.setState({
          param: {
            ...this.state.param,
            bar: {
              ...this.state.param.bar,
              all: false
            }
          }
        })
      })
    }
    else {
      this.setState({
        param: {
          size: {
            current: 18,
            Max: 26,
            Min: 12
          },
          bg: 'weixin',
          bar: {
            all: false,
            gear: false
          }
        }
      }, () => {
        this.saveParam();
      })
    }

    this.reqBookContent()
  }

  onRefresh = () => {
    this.setState({refreshing: true});
    this.initialization();
  }

  //保存参数
  saveParam = () => {
    localStorage.setItem('readBookParam', JSON.stringify(this.state.param))
  }

  //改变背景
  changeBg = (e) => {
    var setting = getSetting()
    setting['darkMode'] = 'normal'
    localStorage.setItem('setting', JSON.stringify(setting))

    this.setState({
      flag: new Date().getTime(),
      param: {
        ...this.state.param,
        bg: e
      }
    }, () => {
      this.saveParam();
    })
  }
  //夜间、白天切换
  changeNight = () => {
    var setting = getSetting()
    setting['darkMode'] = getSetting()['darkMode'] == 'normal' ? 'dark' : 'normal'
    setting['themeMode'] = ''
    localStorage.setItem('setting', JSON.stringify(setting))
    this.setState({
      flag: new Date().getTime()
    }, () => {

    })
  }

  //改变字体大小
  changeFontSize = (s) => {
    if (s == 'plus') {
      if (this.state.param.size.current + 2 > this.state.param.size.Max) {
        Toast.info('已经是最大了', 2, null, false);
        return;
      }
      else {
        this.setState({
          param: {
            ...this.state.param,
            size: {
              ...this.state.param.size,
              current: this.state.param.size.current + 2
            }
          }
        }, () => {
          this.saveParam();
        })
      }
    }
    else if (s == 'subtract') {
      if (this.state.param.size.current - 2 < this.state.param.size.Min) {
        Toast.info('已经是最小了', 2, null, false);
        return;
      }
      else {
        this.setState({
          param: {
            ...this.state.param,
            size: {
              ...this.state.param.size,
              current: this.state.param.size.current - 2
            }
          }
        }, () => {
          this.saveParam();
        })
      }
    }

  }
  //打开工具条
  openBar = () => {
    this.setState({
      param: {
        ...this.state.param,
        bar: {
          ...this.state.param.bar,
          all: !this.state.param.bar.all ? true : false
        }
      }
    }, () => {
      this.saveParam();
    })

  }
  closeOpenBar = () => {
    this.setState({
      param: {
        ...this.state.param,
        bar: {
          ...this.state.param.bar,
          all: false
        }
      }
    }, () => {
      this.saveParam();
    })

  }

  openBarSet = () => {
    this.setState({
      param: {
        ...this.state.param,
        bar: {
          ...this.state.param.bar,
          gear: !this.state.param.bar.gear ? true : false
        }
      }
    }, () => {
      this.saveParam();
    })
  }

  //购买对话框
  changeBuyDialog = (key, m) => {
    this.setState({
      book: {
        ...this.state.book,
        isBuy: key
      }
    })
    if (key == -1)
      this.changeBuyFinance(this.state.financeRuleList, m)
    else if (key == -2)
      this.changeBuyFinance(this.state.financeMonthlyRuleList, m)
  }

  //购买规则
  getFinanceList = () => {
    const {stamp} = this.state

    let params = {
      siteId: 1,
      typeId: '2',
      orderId: 0,
      pos: 'read'
    }
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `pay/getVoiceFinanceList`, params)
      .then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].isDefault == 1) {
            this.setState({
              buyInfo: {
                ...this.state.buyInfo,
                ruleId: res.data[i].ruleid,
                payegold: res.data[i].egold,
                payesilver: res.data[i].esilver,
                paymoney: res.data[i].money / 100,
                desc: res.data[i].desc_1
              }
            })
          }
        }

        this.setState({
          financeRuleList: res.data
        })
      })
  }

  //改变购买金额
  changeBuyFinance = (list, payMoney, key, index) => {
    for (var i = 0; i < list.length; i++) {
      if (list[i].ruleid === payMoney) {
        this.setState({
          buyInfo: {
            ...this.state.buyInfo,
            ruleId: list[i].ruleid,
            payegold: list[i].egold,
            payesilver: list[i].esilver,
            paymoney: list[i].money / 100,
            desc: list[i].desc_1
          }
        })
        break;
      }
    }

    this.setState({
      slideIndex: index
    })

  }

  onClose = key => () => {
    this.openBar()
  }
  //计算分页数
  reqPageCount = () => {
    if (this.state.isLR) {
      const {fontStore, fontIndex} = this.state
      const p = document.documentElement.querySelector('#container')
      const height = window.getComputedStyle(p, null).height
      const lineHeight = window.getComputedStyle(p, null).lineHeight
      const lineNum = Math.round(parseFloat(height) / parseFloat(lineHeight));
      const pageCount = Math.ceil(lineNum / fontStore[fontIndex].lineHeight)
      this.setState({
        pageCount,
        isBlock: false
      })
    }

  }

  //工具栏点击事件
  setInterface = (item) => {

    if (item == '目录') {
      this.setState({
        isScroll: true,
        isCatalog: true,
        cotalogList: this.state.bookList
      })
    } else if (item == '夜间') {
      localStorage.setItem('skinIndex', 4)
      localStorage.setItem('isNight', true)

    } else if (item == '日间') {
      localStorage.setItem('skinIndex', 3)
      localStorage.setItem('isNight', false)
    } else if (item == '设置') {
      const {isSet} = this.state
      this.setState({
        isSet: !isSet
      })
    } else if (item == '封面') {
      const {bid} = this.props.match.params
      this.props.history.push(`/detail/${bid}`)
    }
    this.setState({})
  }
  //遮罩层点击事件
  ModalDisplay = () => {
    this.setState({
      isScroll: false,
      isCatalog: false
    })
  }
  //目录列表排序
  listReverse = () => {
    const {bookList, isSort} = this.state
    let cotalogList = []
    bookList.forEach(item => {
      cotalogList.push(item)
    })
    this.setState({
      cotalogList: cotalogList.reverse(),
      isSort: !isSort
    })
  }
  //目录跳转章节
  jumpChpater = (item) => {
    const {bid} = this.props.match.params
    const cid = item.chapterId
    let {isLR, isScroll} = this.state
    if (!isLR) {
      isScroll = true
    } else {
      isScroll = false
    }
    get_book_content(bid, cid)
      .then((res) => {
        this.setState({
          isScroll,
          content: res,
          chapterName: item.chapterName,
          isCatalog: false,
          chapterIndex: item.chapterOrdid,
        }, () => {
          this.setState({
            isNav: false
          })
        })
        this.getBookStore(cid)
      })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  //下一章,上一章
  toRead = (bookId, chapterId, msg) => {
    if (chapterId == 0) {
      Toast.info(msg, 1);
      return
    }

    this.setState({
      param: {
        ...this.state.param,
        bar: {
          ...this.state.param.bar,
          all: false
        }
      },
      bookId: bookId,
      chapterId: chapterId
    }, () => {
      this.reqBookContent()
    })
  }

  toBookShelf = () => {
    this.props.history.push('/bookShelf')
  }

  onRef = (ref) => {
    this.child = ref
  }

  toWxPay = () => {
    this.child.init(this.state.buyInfo.paymoney  * 100,this.state.buyInfo.ruleId)
  }

  payNotify = (e) => {
    toBookRead(this.state.bookId, this.state.chapterId, this.context);
  }

  toBookPraise = () => {
    const {stamp, bookId, chapterId} = this.state
    axios.post(global.constants.apiUrl + `novel/postNovelChapterPraise`, {
      bookId: bookId,
      chapterId: chapterId,
      ver: this.context.ver,
      channel: this.context.channel,
      versionId: this.context.versionId,
      openId: this.context.openid,
      site: this.context.site,
      timestamp: stamp,
      sign: signParam(this.context.ver, this.context.channel, this.context.versionId, this.context.openid, this.context.site, stamp)
    })
      .then((res) => {
        Toast.info(res.msg, 2, () => {
          this.setState({
            book: {
              ...this.state.book,
              data: {
                praiseCnt: res.data.praiseCnt,
                readCnt: res.data.readCnt,
                shareCnt: res.data.shareCnt
              }
            }
          })
        })
      })
  }

  getUserReadChapterCount = () => {
    const {stamp, bookId, chapterId} = this.state

    let params = {
      bookId: bookId,
      chapterId: chapterId,
    }
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `user/getUserReadChapterCount`, params)
      .then((res) => {
        if (res.code > 0)
          Toast.success(res.msg, 1)
      })
  }

  readTime = () => {
    const {stamp} = this.state

    let params = {}
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `novel/saveReadTime`, params)
      .then((res) => {
        if (res.success >= 3) {
          Toast.success(res.msg, 1)
        }
      })
  }

  getNovelChapterFollow = () => {
    const {stamp, bookId, chapterId} = this.state
  
    let params = {
      bookId: bookId,
      chapterId: chapterId,
    }
    params = $.extend(params, axiosParam(this.context,stamp))

    axios.post(global.constants.apiUrl + `novel/getNovelChapterFollow`, params)
      .then((res) => {
        if (res.code < 0) {
          this.setState({
            followData: {
              isShow: true,
              keyword: res.data.keyword,
              qrcode: res.data.qrcode
            }
          })
        }
        else
        {
          this.setState({
            followData: {
              isShow: false,
              keyword: '',
              qrcode: ''
            }
          })
        }
      })
  }

  render() {

    const {userInfo, refreshing, book, param, financeRuleList, buyInfo, financeMonthlyRuleList, chapterId, bookId, recommendList, isFirstLoaded, isShowCaseTip, flag, isShowLastBanner, lastBanner, followData} = this.state

    const {changeBg, changeNight, changeFontSize, openBar, openBarSet, changeBuyDialog, changeBuyFinance, toRead, toWxPay, toBookPraise, onRefresh, closeOpenBar,toBuyBook} = this
    const antIcon = <div className={`am-icon-dot`}/>
    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 章节阅读' : ''}>
        </DocumentTitle>

        {isFirstLoaded ? <div className={`aui-bg-wx`}>{antIcon}</div> :
          <>
            {!isShowCaseTip ?
              <div
                className={`aui-book-read ${ 1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''}  ${1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''}`}
                style={{'height': document.documentElement.clientHeight}}>
                <div
                  className={`aui-content ${1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''}  ${ 1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''}`}>
                  <div className="aui-padded-10 aui-padded-b-0">
                    <div className="aui-content">
                      <h3 className="aui-pull-left aui-padded-t-10"
                          className={`aui-pull-left aui-padded-t-10 ${getReadTheme(flag) == 'night' ? 'aui-text-night' : 'aui-text-day'}`}>{book.chaptername}</h3>
                      <div className="aui-pull-right aui-padded-t-15 aui-font-size-14 aui-text-color-gray cur"
                           onClick={() => {
                             toPage('complaint', this.context)
                           }}><span className="fa fa-exclamation"></span>投诉
                      </div>
                    </div>
                    <div className="aui-content aui-padded-t-10">
                      <div className="top-bg-color aui-pull-left">
                <span onClick={() => {
                  changeBg('weixin')
                }}
                      className={`aui-book-read-bg-weixin cur ${(param.bg == 'weixin' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}></span>
                        <span className="aui-book-read-bg-eyeshield cur" onClick={() => {
                          changeBg('eyeshield')
                        }}
                              className={`aui-book-read-bg-eyeshield cur ${(param.bg == 'eyeshield' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}></span>
                        <span className="aui-book-read-bg-pink cur" onClick={() => {
                          changeBg('pink')
                        }}
                              className={`aui-book-read-bg-pink cur ${(param.bg == 'pink' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}></span>
                        <span className="aui-book-read-bg-paper cur" onClick={() => {
                          changeBg('paper')
                        }}
                              className={`aui-book-read-bg-paper cur ${(param.bg == 'paper' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}></span>
                        <span className="aui-bg-gray-light cur aui-font-size-12" onClick={() => {
                          changeNight()
                        }}
                              className={`aui-bg-gray-light cur aui-font-size-12 ${(getReadTheme(flag) == 'night') ? 'aui-book-read-bg-selected' : ''}`}><i
                          className="fa fa-moon"></i></span>
                      </div>
                      <div className="aui-pull-right">
                        <div className="aui-label aui-label aui-padded-5 aui-margin-l-5"
                             className={`aui-label aui-label aui-padded-5 aui-margin-l-5 ${ (getReadTheme(flag) == 'night') ? 'aui-bg-gray-light' : ''}`}
                             onClick={() => {
                               changeFontSize('plus')
                             }}>&nbsp;A+&nbsp;</div>
                        <div className="aui-label aui-label aui-padded-5 aui-margin-l-5"
                             className={`aui-label aui-label aui-padded-5 aui-margin-l-5 ${ (getReadTheme(flag) == 'night') ? 'aui-bg-gray-light' : ''}`}
                             onClick={() => {
                               changeFontSize('subtract')
                             }}>&nbsp;A-&nbsp;</div>
                      </div>
                    </div>
                    <div
                      className={`aui-content-padded aui-book-body aui-text-color-black aui-margin-b-0 ${ 1 == 1 && param.size ? 'aui-font-size-' + param.size.current : ''} ${ getReadTheme(flag) == 'night' ? 'aui-text-night' : 'aui-text-day'}`}
                      onClick={() => {
                        closeOpenBar()
                      }}>
                      <div dangerouslySetInnerHTML={{__html: book.chaptercontent}}></div>
                    </div>
                  </div>
                </div>

                <div
                  className={`aui-row ${1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''} ${ (getReadTheme(flag) == 'night') ? 'aui-text-color-white' : 'aui-text-color-pink'} ${1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''}`}
                  style={{'padding': '20px 10px 30px 10px'}}>
                  <div className="aui-col-xs-6 aui-bg-none aui-padded-0">
                    <span
                      className={`aui-font-size-16 aui-text-align-c aui-text-gray-light ${1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''} ${1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''}`}>阅读<span>{book.data && book.data.readCnt}</span>&nbsp;
                      <i className="aui-iconfont aui-icon-laud aui-margin-l-10 aui-font-size-14" onClick={() => {
                        toBookPraise()
                      }}></i> <span>{book.data && book.data.praiseCnt}</span></span>
                  </div>

                  <div className="aui-col-xs-6 aui-bg-none aui-padded-0">
                    <div
                      className={`aui-font-size-16 aui-text-align-r aui-margin-t-0`}
                      onClick={() => {
                        toOneCheckout(bookId,this.context)
                      }}><div className={`fa fa-cart`} style={{'font-size': '1rem'}}><span className={`aui-font-size-14`}>点击购买有声书</span></div>
                    </div>
                  </div>
                </div>


                {recommendList && recommendList.map((item, index) =>
                  <div className={`aui-card-list aui-padded-10 ${ 1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''}  ${1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''} `} style={{'position': 'relative','marginBottom':'0px'}} onClick={() => {
                    toBookRead(item.bookid, item.chapterid, this.context)
                  }} key={'item-' + index}>
                    <div className={`recommend-body aui-card-list-content`}>
                      <div className={`aui-padded-t-5 aui-padded-b-5 aui-font-size-18 recommend-title`}>{item.title}</div>
                      <img src={item.thumb} style={{'height':'200px'}}/>
                    </div>
                  </div>
                )}

                {book && book.isBuy > 0 && !followData.isShow ?
                  <div
                    className={`aui-content-padded aui-margin-0 aui-padded-t-10 next-menu ${1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''} ${1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''}`}>
                    <section class="aui-grid aui-bg-none aui-padded-l-10 aui-padded-r-10">
                      <div class="aui-row aui-bg-none">
                        <div class="aui-col-xs-3 aui-bg-none aui-padded-0">
                          <Button type="warning"
                                  className={`btn-large aui-font-size-16 ${ getReadTheme(flag) == 'night' ? 'gray' : ''}`} inline
                                  size="small" onClick={() => {
                            openBar()
                          }} style={{'width': '100%'}}>菜单</Button>
                        </div>
                        <div class="aui-col-xs-1 aui-padded-l-10">

                        </div>
                        <div class="aui-col-xs-8 aui-padded-0">
                          <Button type="warning"
                                  className={`btn-large aui-font-size-16 ${ getReadTheme(flag) == 'night' ? 'gray' : ''}`} inline
                                  size="small" onClick={() => {
                            toRead(bookId, book.nextchapterid, '已经是最后一章了')
                          }} style={{'width': '100%'}}>下一章</Button>
                        </div>
                      </div>
                    </section>
                  </div>
                  : ''}

                {followData.isShow ?
                  <div
                    className={`aui-content-padded aui-margin-0 aui-padded-t-10 aui-text-align-c ${1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''} ${ (getReadTheme(flag) == 'night') ? 'aui-text-color-white' : 'aui-text-color-pink'} ${1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''}`}>
                    <img src={followData.qrcode} style={{'height':'150px','width':'150px','margin':'0px auto'}} />
                    <div class="font-size-12 aui-margin-t-10">长按关注微信公众号，方便下次阅读</div>
                  </div>
                  : ''}

                {book && book.isBuy > 0 ?
                  <div
                    className={`aui-padded-t-15 ${1 == 1 ? 'aui-book-read-body-bg-' + param.bg : ''} ${1 == 1 ? 'aui-book-read-body-bg-' + getReadTheme(flag) : ''}`}>
                    <div className={`aui-content-padded aui-margin-t-0`}
                         style={{'marginBottom': '0px', 'paddingBottom': '0.75rem'}}>
                      <div className="aui-grid aui-bg-none">
                        <div className="aui-row">
                          <div className="aui-col-xs-6 aui-font-size-14 aui-padded-0 aui-text-gray-light">

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  : ''}


                {(param.bar && param.bar.all && book.isBuy > 0) ?
                  <div
                    popup
                    animated={false}
                    mask={true}
                    maskClosable={true}
                    visible={param.bar && param.bar.all && book.isBuy > 0}
                    className={`aui-book-read ${ 'aui-book-read-body-bg-' + (getReadTheme(flag) == 'night' ? 'night' : 'day')}`}
                  >
                    <div>
                      {(param.bar && param.bar.all) ?
                        <header className="aui-bar aui-bar-nav aui-text-color-white">
                          <a className="aui-pull-left aui-btn" onClick={() => {
                            toBookCover(bookId, this.context)
                          }}>
                            <span className="aui-iconfont aui-icon-left"></span>
                          </a>
                          <a className="aui-pull-right aui-btn" onClick={() => {
                            toPage('home', this.context)
                          }}>
                            <span className="aui-iconfont aui-icon-home"></span>
                          </a>
                        </header>
                        : ''}
                      <div className="aui-row-padded"></div>
                      {(param.bar && param.bar.gear) ?
                        <div className="aui-content aui-bar-footer-more"
                             className={`aui-content aui-bar-footer-more ${ getReadTheme(flag) == 'night' ? 'night' : ''}`}>
                          <ul className="aui-list"
                              className={`aui-list ${getReadTheme(flag) == 'night' ? 'night' : ''}`}>
                            <li className="aui-list-item">
                              <div className="aui-list-item-inner aui-list-item-left aui-list-item-btn">
                                <div className="aui-label aui-label-default cur" onClick={() => {
                                  changeFontSize('plus')
                                }}>&nbsp;Aa+&nbsp;</div>
                                <div className="aui-label aui-label-default cur" onClick={() => {
                                  changeFontSize('subtract')
                                }}>&nbsp;Aa-&nbsp;</div>
                              </div>
                            </li>
                            <li className="aui-list-item">
                              <div className="aui-list-item-inner aui-list-item-left">
                                <div
                                  className={`aui-label aui-book-read-bg-eyeshield cur ${(param.bg == 'eyeshield' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}
                                  onClick={() => {
                                    changeBg('eyeshield')
                                  }}>护眼绿
                                </div>
                                <div
                                  className={`aui-label aui-book-read-bg-parchment cur ${(param.bg == 'parchment' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}
                                  onClick={() => {
                                    changeBg('parchment')
                                  }}>羊皮纸
                                </div>
                                <div
                                  className={`aui-label aui-book-read-bg-pink cur ${(param.bg == 'pink' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}
                                  onClick={() => {
                                    changeBg('pink')
                                  }}>贵妃粉
                                </div>
                                <div
                                  className={`aui-label aui-book-read-bg-paper cur ${(param.bg == 'paper' && getReadTheme(flag) != 'night') ? 'aui-book-read-bg-selected' : ''}`}
                                  onClick={() => {
                                    changeBg('paper')
                                  }}>纸之灵
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        : ''}
                      <div
                        className={`aui-bar aui-bar-tab aui-padded-b-25 ${ getReadTheme(flag) == 'night' ? 'night' : ''}`}>
                        <div className="aui-bar-tab-item" onClick={() => {
                          toRead(bookId, book.prechapterid, '已经是第一章了')
                        }}>
                          <i className="aui-iconfont aui-icon-top"></i>
                          <div className="aui-bar-tab-label">上一章</div>
                        </div>
                        <div className="aui-bar-tab-item" onClick={() => {
                          toCatalog(bookId, this.context)
                        }}>
                          <i className="aui-iconfont aui-icon-menu"></i>
                          <div className="aui-bar-tab-label">目录</div>
                        </div>
                        <div className="aui-bar-tab-item" onClick={() => {
                          openBarSet()
                        }}>
                          <i className="aui-iconfont aui-icon-gear"></i>
                          <div className="aui-bar-tab-label">设置</div>
                        </div>
                        <div className="aui-bar-tab-item" onClick={() => {
                          changeNight()
                        }}>
                          <i className="aui-iconfont mixs-iconfont mixs-icon-day"
                             className={`aui-iconfont mixs-iconfont mixs-icon-day ${ getReadTheme(flag) == 'night' ? 'mixs-icon-night' : 'mixs-icon-day'}`}></i>
                          <div className="aui-bar-tab-label">{getReadTheme(flag) == 'day' ? '夜间' : '白天'}</div>
                        </div>
                        <div className="aui-bar-tab-item cur" onClick={() => {
                          toRead(bookId, book.nextchapterid, '已经是最后一章了')
                        }}>
                          <i className="aui-iconfont aui-icon-down"></i>
                          <div className="aui-bar-tab-label">下一章</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''}

                <Modal
                  popup
                  animated={false}
                  maskClosable={true}
                  visible={book.isBuy <= 0}
                  animationType="slide-up"
                  className={`aui-book-read ${getReadTheme(flag)}`}
                >
                  <div>
                    {book.isBuy == 0 ?
                      <>
                        <div className={`book-read-pay-body ${getReadTheme(flag)}`}>
                          <div className={`book-read-pay-body-block`}>
                            <WhiteSpace size="sm"/>
                            <p
                              className={`aui-font-size-14 aui-margin-t-5 aui-text-align-l aui-padded-l-10 aui-padded-r-10`}>为保护付费作品权益，请开通VIP会员免费阅读。会员属于虚拟物品，购买后不可退换。
                            </p>
                            <WhiteSpace size="sm"/>
                          </div>
                          <div className="recharge-body">
                            <WhiteSpace size="sm"/>
                            <Swiper {...params}>
                              {financeRuleList.map((item, index) => {
                                switch (item.typeid) {
                                  case 1:
                                    return <div>
                                      <div
                                        className={`item aui-margin-t-15 ${ buyInfo.ruleId == item.ruleid ? 'current' : '' }`}
                                        onClick={() => {
                                          changeBuyFinance(financeRuleList, item.ruleid, index)
                                        }}>
                                        <Card>
                                          <Card.Body>
                                            <p
                                              className={`aui-font-size-12`}>{item.egold}{this.context.siteinfo && this.context.siteinfo.currencyname}</p>
                                            <p
                                              className={`aui-font-size-12`}>{(1 == 1) ? ('+送' + item.esilver + '' + (this.context.siteinfo && this.context.siteinfo.esilvername) + '') : ''}</p>
                                            <p
                                              className={`aui-font-size-18 aui-padded-t-5 aui-padded-b-15 aui-text-color-pink`}>￥{item.name}</p>
                                          </Card.Body>
                                          <Card.Footer content={
                                            <div>充值{this.context.siteinfo && this.context.siteinfo.currencyname}</div>}/>
                                        </Card>
                                      </div>
                                    </div>
                                    break;
                                  case 2:
                                    return <div>
                                      <div
                                        className={`item aui-margin-t-15 ${ buyInfo.ruleId == item.ruleid ? 'current' : '' }`}
                                        onClick={() => {
                                          changeBuyFinance(financeRuleList, item.ruleid, index)
                                        }}>
                                        <Card>
                                          <Card.Body>
                                            <p
                                              className={`aui-font-size-12`} style={{'height': '36px'}}
                                              dangerouslySetInnerHTML={{__html: item.desc}}></p>
                                            <p
                                              className={`aui-font-size-18 aui-padded-t-5 aui-padded-b-5 aui-text-color-pink`}>￥{item.name}</p>

                                            <p
                                              className={`aui-font-size-12`}>{item.desc_1 ? (item.desc_1.indexOf('仅') != -1 ? item.desc_1 :
                                              <del
                                                style={{'textDecoration': 'line-through #e1e1e1'}}>￥{item.desc_1}</del>) : ''}</p>
                                          </Card.Body>
                                          <Card.Footer
                                            content={item.desc_3 ? (item.desc_3.indexOf('送') != -1 ? item.desc_3 + (this.context.siteinfo && this.context.siteinfo.esilvername) + '' : item.desc_3) : ''}/>
                                        </Card>
                                      </div>
                                    </div>
                                    break;
                                }
                              })}
                            </Swiper>
                            <WhiteSpace size="lg"/>
                          </div>
                          <div className="buy-body">
                            <WingBlank size="lg">
                            <Flex className={`aui-padded-t-5`}>
                                <Flex.Item>
                                <Button type="warning" className={`btn-large`}
                                      inline size="large" onClick={() => {
                                this.toWxPay()
                              }} style={{'width': '95%'}}><span>立即开通</span></Button>
                                </Flex.Item>
                                <Flex.Item>
                                <Button type="primary" className={`btn-large`}
                                      inline size="large" onClick={() => {
                                        toOneCheckout(bookId,this.context)
                              }} style={{'width': '95%'}}><span>买有声书</span></Button>
                                </Flex.Item>
                              </Flex>
                            
                              
                            </WingBlank>
                          </div>
                          <div>
                            <WingBlank size="lg">
                              
                            </WingBlank>
                          </div>
                        </div>
                      </>
                      : ''}

                    {book.isBuy == -1 ?
                      <div className="aui-bar aui-bar-tab" style={{'background': '#fff', 'paddingBottom': '0.75rem'}}>
                        <div className="aui-content-padded aui-margin-t-0">
                          <div className="aui-row-padded cur" onClick={() => {
                            changeBuyDialog(0)
                          }}>
                            <p
                              className="aui-text-align-c aui-padded-5 aui-font-size-14 aui-label aui-label-outlined aui-text-color-pink"
                              style={{'border': '0px solid #ff6600'}}>充值{this.context.siteinfo && this.context.siteinfo.currencyname}</p>
                            <p className="aui-font-size-14 aui-text-align-l aui-margin-t-10">充值：<span
                              className="aui-text-color-pink">{buyInfo.payegold}</span> {this.context.siteinfo && this.context.siteinfo.currencyname} + <span
                              className="aui-text-color-pink">{buyInfo.payesilver}</span> {this.context.siteinfo && this.context.siteinfo.esilvername}，共 <span
                              className="aui-text-color-pink">{buyInfo.payegold + buyInfo.payesilver}</span> 点
                            </p>
                            <p className="aui-font-size-14 aui-text-align-l aui-margin-t-10">账户余额：<span
                              className="aui-text-color-pink">{userInfo.egold}</span> {this.context.siteinfo && this.context.siteinfo.currencyname} + <span
                              className="aui-text-color-pink">{userInfo.esilver}</span> {this.context.siteinfo && this.context.siteinfo.esilvername}，共 <span
                              className="aui-text-color-pink">{userInfo.egold + userInfo.esilver}</span> 点
                            </p>
                          </div>
                          <div className="aui-row-padded recharge-body">
                            <Grid data={financeRuleList}
                                  columnNum={2}
                                  hasLine={false}
                                  square={false}
                                  renderItem={(el, index) => (
                                    <Button type="warning"
                                            className={`btn-large ${ buyInfo.ruleId == el.ruleid ? '' : 'aui-text-color-pink' }`}
                                            size="large" onClick={() => {
                                      changeBuyFinance(financeRuleList, el.money,el.ruleid)
                                    }}
                                            style={index % 2 == 0 ? {'marginRight': '5px'} : {'marginLeft': '5px'}}>{el.name}<span>{el.desc != '' ? '(' + el.desc + ')' : ''}</span></Button>
                                  )}
                            />
                          </div>
                          <div className="aui-col-xs-12 aui-pull-left">
                            <Button type="warning"
                                    className={`aui-margin-t-15 btn-large`}
                                    inline size="large" onClick={() => {
                              toWxPay()
                            }} style={{'width': '100%'}}>立即充值并购买</Button>
                          </div>
                        </div>
                      </div>
                      : ''}

                    {book.isBuy == -2 ?
                      <div className="aui-bar aui-bar-tab"
                           style={{'background': '#fff', 'padding-bottom': '0.75rem'}}>
                        <div className="aui-content-padded">
                          <div className="aui-row-padded" onClick={() => {
                            changeBuyDialog(0)
                          }}>
                            <p
                              className="aui-text-align-c aui-padded-5 aui-font-size-14 aui-label aui-label-outlined aui-text-color-pink "
                              style={{'border': '0px solid #ff6600'}}>开通会员</p>
                            <p className=" aui-margin-t-10 aui-font-size-14 aui-text-align-l">余额：<span
                              className="aui-text-color-pink">{userInfo.egold}</span> {this.context.siteinfo && this.context.siteinfo.currencyname} + <span
                              className="aui-text-color-pink">{userInfo.esilver}</span> {this.context.siteinfo && this.context.siteinfo.esilvername}
                            </p>
                            <p className="aui-font-size-14 aui-text-align-l aui-margin-t-10">价格：<span
                              className="aui-text-color-pink">{buyInfo.desc}</span>
                            </p>
                          </div>
                          <div className="aui-row-padded recharge-body">
                            <Grid data={financeMonthlyRuleList}
                                  columnNum={2}
                                  hasLine={false}
                                  square={false}
                                  renderItem={(el, index) => (
                                    <Button type="warning"
                                            className={`btn-large ${ buyInfo.ruleId == el.ruleid ? '' : 'aui-text-color-pink' }`}
                                            size="large" onClick={() => {
                                      changeBuyFinance(financeMonthlyRuleList, el.money, el.ruleid)
                                    }}
                                            style={index % 2 == 0 ? {'marginRight': '5px'} : {'marginLeft': '5px'}}>{el.name}</Button>
                                  )}
                            />

                            <div className="aui-col-xs-12 aui-pull-left">
                              <Button type="warning"
                                      className={`aui-margin-t-10 btn-large`}
                                      inline size="large" onClick={() => {
                                toWxPay()
                              }} style={{'width': '100%'}}>立即开通</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      : ''}
                  </div>
                </Modal>
              </div>
              : ''}
          </>
        }

        {isShowCaseTip ?
          <img src={require('@/assets/images/case-float-tip.png?ver=1')}
               style={{'width': '100%'}} onClick={() => {
            this.setState({
              isShowCaseTip: !isShowCaseTip
            })
          }}/>
          : ''}
        <WxPay onRef={this.onRef} payNotify={this.payNotify}></WxPay>
      </>
    )
  }

  componentDidMount() {
    //Toast.loading('章节努力加载中...', 0, '', true)
    const {bid, cid} = this.props.match.params
    var timestamp = new Date().getTime()
    this.setState({
      bookId: bid,
      chapterId: cid,
      stamp: timestamp
    }, () => {
      getUserInfo(this.context, timestamp)
        .then((res) => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context)
            }
          } else {
            this.setState({
              userInfo: res.data
            }, () => {
              this.Initialization(bid, cid)
              this.getFinanceList()
              sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 章节阅读' : '',url: window.location.href, type: 'common'},timestamp,this.context)
            })
          }
        })
    })
  }
}

export default index
