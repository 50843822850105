

import wx from 'weixin-js-sdk'; // 微信sdk依赖
import axios from 'axios';
import $ from 'jquery';
import {
  axiosParam
} from '@/assets/js/string';
// 引用全局
/* eslint-disable */
const jsApiList = [
  'checkJsApi',
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  'hideOptionMenu',
  'showOptionMenu',
  'shareTimeline' //不存在
];
 
// 要用到微信API
function getJSSDK (params, stamp, context) {
    params = $.extend(params, axiosParam(context, stamp));

    axios.post(global.constants.apiUrl + 'user/getShareInfo', params).then(res => {
      wx.config({
        debug: false,
        appId: res.data.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr, 
        signature: res.data.signature,
        jsApiList
      });
      wx.ready(() => {
        wx.onMenuShareAppMessage({
          title: res.data.forWeixin.title,
          desc: res.data.forWeixin.desc,
          link: res.data.forWeixin.link,
          imgUrl: res.data.forWeixin.imgUrl,
          trigger: function trigger(res) {},
          success: function success(res) {
            console.log('已分享');
          },
          cancel: function cancel(res) {
            console.log('已取消');
          },
          fail: function fail(res) {
            console.log(JSON.stringify(res), 123456);
          },
        });
      // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
      wx.onMenuShareTimeline({
        title: res.data.forWeixin.title,
        link: res.data.forWeixin.linkurl,
        imgUrl: res.data.forWeixin.imgUrl,
        trigger: function trigger(res) {
          // alert('用户点击分享到朋友圈');
        },
        success: function success(res) {
          // alert('已分享');
        },
        cancel: function cancel(res) {
          // alert('已取消');
        },
        fail: function fail(res) {
          alert(JSON.stringify(res));
        },
      });
      // 2.3 监听“分享到QQ”按钮点击、自定义分享内容及分享结果接口
      wx.onMenuShareQQ({
        title: res.data.forWeixin.title,
        desc: res.data.forWeixin.desc,
        link: res.data.forWeixin.linkurl,
        imgUrl: res.data.forWeixin.imgUrl,
        trigger: function trigger(res) {
          // alert('用户点击分享到QQ');
        },
        complete: function complete(res) {
          alert(JSON.stringify(res));
        },
        success: function success(res) {
          // alert('已分享');
        },
        cancel: function cancel(res) {
          // alert('已取消');
        },
        fail: function fail(res) {
          // alert(JSON.stringify(res));
        },
      });
      // 2.4 监听“分享到微博”按钮点击、自定义分享内容及分享结果接口
      wx.onMenuShareWeibo({
        title: res.data.forWeixin.title,
        desc: res.data.forWeixin.desc,
        link: res.data.forWeixin.linkurl,
        imgUrl: res.data.forWeixin.imgUrl,
        trigger: function trigger(res) {
          // alert('用户点击分享到微博');
        },
        complete: function complete(res) {
          // alert(JSON.stringify(res));
        },
        success: function success(res) {
          // alert('已分享');
        },
        cancel: function cancel(res) {
          // alert('已取消');
        },
        fail: function fail(res) {
          // alert(JSON.stringify(res));
          console.log(JSON.stringify(res));
        },
      });
    });
    wx.error((res) => {
      console.log(`${JSON.stringify(res)}微信验证失败`, 456);
      // alert(JSON.stringify(res)+"微信验证失败");
    });
  });
}
export default {
  // 获取JSSDK
  getJSSDK,
}