import React, { Component } from 'react';
import axios from '@/assets/js/axios.js';
import { getUserInfo, quitLogin } from '@/assets/js/user';
import ctx from '@/assets/js/ctx';
import {
  axiosParam,
  signParam,
  toHome,
  toBookCover,
  string,
  toViewContract,
  toOrderList
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import {
  Flex,
  ListView,
  WingBlank,
  WhiteSpace,
  Grid,
  PullToRefresh,
  Tag,
  Tabs,
  SegmentedControl,
  List,
  InputItem,
  Modal,
} from 'antd-mobile';
import TopNav from '@/components/TopNav';
import { getTheme } from '@/assets/js/setting';
import { CapsuleTabs, Form, Input, TextArea, Button } from 'antd-mobile-v5';
import { Image, Toast } from 'antd-mobile-v5';
import StickyFooter from 'react-sticky-footer';
import Title from '@/components/Title';
import $ from 'jquery';
import BannerGroup from '@/components/BannerGroup';
import sdk from '@/routes/wxShare';
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      userInfo: {},
      siteInfo: {},
      ruleId: 0,
      payMoney: '-',
      page: 0,
      isAllLoaded: false,
      financeRuleList: [],
      financeMonthlyRuleList: [],
      isFirstLoaded: true,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      pagesize: 10,
      isLoading: true,
      refreshing: true,
      down: true,
      hasMore: true,
      useBodyScroll: true,
      cartList: [],
      typeId: 0,
      contractVisible: false,
      contractHtml: '',
      person_truename: '',
      person_broadcastname: '',
      person_card: '',
      person_mobile: '',
      person_email: '',
      company_companyname: '',
      company_address: '',
      company_no: '',
      company_mobile: '',
      company_email: '',
      isModifyPerson: true,
      isModifyCompany: true,
      warrant_type: '',
      is_esilver_buy: '',
      xmly_url: '',
      marketPrice: 0,
      require_xmly_url: 0
    };
  }

  setOrderQuotation = (values: any) => {
    let value = values;
    const { stamp, truename, broadcastname, companyname, card, email, address, articleId, typeId } =
      this.state;

    let params = {
      siteId: 3,
      articleId: articleId,
      typeId: typeId,
      person_truename: value['person_truename'],
      person_broadcastname: value['person_broadcastname'],
      person_card: value['person_card'],
      person_mobile: value['person_mobile'],
      person_email: value['person_email'],
      company_companyname: value['company_companyname'],
      company_address: value['company_address'],
      company_no: value['company_no'],
      company_mobile: value['company_mobile'],
      company_email: value['company_email'],
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `order/setOrderQuotation`, params).then(res => {
      if (res.code === '1') {
        this.setState(
          {
            contractHtml: res.data,
            contractVisible: true,
          },
          () => {}
        );
      } else {
      }
    });
  };

  getCartList = () => {
    const { stamp, articleId } = this.state;

    let params = {};

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + 'novel/cartList', params).then(res => {
      this.setState(
        {
          isFirstLoaded: false,
          cartList: res.data.list.data,
          totalPrice: res.data.totalPrice
        },
        () => {}
      );
    });
  };

  getNovelInfo = () => {
    const { stamp, articleId } = this.state;

    let params = {
      articleId: articleId,
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + 'order/getNovelBuyInfo', params).then(res => {
      this.setState(
        {
          isFirstLoaded: false,
          cartList: res.data.list,
          totalPrice: res.data.totalPrice,
          warrant_type: res.data.warrant_type,
          is_esilver_buy: res.data.is_esilver_buy,
          marketPrice: res.data.marketPrice,
          require_xmly_url: res.data.require_xmly_url
        },
        () => {}
      );
    });
  };

  onChangeTab = e => {
    this.setState(
      {
        typeId: e.nativeEvent.selectedSegmentIndex,
      },
      () => {}
    );
  };

  onRefresh = () => {
    this.setState({
      page: 0,
      refreshing: true,
    });
  };

  onCloseContract = () => {
    this.setState({
      contractVisible: false,
    });
  };

  getOrderUserAddress = () => {
    const { stamp } = this.state;

    let params = {};

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `order/getOrderUserAddress`, params).then(res => {
      if (res.code === '1') {
        if (res.data.person) {
          this.setState(
            {
              person_truename: res.data.person.person_truename,
              person_broadcastname: res.data.person.person_broadcastname,
              person_card: res.data.person.person_card,
              person_mobile: res.data.person.person_mobile,
              person_email: res.data.person.person_email,
              isModifyPerson: false,
            },
            () => {}
          );
        } else {
          this.setState(
            {
              isModifyPerson: true,
            },
            () => {}
          );
        }
        if (res.data.company) {
          this.setState(
            {
              company_companyname: res.data.company.company_companyname,
              company_address: res.data.company.company_address,
              company_no: res.data.company.company_no,
              company_mobile: res.data.company.company_mobile,
              company_email: res.data.company.company_email,
              isModifyCompany: false,
            },
            () => {}
          );
        } else {
          this.setState(
            {
              isModifyCompany: true,
            },
            () => {}
          );
        }
      } else {
      }
    });
  };

  onSaveCheckout = (values: any) => {
    let value = values;
    const { stamp, truename, broadcastname, companyname, card, email, address, articleId, typeId } =
      this.state;
    let params = {
      siteId: 3,
      articleId: articleId,
      typeId: typeId,
      person_truename: value['person_truename'],
      person_broadcastname: value['person_broadcastname'],
      person_card: value['person_card'],
      person_mobile: value['person_mobile'],
      person_email: value['person_email'],
      company_companyname: value['company_companyname'],
      company_address: value['company_address'],
      company_no: value['company_no'],
      company_mobile: value['company_mobile'],
      company_email: value['company_email'],
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `order/setOrderUserAddress`, params).then(res => {
      if (res.code === '1') {
        switch (typeId) {
          case 0:
            this.setState(
              {
                person_truename: res.data.person_truename,
                person_broadcastname: res.data.person_broadcastname,
                person_card: res.data.person_card,
                person_mobile: res.data.person_mobile,
                person_email: res.data.person_email,
                isModifyPerson: false,
              },
              () => {}
            );
            break;
          case 1:
            this.setState(
              {
                company_companyname: res.data.company_companyname,
                company_address: res.data.company_address,
                company_no: res.data.company_no,
                company_mobile: res.data.company_mobile,
                company_email: res.data.company_email,
                isModifyCompany: false,
              },
              () => {}
            );
            break;
          default:
            break;
        }
      } else {
      }
    });
  };

  toEdit = e => {
    if (e === 0) {
      this.setState(
        {
          isModifyPerson: !this.state.isModifyPerson,
          typeId: 0,
        },
        () => {}
      );
    } else {
      this.setState(
        {
          isModifyCompany: !this.state.isModifyCompany,
          typeId: 1,
        },
        () => {}
      );
    }
  };

  btnViewContract = (articleId, type, e) => {
    const { stamp, isModifyCompany, isModifyPerson } = this.state;
    // eslint-disable-next-line default-case
    switch (type) {
      case 0:
        if (!isModifyPerson) toViewContract(articleId, type, e);
        break;
      case 1:
        if (!isModifyCompany) toViewContract(articleId, type, e);
        break;
    }
  };

  onChangeXmlyUrl = (e) => {
    this.setState(
      {
        xmly_url: e
      },
      () => {}
    );
  };

  onReceive = (values: any) => {
  let value = values;
    const { stamp, articleId, typeId,xmly_url } =
      this.state;
    let params = {
      articleId: articleId,
      typeId: typeId,
      xmly_url: xmly_url
    };

    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + `order/setOneOrder`, params).then(res => {
      if (res.code === '3'){
        toOrderList(this.context);
      } else {
        Toast.show({
              content: res.msg,
              afterClose: () => {
                // toPage('home',this.context)
              },
            })
      }
    });
  }

  render() {
    const {
      cartList,
      typeId,
      isFirstLoaded,
      totalPrice,
      articleId,
      userInfo,
      contractVisible,
      contractHtml,
      person_truename,
      person_broadcastname,
      person_card,
      person_mobile,
      person_email,
      company_companyname,
      company_address,
      company_no,
      company_mobile,
      company_email,
      isModifyCompany,
      isModifyPerson,
      is_esilver_buy,
      warrant_type,
      xmly_url,
      marketPrice,
      require_xmly_url
    } = this.state;

    const {
      onCloseContract,
      onChangeTab,
      onRefresh,
      setOrderQuotation,
      onSaveCheckout,
      toEdit,
      btnViewContract,
      onReceive
    } = this;
    const antIcon = <div className={`am-icon-dot`} />;
    const style = {
      bottom: '0px',
      height: document.documentElement.clientHeight - 45,
    };

    const checkMobile = (_: any, value) => {
      var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(value)) {
        return Promise.reject(new Error('手机号格式不正确!'));
        // return Promise.resolve()
      } else {
        return Promise.resolve();
      }
    };

    const checkEmail = (_: any, value) => {
      var myreg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
      if (!myreg.test(value)) {
        return Promise.reject(new Error('邮箱格式不正确!'));
        // return Promise.resolve()
      } else {
        return Promise.resolve();
      }
    };

    const checkCard = (_: any, value) => {
      var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!regIdNo.test(value)) {
        return Promise.reject(new Error('身份证不正确!'));
        // return Promise.resolve()
      } else {
        return Promise.resolve();
      }
    };
    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | 订单提交' : ''}
        ></DocumentTitle>
        <TopNav name={'订单提交'} history={this.props.history} />
        <div className={`checkout home aui-bg-wx`} style={{'marginTop':'45px'}}>
          <div className={`checkout aui-content aui-bg-wx ${getTheme()}`}>
            {isFirstLoaded ? (
              <div style={{ marginTop: '0px' }}>{antIcon}</div>
            ) : (
              <>
                <div className={`aui-pay-index aui-bg-wx ${getTheme()}`}>
                  <Flex className={`userinfo aui-bg-white aui-border-radius-10`}>
                    <Flex.Item>
                      <List className={`avatar`}>
                        <Item
                          arrow="horizontal"
                          thumb={userInfo.avatar}
                          multipleLine
                          arrow=""
                          onClick={() => {}}
                        >
                          {userInfo.nickname}
                          <Brief>ID:{userInfo.userid}</Brief>
                        </Item>
                      </List>
                    </Flex.Item>
                    <Flex.Item>
                      <List className="my-list">
                        <Item arrow="horizontal" multipleLine arrow="">
                          <Brief>
                            <Flex className={`aui-font-size-14`}>
                              <Flex.Item>FM点：{userInfo.egold} 点</Flex.Item>
                              <Flex.Item></Flex.Item>
                              <Flex.Item></Flex.Item>
                            </Flex>
                            <Flex className={`aui-font-size-14`}>
                              <Flex.Item>FM券：{userInfo.esilver} 券</Flex.Item>
                              <Flex.Item></Flex.Item>
                              <Flex.Item></Flex.Item>
                            </Flex>
                          </Brief>
                        </Item>
                      </List>
                    </Flex.Item>
                  </Flex>
                </div>
                  <WhiteSpace />
                  {require_xmly_url === 1 ?
                    <>
                      <div className={`aui-bg-white aui-padded-15`}>
                        <Input
                          className={`aui-bar-light aui-padded-t-10 aui-padded-b-10`}
                          placeholder={
                            '喜马拉雅上部作品链接'
                          }
                          onChange={val => {
                            this.onChangeXmlyUrl(val);
                          }}
                          value={xmly_url}
                        />
                        <div className={`aui-font-size-12`}>提醒：请打开喜马拉雅APP，作品页面右上角...打开，复制链接地址</div>
                      </div>
                      <WhiteSpace />
                    </>
                    : ''}
                <div className={`aui-bg-white aui-border-radius-10`}>
                  <Title name={'订单详情'} />
                  <div className={``}>
                    <ul className={`aui-list aui-media-list hot-list`}>
                      <Grid
                        data={cartList}
                        activeClassName={`active-no`}
                        columnNum={1}
                        hasLine={false}
                        square={false}
                        itemStyle={{ height: 'auto' }}
                        renderItem={(el, index) => (
                          <li
                            className={`aui-list-item aui-bg-none`}
                            onClick={() => {
                              toBookCover(el.articleid, this.context);
                            }}
                          >
                            <div className={`aui-media-list-item-inner`}>
                              <div
                                className={`aui-list-item-media aui-margin-0`}
                                style={{ width: 'auto' }}
                              >
                                <img src={el.thumb_url} className={`square`} onError={e => {
                              e.target.src = 'http://i.mixs.cn/upload/cover/default.jpeg';
                            }}/>
                              </div>
                              <div className={`aui-list-item-inner aui-padded-r-5`}>
                                <div className={`aui-list-item-text aui-text-align-l`}>
                                  <span
                                    className={`aui-font-size-16 aui-text-color-black aui-text-bold`}
                                  >
                                    {string.truncate(el.articlename, 12, '...')}
                                  </span>
                                </div>
                                <Brief>
                                  <div className={`aui-margin-t-0 aui-text-align-l`}>
                                    <span className={`aui-font-size-12`}>{el.author} · </span>

                                    <span className={`aui-font-size-12 aui-margin-l-5`}>
                                      {string.truncate(el.sortname, 2, '')} ·{' '}
                                    </span>
                                    <span className={`aui-font-size-12 aui-margin-l-5`}>
                                      {el.fullflag} ·{' '}
                                    </span>
                                    <span className={`aui-font-size-12 aui-margin-l-5`}>
                                      {el.size}
                                    </span>
                                  </div>
                                </Brief>
                                <Brief>
                                  <div className={`aui-margin-t-0 aui-text-align-l`}>
                                    <span className={`aui-font-size-12`}>
                                      {warrant_type === 0 ? '非独家' : '独家'} ·
                                    </span>
                                    <span className={`aui-font-size-12 aui-margin-l-5`}>5年</span>
                                  </div>
                                </Brief>
                                <Brief>
                                  <div
                                    className={`aui-list-item-text aui-margin-t-5 aui-text-align-l all-sale`}
                                  >
                                    <span className={`aui-font-size-12 aui-text-color-black`}>
                                      全网可上架
                                    </span>{' '}
                                  </div>
                                </Brief>
                                <Brief>
                                  <div
                                    className={`aui-list-item-text aui-margin-t-5 aui-text-align-l all-sale`}
                                  >
                                    <span className={` aui-text-color-pink`}>
                                      *{is_esilver_buy}
                                    </span>
                                    {totalPrice > 0 ?
                                      <>
                                        <span className={`aui-font-size-16 aui-text-color-pink`} style={{ 'float': 'right' }}>
                                          <span style={{ color: '#ff0000' }}>¥{totalPrice}</span>{' '}
                                          <span className={` aui-font-size-12`}>FM点</span>
                                        </span>
                                      </>
                                      : 
                                      <>
                                        <span className={`aui-font-size-16 aui-text-color-pink`} style={{ 'float': 'right' }}>
                                          <span className={`text-decoration`}  style={{ color: '#ff0000' }}>¥{marketPrice}</span>{' '}
                                          <span className={`aui-font-size-12`}>FM点</span>
                                          <span className={`aui-font-size-12 aui-margin-l-10`}>年卡免费</span>
                                        </span>
                                      </>
                                    }
                                  </div>
                                </Brief>

                                <div
                                  className={`aui-list-item-text aui-text-color-gray aui-font-size-12 aui-margin-t-10`}
                                ></div>
                              </div>
                            </div>
                          </li>
                        )}
                      />
                    </ul>
                  </div>
                </div>
                {warrant_type === 1 ? (
                  <>
                      <WhiteSpace />
                      <div className={`aui-margin-t-0 more-info aui-bg-white`}>
                        <BannerGroup positionId="2" />
                      </div>
                  </>
                ) : (
                  ''
                )}
                <WhiteSpace />
                <div className={`aui-margin-t-0 more-info aui-bg-white aui-border-radius-10`}>
                  <Title name={'销售条款'} />
                  <div class="aui-content aui-margin-b-0 aaa" style={{ margin: '0px' }}>
                    <ul class="aui-list aui-media-list aui-bg-none">
                      <li class="aui-list-item aui-bg-none">
                        <div class="aui-media-list-item-inner">
                          <div class="aui-list-item-inner">
                            <div class="aui-list-item-text aui-font-size-14 aui-margin-t-10">
                              <p>
                                *独家作品直接登记文字版权。非独家作品遵循先买先登记，后买后登记规则。作品已被登记需要等前者完结后再次顺序登记。详见菜单栏-版权登记。
                              </p>
                              <p className={`aui-text-color-pink`}>
                                *订单付款后我们将在工作日24H（9点至18点）发送授权文件至指定邮箱。节假日休息。
                              </p>
                              <p>
                                *有声书版权购买后不可退款，可联系售后客服，补差价人工更换其他高维书籍。
                              </p>
                              <p>*FM点为商城唯一结算单位。1元=1 FM点。</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <WhiteSpace />
                <SegmentedControl
                    pop
                    values={['个人', '机构']}
                    tintColor={'#ff0000'}
                    selectedIndex={typeId}
                    onChange={onChangeTab}
                    style={{ height: '45px', margin: '0px 10px 0px 10px' ,border:'1px solid #ff0000',borderRadius:'5px'}}
                  />
                  <WhiteSpace />
                <div>
                  {typeId === 0 ? (
                    <Form
                      className={`user-form aui-border-radius-10 aui-bg-white`}
                      onFinish={onSaveCheckout}
                      footer={``}
                      initialValues={{
                        person_truename: person_truename,
                        person_broadcastname: person_broadcastname,
                        person_card: person_card,
                        person_mobile: person_mobile,
                        person_email: person_email,
                      }}
                    >
                      <Form.Item
                        name="person_truename"
                        label="被授权方（真名）"
                          rules={[{ required: true, message: '姓名不能为空' }]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={
                            person_truename !== '' ? person_truename : '请输入被授权方（真名）'
                          }
                          value={person_truename}
                          disabled={!isModifyPerson}
                        />
                      </Form.Item>
                      <Form.Item
                        name="person_broadcastname"
                        label="播音名"
                        rules={[{ required: true, message: '播音名不能为空' }]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={
                            person_broadcastname !== '' ? person_broadcastname : '请输入播音名'
                          }
                          value={person_broadcastname}
                          disabled={!isModifyPerson}
                        />
                      </Form.Item>
                      <Form.Item
                        name="person_card"
                        label="身份证号码"
                        rules={[
                          {
                            required: true,
                            message: '身份证号码不能为空',
                          },
                          { validator: checkCard },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={person_card !== '' ? person_card : '请输入身份证号码'}
                          value={person_card}
                          disabled={!isModifyPerson}
                        />
                      </Form.Item>
                      <Form.Item
                        name="person_mobile"
                        label="联系手机号"
                        rules={[
                          { required: true, message: '联系手机号不能为空' },
                          { validator: checkMobile },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={person_mobile !== '' ? person_mobile : '请输入联系手机号'}
                          value={person_mobile}
                          disabled={!isModifyPerson}
                        />
                      </Form.Item>
                      <Form.Item
                        name="person_email"
                        label="指定收件邮箱"
                        rules={[
                          { required: true, message: '播音E—Mail不能为空' },
                          { validator: checkEmail },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={person_email !== '' ? person_email : '请输入E—Mail'}
                          value={person_email}
                          disabled={!isModifyPerson}
                        />
                      </Form.Item>
                      <div className={`aui-padded-10`}>
                        <Flex className={`aui-padded-t-0 aui-padded-b-5 `}>
                          <Flex.Item>
                            <span style={{ color: '#ff0000', fontSize: '14px' }}>
                              *请务必确定邮箱等信息填写正确，否则将无法收到授权文件。<br/>
                              *请先保存信息，然后再点击预览授权。
                            </span>
                          </Flex.Item>
                          </Flex>
                        </div>
                        <div className={`aui-padded-10`}>
                        <Flex className={`aui-padded-t-0 aui-padded-b-15`}>
                          <Flex.Item>
                            {isModifyPerson ? (
                              <Button type="primary" className={`btn-large btn-checkout`}>
                                保存
                              </Button>
                            ) : (
                              <div
                                type="primary"
                                className={`btn-large btn-contract red`}
                                onClick={() => {
                                  toEdit(0);
                                }}
                              >
                                修改
                              </div>
                            )}
                            </Flex.Item>
                            {totalPrice > 0 ? <>
                              <Flex.Item>
                                <div
                                  type="warning"
                                  color="danger"
                                  className={`btn-large btn-contract blue`}
                                  onClick={() => {
                                    btnViewContract(articleId, 0, this.context);
                                  }}
                                >
                                  预览授权书
                                </div>
                              </Flex.Item>
                            </>
                              :
                              <>
                                <Flex.Item>
                                  <div
                                    type="warning"
                                    color="danger"
                                    className={`btn-large btn-contract blue`}
                                    onClick={() => {
                                      onReceive(articleId, this.context);
                                    }}
                                  >
                                    立即领取
                                  </div>
                                </Flex.Item>
                              </>
                            }
                        </Flex>
                      </div>
                    </Form>
                  ) : (
                    <Form
                    className={`user-form aui-border-radius-10 aui-bg-white`}
                      onFinish={onSaveCheckout}
                      footer={``}
                      initialValues={{
                        company_companyname: company_companyname,
                        company_no: company_no,
                        company_address: company_address,
                        company_mobile: company_mobile,
                        company_email: company_email,
                      }}
                    >
                      <Form.Item
                        name="company_companyname"
                        label="被授权方（机构）："
                        rules={[
                          {
                            required: true,
                            message: '被授权方（机构）不能为空',
                          },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={
                            company_companyname !== ''
                              ? company_companyname
                              : '请输入被授权方（机构）'
                          }
                          value={company_companyname}
                          disabled={!isModifyCompany}
                        />
                      </Form.Item>
                      <Form.Item
                        name="company_no"
                        label="统一社会信用代码："
                        rules={[
                          {
                            required: true,
                            message: '被授权方（机构）不能为空',
                          },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={
                            company_companyname !== ''
                              ? company_companyname
                              : '请输入被授权方（机构））'
                          }
                          value={company_companyname}
                          disabled={!isModifyCompany}
                        />
                      </Form.Item>
                      <Form.Item
                        name="company_address"
                        label="注册地址："
                        rules={[
                          {
                            required: true,
                            message: '机构办公地址：不能为空',
                          },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={
                            company_address !== '' ? company_address : '请输入机构办公地址：）'
                          }
                          value={company_address}
                          disabled={!isModifyCompany}
                        />
                      </Form.Item>
                      <Form.Item
                        name="company_mobile"
                        label="联系手机号"
                        rules={[
                          { required: true, message: '联系手机号不能为空' },
                          { validator: checkMobile },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={
                            company_mobile !== '' ? company_mobile : '请输入联系手机号）'
                          }
                          value={company_mobile}
                          disabled={!isModifyCompany}
                        />
                      </Form.Item>
                      <Form.Item
                        name="company_email"
                        label="E—Mail"
                        rules={[
                          { required: true, message: '播音E—Mail不能为空' },
                          { validator: checkEmail },
                        ]}
                          className={`aui-bg-tran`}
                      >
                        <Input
                          placeholder={company_email !== '' ? company_email : '请输入E—Mail）'}
                          value={company_email}
                          disabled={!isModifyCompany}
                        />
                      </Form.Item>
                      
                      <div className={`aui-padded-10`}>
                        <Flex className={`aui-padded-t-0 aui-padded-b-5 `}>
                          <Flex.Item>
                            <span style={{ color: '#ff0000', fontSize: '14px' }}>
                              *请务必确定邮箱等信息填写正确，否则将无法收到授权文件。<br/>
*请先保存信息，然后再点击预览授权。
                            </span>
                          </Flex.Item>
                            </Flex>
                            </div>
                               <div className={`aui-padded-10`}>
                        <Flex className={`aui-padded-t-0 aui-padded-b-15 `}>
                          <Flex.Item>
                            {isModifyCompany ? (
                              <Button type="primary" className={`btn-large btn-checkout`}>
                                保存
                              </Button>
                            ) : (
                              <div
                                type="primary"
                                className={`btn-large btn-contract red`}
                                onClick={() => {
                                  toEdit(1);
                                }}
                              >
                                修改
                              </div>
                            )}
                          </Flex.Item>
                          <Flex.Item>
                            <div
                              type="warning"
                              color="danger"
                              className={`btn-large btn-contract blue`}
                              onClick={() => {
                                btnViewContract(articleId, 1, this.context);
                              }}
                            >
                              预览授权书
                            </div>
                          </Flex.Item>
                                </Flex>
                      </div>
                    </Form>
                  )}
                </div>
                <Modal
                  transparent
                  maskClosable={false}
                  visible={contractVisible}
                  closable={false}
                  style={{ width: '100%' }}
                >
                  <div
                    style={{
                      width: '100%',
                      color: '#333',
                      height: document.documentElement.clientHeight,
                      overflowY: 'auto',
                      overflowX: 'visible',
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: contractHtml }}></div>

                    <Flex className={`aui-padded-t-5 aui-padded-b-15 `}>
                      <Flex.Item>
                        <Button
                          type="primary"
                          className={`btn-large btn-contract red`}
                          onClick={() => {
                            onCloseContract();
                          }}
                        >
                          返回修改
                        </Button>
                      </Flex.Item>
                      <Flex.Item>
                        <Button
                          type="warning"
                          color="danger"
                          className={`btn-large btn-contract blue`}
                          onClick={() => {
                            toBookRead(book.articleid, book.chapterid, this.context);
                          }}
                        >
                          立即购买
                        </Button>
                      </Flex.Item>
                    </Flex>
                  </div>
                </Modal>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const { articleid } = this.props.match.params;
    var timestamp = new Date().getTime();
    this.setState(
      {
        stamp: timestamp,
        articleId: articleid,
      },
      () => {
        getUserInfo(this.context, timestamp).then(res => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context);
            }
          } else {
            this.setState({
              userInfo: res.data,
            });

            if (parseInt(articleid) > 0) {
              this.getNovelInfo();
              this.getOrderUserAddress();
            } else {
              this.getCartList();
            }
          }
        });
        sdk.getJSSDK(
          {
            title: this.context.siteinfo ? this.context.siteinfo.title + ' | 订单提交' : '',
            url: window.location.href,
            type: 'common',
          },
          timestamp,
          this.context
        );
      }
    );
  }
}

export default index;
