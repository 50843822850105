import React, {Component} from 'react'
import {Tabs, WhiteSpace, Button, Toast} from 'antd-mobile';
import ctx from '@/assets/js/ctx'
import {getQueryString,toHome} from '@/assets/js/string'

export class index extends Component {
  static contextType = ctx

  constructor(contextType) {
    super()
    this.state = {
      redirectUrl : ''
    }
  }

  checkUrlParam = (ver, channel, versionId, site, return_uri) => {
    this.context.axios.get(global.constants.apiUrl + 'login/checkUrlParam?ver=' + ver + '&channel=' + channel + '&versionId=' + versionId + '&site=' + site + '').then((response) => {
      if (response.code == 1) {
        var AppID = response.data.AppId

        const path = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + AppID + '&redirect_uri=' + encodeURIComponent(return_uri) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
        window.location.href = path
      }
      else {
        console.log('失败')
      }
    })
      .catch((error) => {
        console.log('失败2')
      });
  }

  postLogin = (code, ver, channel, site, versionId, redirectUrl) => {
    this.context.axios.get(global.constants.apiUrl + 'login/postLogin?code=' + code + '&ver=' + ver + '&channel=' + channel + '&versionId=' + versionId + '&site=' + site + '').then((response) => {
      if (response.code == 1) {
        localStorage.setItem("ver", response.data.ver)
        localStorage.setItem("userId", response.data.userid)
        localStorage.setItem("timestamp", response.data.timestamp)
        localStorage.setItem("sign", response.data.sign)
        localStorage.setItem("site", response.data.site)
        localStorage.setItem("openid", response.data.openid)
        window.location.href = redirectUrl
        // toHome(this.context)
      }
      else {
        Toast.loading(res.msg, 2, () => {
          // window.location.href = '/notFound'
          return
        });
      }
    })
      .catch((error) => {
        Toast.loading('系统错误，请联系管理员', 2, () => {
          // window.location.href = '/notFound'
          // return
        });
      });
  }

  checkToken = (ver, openid, channel, versionId, site, timestamp, sign) => {
    this.context.axios.get(global.constants.apiUrl + 'login/checkToken?ver=' + ver + '&openid=' + openid + '&channel=' + channel + '&versionId=' + versionId + '&site=' + site + '&timestamp=' + timestamp + '&sign=' + sign).then((response) => {
      if (response.code == 1) {
        console.log('验证通过')
      }
      else {
        localStorage.clear();
      }
    })
      .catch((error) => {
        localStorage.clear();
        console.log('验证失败')
      });
  }

  render() {
    const {} = this.state

    const {} = this

    return (
      <>
      </>
    )
  }

  componentDidMount() {
    var code = getQueryString('code')
    var ver = getQueryString('ver')
    var channel = getQueryString('channel')
    var versionId = getQueryString('versionId')
    var redirectUrl = getQueryString('redirectUrl')
    var site = getQueryString('site')
    console.log('redirectUrl',redirectUrl)
    this.setState({
      redirectUrl: redirectUrl
    }, () => {
      if (!code) {
        var return_uri = window.location.protocol + '//' + window.location.host + '/Login?ver=' + ver + '&channel=' + channel + '&versionId=' + versionId + '&site=' + site + '&redirectUrl=' + encodeURIComponent(redirectUrl);
        //console.log(return_uri)
        this.checkUrlParam(ver, channel, versionId, site, return_uri);
      }
      else {
        this.postLogin(code, ver, channel, site, versionId, redirectUrl)
      }
    })


  }
}

export default index
