import React, {Component} from 'react'
import BookListItem from '@/components/BookListItem'
import {ListView, PullToRefresh} from 'antd-mobile'
import styles from "./index.module.scss"

export class index extends Component {


  render() {
    const {dataSource, onEndReached, hasMore, onRefresh, refreshing, isLoading} = this.props

    const antIcon = <div className={`am-icon-dot`}/>

    return (
      <>

        <ListView
          dataSource={dataSource}
          useBodyScroll={true}
          className={styles.rankWrap}
          renderRow={
            (rowData, sectionId, rowID) => {
              return <BookListItem {...this.props} rankList={rowData}/>
            }
          }
          renderFooter={() => (<div style={{padding: 0, textAlign: 'center'}}>
            {isLoading ? antIcon : (hasMore ? '' : '没有更多')}
          </div>)}
          pullToRefresh={<PullToRefresh
            indicator={{activate: antIcon, deactivate: antIcon, release: antIcon, finish: antIcon}}
            refreshing={refreshing}
            distanceToRefresh={55}
            onRefresh={onRefresh}
          />}
          onEndReached={onEndReached}
          onEndReachedThreshold={20}
        />
      </>
    )
  }
}

export default index
