import React, { Component } from 'react';
import axios from '@/assets/js/axios.js';
import { getUserInfo } from '@/assets/js/user';
import HeaderNav from '@/components/HeaderNav';
import WxPay from '@/components/WxPay';
import ctx from '@/assets/js/ctx';
import {
  getQueryString,
  signParam,
  toOrderList,
  axiosParam,
  toBookRead,
  toCatalog,
  toHome,
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import {
  PullToRefresh,
  Flex,
  Button,
  List,
  ListView,
  WhiteSpace,
  WingBlank,
  Icon,
  Toast,
} from 'antd-mobile';
import { Popup, Divider, AutoCenter } from 'antd-mobile-v5';
import TopNav from '@/components/TopNav';
import Title from '@/components/Title';
import { loadingMore } from '@/assets/js/content';
import { toPage } from '@/assets/js/string';
import { getTheme, getSetting } from '@/assets/js/setting';
import $ from 'jquery';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import sdk from '@/routes/wxShare'
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      showAliPay: true,
      isWechat: false,
      timer: '',
      percent: 0,
    };
  }

  toDownLoad = () => {
    Toast.loading('', 0, () => {
      console.log('Load complete !!!');
    });
    const { stamp, userId, orderId } = this.state;

    let params = {
      orderId: orderId,
      ver: this.context.ver,
      channel: this.context.channel,
      versionId: this.context.versionId,
      userId: userId,
      site: this.context.site,
      timestamp: stamp,
      sign: signParam(
        this.context.ver,
        this.context.channel,
        this.context.versionId,
        this.context.userId,
        this.context.site,
        stamp
      ),
    };

    this.context.axios
      .post(global.constants.apiUrl + 'order/getOrderNovelContentDownload', params)
      .then(res => {
        Toast.hide();
        if (res.code <= 0) {
          Toast.fail(res.msg, 1);
        } else {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', res.msg);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  iTimer = () => {
    this.timer = setInterval(() => {
      let { percent } = this.state;
      percent += 1;
      this.setState({ percent: percent });
      if (percent >= 100) {
        clearInterval(this.timer);
      }
    }, 300);
  };

  render() {
    const {
      isWechat,
      percent,
      paySuccess,
      showAliPay,
      orderId,
      isFirstLoaded,
      dataSource,
      pagesize,
      payMoney,
      setting,
    } = this.state;

    const { getUserInfo, onRefresh, onChangePay, toWxPay, LikeBody } = this;
    const antIcon = <div className={`am-icon-dot`} />;
    const at = (
      <div style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '14px' }}>正在下载...</span>
        <br />
        请稍等
      </div>
    );
    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | 小说下载' : ''}
        ></DocumentTitle>
        {isWechat ? (
          <div className={`ali-pay`} style={{ height: document.documentElement.clientHeight }}>
            <Flex className={`aui-bg-gray`} style={{ padding: '10px 0px 0px 15%' }}>
              <Flex.Item>
                <div
                  className={`aui-font-size-14 aui-text-color-white aui-text-align-l aui-pull-left`}
                  style={{ width: '180px' }}
                >
                  请点击右上角...
                  <br />
                  请选择【Safari浏览器】打开
                </div>
                <img
                  src={require('@/assets/images/soft_arrow.png?ver=1')}
                  className={`aui-pull-right`}
                  style={{ width: '70px', marginRight: '20px' }}
                />
              </Flex.Item>
            </Flex>
            <Flex className={`aui-bg-gray`} style={{ padding: '10px 15%' }}>
              <Flex.Item>
                <img src={require('@/assets/images/browser_icon_android.png?ver=1')} />
              </Flex.Item>
            </Flex>
            <div>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <AutoCenter>
                <CountdownCircleTimer
                  isPlaying
                  duration={80}
                  size={120}
                  strokeWidth={6}
                  trailStrokeWidth={3}
                  trailColor="#ffae5d"
                  colors={['#ffae5d']}
                >
                  {({ remainingTime }) => at}
                </CountdownCircleTimer>
              </AutoCenter>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WingBlank></WingBlank>
              <AutoCenter>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button
                  className={`aui-border-radius-10 btn-large aui-margin-t-15`}
                  type="warning"
                  onClick={() => {
                    toPage('orderList', this.context);
                  }}
                  style={{ width: '180px' }}
                >
                  完成下载返回订单
                </Button>
              </AutoCenter>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();
    const { ruleid } = this.props.match.params;

    this.setState(
      {
        stamp: timestamp,
      },
      () => {
        if (
          navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 ||
          typeof navigator.wxuserAgent !== 'undefined'
        ) {
          this.setState({
            isWechat: true,
          });
          // setTimeout(this.iTimer,0);
        } else {
          var orderId = getQueryString('orderId');
          var userId = getQueryString('userId');
          this.setState(
            {
              isWechat: false,
              orderId: orderId,
              userId: userId,
            },
            () => {
              var sign = signParam(
                this.context.ver,
                this.context.channel,
                this.context.versionId,
                this.context.userId,
                this.context.site,
                timestamp
              );
              localStorage.setItem('ver', this.context.ver);
              localStorage.setItem('userId', userId);
              localStorage.setItem('timestamp', timestamp);
              localStorage.setItem('sign', sign);
              localStorage.setItem('site', this.context.site);
              localStorage.setItem('logintype', 2);

              this.toDownLoad();
            }
          );
        }
      }
    );
  }
}

export default index;
