import React, { Component } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import ctx from '@/assets/js/ctx';
import { getUserInfo, quitLogin } from '@/assets/js/user';
import axios from '@/assets/js/axios';
import Menu from '@/components/Menu';
import {
  axiosParam,
  signParam,
  toPage,
  toHome,
  toBookRead,
  toUserRankIntro,
  toVoiceBuy,
  toOrderPay,
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import { PullToRefresh, WingBlank, WhiteSpace, List, Card, Button, Grid } from 'antd-mobile';
import TopNav from '@/components/TopNav';
import { getTheme, getSetting } from '@/assets/js/setting';
import $ from 'jquery';
import sdk from '@/routes/wxShare';
import NavigatorTabbar from '@/components/NavigatorTabbar';
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class Index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {};
  }

  toLink = (type,url) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 1:
        toPage(url,this.context)
        break;
      case 2:
        location.href=url
    }
  };

  render() {
    const { myInfo, userInfo, refreshing, height, setting } = this.state;
    const { toLink, set } = this;

    const fmLink = [
      { 'name': '投资版权书', 'type': 2, 'url': 'http://i.mixs.cn/upload/fm_mixs_cn/tzys/index.html' },
      { 'name': '精品小说', 'type': 1, 'url': 'topList/good' },
      { 'name': '投资版权书库', 'type': 1, 'url': 'topList/tzsk' },
      { 'name': '分销FM商城', 'type': 2, 'url': 'http://i.mixs.cn/upload/fm_mixs_cn/zsjm/index.html' },
      { 'name': 'FM画本/后期', 'type': 2, 'url': 'http://i.mixs.cn/upload/fm_mixs_cn/activity/yphb/fm.html' }
    ]

    const fxLink = [
      { 'name': '投资版权书', 'type': 2, 'url': 'http://i.mixs.cn/upload/fm_mixs_cn/tzys/fx.html' },
      { 'name': '精品小说', 'type': 1, 'url': 'topList/good' },
      { 'name': '投资版权书库', 'type': 1, 'url': 'topList/tzsk' }
    ]

    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | 赚米' : '赚米'}
        ></DocumentTitle>

        <div className={`money aui-bg-white aui-border-radius-10 ${getTheme()}`}>
          
          <>
            {this.context.ver === 'v1' ? 
                    fmLink.map((item, index) => {
                      return <>
                        <WhiteSpace style={{}} />
                          <List>
                          <Item
                            extra={``}
                            arrow="horizontal"
                            onClick={() => {
                              toLink(item.type,item.url);
                            }}
                          >
                            {item.name}
                          </Item>
                        </List>
                        </>
                    })
              : 
              fxLink.map((item, index) => {
                      return <>
                        <WhiteSpace style={{}} />
                          <List>
                          <Item
                            extra={``}
                            arrow="horizontal"
                            onClick={() => {
                              toLink(item.type,item.url);
                            }}
                          >
                            {item.name}
                          </Item>
                        </List>
                        </>
                    })
                }
              
             </>
          <NavigatorTabbar selectedTab="money"></NavigatorTabbar>
        </div>
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();
    this.setState(
      {
        stamp: timestamp,
      },
      () => {}
    );
  }
}

export default Index;
