import React, {Component} from 'react'
import {TabBar} from 'antd-mobile'
import '@/config/run.js'
import ctx from '@/assets/js/ctx'
import {withRouter} from 'react-router-dom'
import {getQueryString, signParam, toPage, toBookCover, toBookRead, toCatalog} from '@/assets/js/string'

export class index extends Component {
  static contextType = ctx
  toUrl = (url) => {
    this.props.history.push(`${url}`)
  }

  render() {
    const {toUrl} = this
    const {currentTab} = this.props

    return (
      <div className={`menu aui-pos-fix`} style={{'zIndex':'9999','width':'100%','top':'0px','left':'0px'}}>
        <div className={`aui-tab`} className={`aui-tab ${1 == 1 ? 'header-bg-' + global.constants.system : ''}`}>
          <div className={`aui-tab-item aui-text-color-black aui-text-bold cur ${ currentTab== 'bookshelf' ? 'aui-active' : '' }`} onClick={() => {
            toPage('bookShelf',this.context)
          }}>书架
          </div>
          <div className={`aui-tab-item aui-text-color-black aui-text-bold cur ${ currentTab== 'readlog' ? 'aui-active' : '' }`} onClick={() => {
            toPage('readLog',this.context)
          }}>阅读记录
          </div>
          {this.props.editBooks ?
          <div className={`aui-tab-item aui-text-color-black aui-text-bold cur`} onClick={this.props.editBooks}>管理</div>
            : '' }
          <div className={`aui-tab-item aui-text-color-black aui-text-bold cur`}></div>
          <div className={`aui-tab-item aui-text-color-black aui-text-bold cur`}></div>
          <div className={`aui-tab-item aui-text-color-black aui-text-bold cur`}></div>
        </div>
      </div>
    )
  }
}

export default index
