import React, { Component } from 'react'
import {List} from 'antd-mobile'
import styles from './index.module.scss'

const Item = List.Item;
const Brief = Item.Brief;

export class title extends Component {
    render() {
        const {name,more,arrow} = this.props
        return (
          <List className={`title-List`}>
            <Item extra={more} arrow={arrow} className={`aui-font-size-14`}><span className={`aui-text-bold`}>{name}</span></Item>
          </List>
        )
    }
}

export default title