import React, {Component} from 'react'
import NavTxt from '@/components/NavTxt'
import RankItem from '@/components/RankItem'
import {ListView} from 'antd-mobile';
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam} from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'
import {getTheme, getSetting} from '@/assets/js/setting'
import sdk from '@/routes/wxShare'

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      page: 0,
      size: 0,
      sorts: 0,
      status: 0,
      price: [],
      sorting: 1,
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      refreshing: false,
      isLoading: true,
      hasMore: true
    }
  }

  reqRankList = (action) => {
    const {page, size, sorts, status, price, sorting, stamp} = this.state
    let params = {
      page: page + 1,
      size: size,
      sorts: sorts,
      status: status,
      price: price,
      sorting: sorting,
      ver: this.context.ver,
      channel: this.context.channel,
      versionId: this.context.versionId,
      openId: this.context.openid,
      site: this.context.site,
      timestamp: stamp,
      sign: signParam(this.context.ver, this.context.channel, this.context.versionId, this.context.openid, this.context.site, stamp)
    };
    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'sort/getBookList', params, this)
    }, 10);
  }

  onRefresh = () => {
    this.setState({refreshing: true, isLoading: true, hasMore: true, page: 0, dataList: []});
    this.reqRankList();
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqRankList();
  }

  render() {
    const {dataSource, hasMore, isLoading, refreshing} = this.state
    const {onEndReached, onRefresh} = this
    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + ' | 排行榜' : ''}>
        </DocumentTitle>
        {/* nav */}
        <NavTxt currentTab='rankList' {...this.props}/>
        {/* nav */}
        <>
          <div className={`aui-content sort-content aui-bg-wx`}>
            <div className={`aui-row`}>
              <div className={`aui-col-xs-12`}>
                <ul className={`aui-list aui-media-list aui-bg-none`} style={{'marginTop': '43px'}}>
                  <RankItem onEndReached={onEndReached} hasMore={hasMore}
                            dataSource={dataSource} onRefresh={onRefresh} isLoading={isLoading}
                            refreshing={refreshing} {...this.props}/>
                </ul>
              </div>
            </div>
          </div>
        </>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    this.setState({
      stamp: timestamp
    }, () => {
      this.reqRankList();
    })
  }
}

export default index
