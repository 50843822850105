import React, {Component} from 'react'
import axios from '@/assets/js/axios'
import Title from '@/components/Title'
import {ListView} from 'antd-mobile';
import ctx from '@/assets/js/ctx'
import {getQueryString, signParam} from '@/assets/js/string'
import BookReviewItem from '@/components/BookReviewItem'
import {Toast} from "antd-mobile/lib/index";
import DocumentTitle from 'react-document-title'
import {checkToken} from '@/assets/js/hoc.js'
import {loadingMore} from '@/assets/js/content'
import {toPage} from '@/assets/js/string'
import {getTheme, getSetting} from '@/assets/js/setting'
import sdk from '@/routes/wxShare'

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()
    this.state = {
      page: 0,
      bookId: 0,
      replayId: 0,
      textareaValue: '',
      dataSource: new ListView.DataSource({ // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      hasMore: true,
      isAllLoaded: false,
      isLoading: false,
      dataList: [],
      pagesize: 20,
      totalCount: 0,
      headerNavOpacity: 0,
    }
  }

  reqReviewList = (action) => {
    const {page, stamp, bookId} = this.state
    let params = {
      bookId: bookId,
      page: page + 1,
      limit: this.state.pagesize,
      ver: this.context.ver,
      channel: this.context.channel,
      versionId: this.context.versionId,
      openId: this.context.openid,
      site: this.context.site,
      timestamp: stamp,
      sign: signParam(this.context.ver, this.context.channel, this.context.versionId, this.context.openid, this.context.site, stamp)
    };
    setTimeout(() => {
      loadingMore(global.constants.apiUrl + 'novel/getCommentList', params, this)
    }, 10);
  }

  onRefresh = () => {
    this.setState({refreshing: true, isLoading: true, hasMore: true, page: 0, dataList: []});
    this.reqReviewList();
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false;
    }
    if (!this.state.hasMore) {
      return false;
    }
    this.setState({
      isLoading: true
    });

    this.reqReviewList();
  }

  handleTextareaChange = (e) => {
    this.setState({
      textareaValue: e.target.value
    })
  }

  submitComment = () => {
    const {stamp, bookId, textareaValue, replayId} = this.state
    axios.post(global.constants.apiUrl + `novel/setComment`, {
      bookId: bookId,
      rid: replayId,
      content: textareaValue,
      ver: this.context.ver,
      channel: this.context.channel,
      versionId: this.context.versionId,
      openId: this.context.openid,
      site: this.context.site,
      timestamp: stamp,
      sign: signParam(this.context.ver, this.context.channel, this.context.versionId, this.context.openid, this.context.site, stamp)
    })
      .then((res) => {
        Toast.success(res.msg, 1)

      })
  }

  submitReplayComment = (replayId) => {
    this.setState({
      replayId: replayId
    })
  }

  handleScroll = (e) => {
    var scrollTop = e.target.documentElement.scrollTop;
    if (scrollTop <= 10) {
      this.setState({
        headerNavOpacity: 0
      });
    }
    else {
      this.setState({
        headerNavOpacity: 1
      });
    }
  }

  render() {
    const {resultList, textareaValue, hasMore, dataSource, isLoading, refreshing, totalCount, headerNavOpacity} = this.state
    const {submitComment, onEndReached, onRefresh, pagesize, submitReplayComment, handleScroll} = this

    return (
      <>
        <DocumentTitle title={this.context.siteinfo ? this.context.siteinfo.title + '|书籍评论' : ''}>
        </DocumentTitle>
        {/* nav */}
        <header className={`aui-bar aui-bar-nav aui-bar-light aui-bg-gray-tiny aui-pos-fix`}
                style={{'opacity': headerNavOpacity}}>
          <a class="aui-pull-left aui-btn" onClick={() => {
            goBack()
          }}>
            <span class="aui-iconfont aui-icon-left"></span>
          </a>
          <div class="aui-title">发布评论</div>
          <a class="aui-pull-right aui-btn" onClick={() => {
            toPage('home', this.context)
          }}>
            <span class="aui-iconfont aui-icon-home"></span>
          </a>
        </header>
        {/* nav */}
        <div class="aui-book-review">
          <div class="aui-content aui-bg-white">
            <div class="aui-content aui-bg-white" style={{'width': '100%'}}>
              <ul class="aui-list aui-form-list aui-bg-none">
                <li class="aui-list-item" style={{'background': 'none'}}>
                  <div class="aui-list-item-inner">
                    <div class="aui-list-item-input" style={{'position': 'relative'}}>
                      <textarea placeholder="在此输入评论" id="reviewContent"
                                class="aui-border-color-gray aui-font-size-16 aui-text-gray-light aui-padded-5"
                                value={textareaValue} onChange={this.handleTextareaChange}
                                style={{'height': '9rem'}}></textarea><span class="fa fa-del aui-pull-right clear-input"
                                                                            style={{
                                                                              'position': 'absolute',
                                                                              'right': '20px',
                                                                              'top': '11px'
                                                                            }}></span>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="aui-row-padded buy-body aui-padded-l-15 aui-padded-r-15">
                <div class="aui-col-xs-12 aui-pull-left">
                  <div
                    class="aui-btn aui-btn-danger aui-btn-block aui-btn-sm aui-font-size-14 aui-text-color-white btn-buy aui-margin-t-5 aui-margin-b-15"
                    onClick={() => {
                      submitComment()
                    }} style={{
                    'border-radius': '7rem',
                    'margin-bottom': '1rem!important',
                    'height': '2.1rem',
                    'line-height': '2.1rem'
                  }}>提交评论
                  </div>
                </div>
              </div>
            </div>
          </div>

          <>
            <Title name={'作品评论'}/>
            <div class="aui-content aui-chat aui-bg-white">
              <BookReviewItem onEndReached={onEndReached} hasMore={hasMore}
                              dataSource={dataSource} onRefresh={onRefresh} isLoading={isLoading}
                              refreshing={refreshing} pagesize={pagesize}
                              submitReplayComment={submitReplayComment} onScroll={handleScroll}
                              totalCount={totalCount} {...this.props}/>
            </div>
          </>
        </div>
      </>
    )
  }

  componentDidMount() {
    const {bookid} = this.props.match.params
    var timestamp = new Date().getTime()
    this.setState({
      bookId: bookid,
      stamp: timestamp
    }, () => {
      this.reqReviewList()
    })
  }
}

export default index
