/* eslint-disable default-case */
import React, { Component } from 'react'
import styles from './index.module.scss'
import NavTxt from '@/components/NavTxt'
import FinanceListItem from '@/components/FinanceListItem'
import {
  PullToRefresh,
  WingBlank,
  Grid,
  ListView,
  WhiteSpace,
  Button
} from 'antd-mobile'
import ctx from '@/assets/js/ctx'
import { axiosParam, toBuy, toTrainPay, toPage } from '@/assets/js/string'
import DocumentTitle from 'react-document-title'
import { checkToken } from '@/assets/js/hoc.js'
import { loadingMore } from '@/assets/js/content'
import BookListItem from '@/components/BookListItem'
import Classify from '@/components/Classify'
import { getTheme, getSetting } from '@/assets/js/setting'
import $ from 'jquery'
import moment from 'moment'
import TopNav from '@/components/TopNav'
import sdk from '@/routes/wxShare'
import NavigatorTabbar from '@/components/NavigatorTabbar'
import WxPay from '@/components/WxPay'

function MyBody(props) {
  return (
    <div
      className={`aui-margin-b-0 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}
    >
      <ul className={``}>{props.children}</ul>
    </div>
  )
}

function LikeBody(props) {
  return (
    <>
      <div className={`book-list`}>{props.children}</div>
    </>
  )
}

@checkToken
export class index extends Component {
  static contextType = ctx

  constructor() {
    super()

    let _this = this
    this.state = {
      typeid: '',
      warTypeList: [],
      classList: [],
      bookSorts: [],
      bookStatus: [],
      bookStat: [],
      bookTags: [],
      bookEsilver: [],
      bookPrice: [],
      bookSource: [],
      allBookTags: [],
      bookSaleTotal: [],
      bookPriceSort: [],
      bookSize: [],
      modelId: 0,
      warTypeId: 0,
      classId: 0,
      statusId: 0,
      sortId: 0,
      tagId: 0,
      priceId: 0,
      sizeId: 0,
      saleTotalId: 0,
      esilverId: 0,
      sourceId: 0,
      defaltState: 1,
      className: '',
      filterId: 0,
      priceSortId: 0,
      classIds: '',
      msale: 0,
      page: 0,
      dataSource: new ListView.DataSource({
        // ListView组件的数据源
        rowHasChanged: (row1, row2) => row1 !== row2
      }),
      dataList: [],
      refreshing: false,
      isLoading: true,
      hasMore: true,
      pagesize: 10,
      dataType: 'classifyList',
      isFirstLoad: true,
      title: 'FM书库'
    }
  }

  reqBookList = (action) => {
    const { page } = this.state
    setTimeout(() => {
      loadingMore(
        global.constants.apiUrl + 'sort/getVoiceBookList',
        this.setParam(page + 1),
        this
      )
    }, 10)
  }

  onRefresh = () => {
    this.setState({
      refreshing: true,
      isLoading: true,
      hasMore: true,
      page: 0,
      dataList: []
    })
    this.reqBookList()
    this.reqBookCount()
  }

  onEndReached = (event) => {
    if (this.state.isLoading) {
      return false
    }
    if (!this.state.hasMore) {
      return false
    }
    this.setState({
      isLoading: true
    })

    this.reqBookList()
  }

  reqClassfyTags = () => {
    this.context.axios
      .post(global.constants.apiUrl + 'sort/getVoiceSortList', this.setParam(0))
      .then((res) => {
        this.setState({
          classList: res.data.bookClass,
          bookSorts: res.data.bookSorts,
          bookStatus: res.data.bookStatus,
          bookTags: res.data.bookTags,
          allBookTags: res.data.bookTags,
          warTypeList: res.data.bookWarType,
          bookEsilver: res.data.bookEsilver,
          bookPrice: res.data.bookPrice,
          bookSize: res.data.bookSize,
          bookSaleTotal: res.data.bookSaleTotal,
          bookPriceSort: res.data.bookPriceSort,
          bookSource: res.data.bookSource,
          isFirstLoad: false
        })
        this.handleTags()
      })
  }

  reqBookCount = () => {
    this.context.axios
      .post(global.constants.apiUrl + 'sort/getVoiceCount', this.setParam(0))
      .then((res) => {
        this.setState({
          bookStat: res.data.bookStat,
          isFirstLoad: false
        })
      })
  }

  setParam = (p) => {
    const {
      page,
      sizeId,
      sortId,
      statusId,
      price,
      classId,
      tagId,
      stamp,
      warTypeId,
      modelId,
      esilverId,
      priceId,
      saleTotalId,
      classIds,
      priceSortId,
      typeid,
      sourceId,
      isFirstLoad
    } = this.state

    let params = {
      page: p,
      typeid: typeid,
      size: sizeId,
      sorts: 0,
      status: statusId,
      sorting: sortId,
      tag: tagId,
      wartype: warTypeId,
      model: modelId,
      price: priceId,
      esilver: esilverId,
      saletotal: saleTotalId,
      classids: classIds,
      pricesort: priceSortId,
      source: sourceId
    }

    if (!isFirstLoad) {
      try {
        let toplist = JSON.parse(localStorage.getItem('toplist'))
        toplist[typeid] = {
          sizeId: sizeId,
          statusId: statusId,
          sortId: sortId,
          tagId: tagId,
          warTypeId: warTypeId,
          modelId: modelId,
          priceId: priceId,
          esilverId: esilverId,
          saleTotalId: saleTotalId,
          classIds: classIds,
          classId: classId,
          priceSortId: priceSortId,
          sourceId: sourceId
        }
        localStorage.setItem('toplist', JSON.stringify(toplist))
      } catch (err) {
        console.log(err)
      }
    }

    switch (typeid) {
      case 'last':
        this.setState(
          {
            sortId: 1,
            title: 'A+'
          },
          () => {
            params['sorting'] = 1
          }
        )
        break
      case 'ly':
        this.setState(
          {
            title: '恐怖'
          },
          () => {}
        )
        break
      case 'female':
        this.setState(
          {
            title: '女生'
          },
          () => {}
        )
        break
      case 'male':
        this.setState(
          {
            title: '男生'
          },
          () => {}
        )
        break
      case 'new':
        this.setState(
          {
            title: '299特价'
          },
          () => {}
        )
        break
      case 'cj':
        this.setState(
          {
            title: '初级会员'
          },
          () => {
            params['msale'] = 1
          }
        )
        break
      case 'warrant':
        this.setState(
          {
            warTypeId: 2,
            title: '独家'
          },
          () => {
            params['wartype'] = 2
          }
        )
        break
      case 'good':
        this.setState(
          {
            title: '精品'
          },
          () => {}
        )
        break
      case 'msale':
        this.setState(
          {
            msale: 1,
            title: '音途计划'
          },
          () => {
            params['msale'] = 1
          }
        )
        break
      case 'zq':
        this.setState(
          {
            msale: 1,
            title: '独家999'
          },
          () => {
            params['price'] = 13
            params['wartype'] = 2
          }
        )
        break
      case 'zqm':
        this.setState(
          {
            msale: 1,
            title: '2499专区'
          },
          () => {}
        )
        break
      case 'zql':
        this.setState(
          {
            msale: 1,
            title: '3499专区'
          },
          () => {}
        )
        break
      case 'tzsk':
        this.setState(
          {
            title: 'A+书库'
          },
          () => {
            params['size'] = 3
            params['wartype'] = 2
          }
        )
        break
      case 'xsyh':
        this.setState(
          {
            title: '限时优惠'
          },
          () => {}
        )
        break
      case 'jcb':
        this.setState(
          {
            title: '大年卡'
          },
          () => {}
        )
        break
      case 'jpb':
        this.setState(
          {
            title: '活动'
          },
          () => {}
        )
        break
      case 'qnb':
        this.setState(
          {
            title: '上新'
          },
          () => {}
        )
        break
      case 'mfzj':
        this.setState(
          {
            title: '非独家'
          },
          () => {
            params['wartype'] = 1
          }
        )
        if (isFirstLoad) {
          this.setState(
            {
              sizeId: 6,
              priceId: 23
            },
            () => {}
          )
        }
        break
      case 'ffzj':
        this.setState(
          {
            title: 'VIP专辑'
          },
          () => {
            params['saletotal'] = 1
          }
        )
        if (isFirstLoad) {
          this.setState(
            {
              sizeId: 5
            },
            () => {}
          )
        }
        break
      case 'nkhy':
        this.setState(
          {
            title: '包年录制'
          },
          () => {}
        )
        break
      case 'mshd':
        this.setState(
          {
            title: '秒杀'
          },
          () => {}
        )
        break
      case 'tjb':
        this.setState(
          {
            title: '必录榜'
          },
          () => {}
        )
        break
      case 'xnk':
        this.setState(
          {
            title: '小年卡'
          },
          () => {}
        )
        break
      case 'dpk':
        this.setState(
          {
            title: '短篇卡'
          },
          () => {}
        )
        break
      case 'lyk':
        this.setState(
          {
            title: '灵异卡'
          },
          () => {}
        )
        break
      case 'gvzx':
        this.setState(
          {
            title: '高V专享'
          },
          () => {}
        )
        break
      case 'dpds':
        this.setState(
          {
            title: '短篇单售'
          },
          () => {}
        )
        params['model'] = 19
        break
    }

    params = $.extend(params, axiosParam(this.context, stamp))

    return params
  }
  checkBookModel = (id) => {
    if (id < 0) return
    this.setState(
      {
        modelId: id,
        page: 0,
        filterId: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookWarType = (id) => {
    if (id < 0) return
    this.setState(
      {
        warTypeId: id,
        page: 0,
        filterId: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookEsilver = (id) => {
    if (id < 0) return
    this.setState(
      {
        esilverId: id,
        page: 0,
        filterId: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookSize = (id) => {
    if (id < 0) return
    this.setState(
      {
        sizeId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookSaleTotal = (id) => {
    if (id < 0) return
    this.setState(
      {
        saleTotalId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookPrice = (id) => {
    if (id < 0) return
    this.setState(
      {
        priceId: id,
        page: 0,
        filterId: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookPriceSort = (id) => {
    if (id < 0) return
    this.setState(
      {
        priceSortId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  //书籍类型筛选
  checkBookClass = (index, iindex, id) => {
    const { classList } = this.state
    if (iindex >= 0) {
      this.setState(
        {
          modelId: 0,
          classId: id,
          page: 0,
          classIds: classList[index]['list'][iindex]['ids'],
          filterId: 0
        },
        () => {
          this.onRefresh()
        }
      )
    } else {
      this.setState(
        {
          classId: 0,
          modelId: id,
          page: 0,
          classIds: '',
          filterId: 0
        },
        () => {
          this.onRefresh()
        }
      )
    }
  }

  //书籍字数筛选
  checkBookStatus = (id) => {
    if (id < 0) return
    this.setState(
      {
        statusId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  //书籍更新状态筛选
  checkBookSorts = (id) => {
    if (id < 0) return
    this.setState(
      {
        sortId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookTags = (id) => {
    if (id < 0) return
    this.setState(
      {
        tagId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookSize = (id) => {
    if (id < 0) return
    this.setState(
      {
        sizeId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  checkBookSource = (id) => {
    this.setState(
      {
        sourceId: id,
        page: 0
      },
      () => {
        this.onRefresh()
      }
    )
  }

  clickFilter = (id) => {
    if (id < 0) return
    this.setState({
      filterId: id
    })
  }

  handleScroll = (e) => {
    var scrollTop = e.target.scrollTop
    if (!(scrollTop <= 10)) {
      this.setState({
        defaltState: 0
      })
    } else {
      this.setState({
        defaltState: 1
      })
    }
  }

  onRef = (ref) => {
    this.financeListItem = ref
  }

  handleTags = (e) => {
    $('.sort-tag .aui-grid-label')
      .last()
      .after(
        "<div class='aui-grid-label current aui-margin-b-0 aui-pull-left op-tag'><div class='aui-iconfont aui-font-size-14 aui-icon-top aui-padded-l-5'></div></div>"
      )

    var maxwidth = $('.sort-tag').width()
    var w = 0
    var index = 0
    var j = 0
    $('.sort-tag .aui-grid-label').each(function (i, item) {
      w += $(this).width()
      if (w > maxwidth) {
        w = $(this).width()
        index++
        if (index > 2) {
          j = i - 1
          return false
        }
      }
    })

    $('.sort-tag .aui-grid-label').each(function (i, item) {
      if (i >= j) {
        $(this).hide()
      }
    })

    $('.sort-tag .aui-grid-label')
      .eq(j)
      .after(
        "<div class='aui-grid-label current aui-margin-b-0 aui-pull-left op-tag'><div class='aui-iconfont aui-font-size-14 aui-icon-down aui-padded-l-5'></div></div>"
      )

    $('.sort-tag').on('click', '.aui-grid-label .aui-iconfont', function () {
      if ($(this).hasClass('aui-icon-top')) {
        $('.sort-tag .aui-grid-label').each(function (i, item) {
          if (i >= j) {
            $(this).hide()
          }
        })
        $('.sort-tag .aui-grid-label.op-tag .aui-icon-down').parent().show()
        $('.sort-tag .aui-grid-label.op-tag .aui-icon-top').parent().hide()
      } else {
        $('.sort-tag .aui-grid-label').each(function (i, item) {
          $(this).show()
        })

        $('.sort-tag .aui-grid-label.op-tag .aui-icon-down').parent().hide()
        $('.sort-tag .aui-grid-label.op-tag .aui-icon-top').parent().show()
      }
    })
  }

  changeHomeListFirstHeight = (e) => {}

  onPayRef = (ref) => {
    this.child = ref
  }

  toWxPay = (payMoney, ruleId) => {
    this.child.init(payMoney, ruleId)
  }

  payNotify = (e) => {
    window.location.reload()
  }

  render() {
    const {
      classList,
      classId,
      filterId,
      bookStatus,
      bookSorts,
      bookTags,
      sortId,
      statusId,
      dataSource,
      isLoading,
      refreshing,
      hasMore,
      tagId,
      pagesize,
      page,
      bookStat,
      isFirstLoad,
      modelId,
      warTypeId,
      warTypeList,
      bookEsilver,
      esilverId,
      bookPrice,
      priceId,
      typeid,
      title,
      bookSize,
      sizeId,
      bookSaleTotal,
      saleTotalId,
      bookPriceSort,
      priceSortId,
      sourceId,
      bookSource
    } = this.state

    const {
      checkBookStatus,
      checkBookClass,
      checkBookSorts,
      onEndReached,
      checkBookTags,
      onRefresh,
      handleScroll,
      onRef,
      checkBookModel,
      checkBookWarType,
      checkBookEsilver,
      checkBookPrice,
      checkBookSize,
      checkBookSaleTotal,
      checkBookPriceSort,
      checkBookSource,
      changeHomeListFirstHeight,
      toWxPay
    } = this
    const antIcon = <div className={`am-icon-dot`} />

    return (
      <>
        <DocumentTitle
          title={
            this.context.siteinfo
              ? this.context.siteinfo.title + ' | ' + title
              : ''
          }
        ></DocumentTitle>

        {!isFirstLoad ? (
          <>
            <TopNav name={title} history={this.props.history} />

            <div className={`classify aui-bg-wx ${getTheme()}`}>
              <ListView
                ref={(el) => (this.lv = el)}
                dataSource={dataSource}
                pageSize={pagesize}
                useBodyScroll={false}
                style={{
                  marginTop: '45px',
                  height: document.documentElement.clientHeight - 45,
                  overflow: 'hidden',
                  overflowY: 'scroll'
                }}
                renderHeader={(sectionData, sectionID) => (
                  <div className={`aui-content  aui-border-radius-10`}>
                    {(() => {
                      // eslint-disable-next-line default-case
                      switch (typeid) {
                        case 'xsyh':
                          return (
                            <>
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                                bookPrice={bookPrice}
                                priceId={priceId}
                                checkBookPrice={checkBookPrice}
                                warTypeList={warTypeList}
                                warTypeId={warTypeId}
                                checkBookWarType={checkBookWarType}
                                bookSaleTotal={bookSaleTotal}
                                saleTotalId={saleTotalId}
                                checkBookSaleTotal={checkBookSaleTotal}
                                bookSize={bookSize}
                                sizeId={sizeId}
                                checkBookSize={checkBookSize}
                                bookSorts={bookSorts}
                                sortId={sortId}
                                checkBookSorts={checkBookSorts}
                                bookPriceSort={bookPriceSort}
                                priceSortId={priceSortId}
                                checkBookPriceSort={checkBookPriceSort}
                                bookEsilver={bookEsilver}
                                esilverId={esilverId}
                                checkBookEsilver={checkBookEsilver}
                                bookTags={bookTags}
                                tagId={tagId}
                                checkBookTags={checkBookTags}
                                bookSource={bookSource}
                                sourceId={sourceId}
                                checkBookSource={checkBookSource}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'sk':
                          return (
                            <>
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                                bookPrice={bookPrice}
                                priceId={priceId}
                                checkBookPrice={checkBookPrice}
                                warTypeList={warTypeList}
                                warTypeId={warTypeId}
                                checkBookWarType={checkBookWarType}
                                bookSaleTotal={bookSaleTotal}
                                saleTotalId={saleTotalId}
                                checkBookSaleTotal={checkBookSaleTotal}
                                bookSize={bookSize}
                                sizeId={sizeId}
                                checkBookSize={checkBookSize}
                                bookSorts={bookSorts}
                                sortId={sortId}
                                checkBookSorts={checkBookSorts}
                                bookPriceSort={bookPriceSort}
                                priceSortId={priceSortId}
                                checkBookPriceSort={checkBookPriceSort}
                                bookEsilver={bookEsilver}
                                esilverId={esilverId}
                                checkBookEsilver={checkBookEsilver}
                                bookTags={bookTags}
                                tagId={tagId}
                                checkBookTags={checkBookTags}
                                bookSource={bookSource}
                                sourceId={sourceId}
                                checkBookSource={checkBookSource}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'tzsk':
                          return (
                            <>
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                                bookPrice={bookPrice}
                                priceId={priceId}
                                checkBookPrice={checkBookPrice}
                                bookEsilver={bookEsilver}
                                esilverId={esilverId}
                                checkBookEsilver={checkBookEsilver}
                                bookTags={bookTags}
                                tagId={tagId}
                                checkBookTags={checkBookTags}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'new':
                          return (
                            <>
                              <WhiteSpace />
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                                bookPrice={bookPrice}
                                priceId={priceId}
                                checkBookPrice={checkBookPrice}
                                warTypeList={warTypeList}
                                warTypeId={warTypeId}
                                checkBookWarType={checkBookWarType}
                                bookSaleTotal={bookSaleTotal}
                                saleTotalId={saleTotalId}
                                checkBookSaleTotal={checkBookSaleTotal}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'zqm':
                        case 'zql':
                          return (
                            <>
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                                bookPriceFirst={bookPrice}
                                priceId={priceId}
                                checkBookPrice={checkBookPrice}
                                warTypeList={warTypeList}
                                warTypeId={warTypeId}
                                checkBookWarType={checkBookWarType}
                                bookSaleTotal={bookSaleTotal}
                                saleTotalId={saleTotalId}
                                checkBookSaleTotal={checkBookSaleTotal}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'cj':
                          return (
                            <>
                              <div
                                className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white image`}
                              >
                                <div
                                  class='aui-content aui-margin-b-0 aaa aui-padded-10'
                                  style={{ margin: '0px' }}
                                >
                                  <img
                                    src={require('@/assets/images/toplist/cjhy.jpeg')}
                                  />
                                </div>
                              </div>
                              <WhiteSpace />
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  warTypeList={warTypeList}
                                  warTypeId={warTypeId}
                                  checkBookWarType={checkBookWarType}
                                  bookSaleTotal={bookSaleTotal}
                                  saleTotalId={saleTotalId}
                                  checkBookSaleTotal={checkBookSaleTotal}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                />
                                <div
                                  className={`aui-grid sort-category total-statics aui-text-align-l`}
                                >
                                  <div className={`aui-row aui-text-align-l`}>
                                    <div
                                      class='aui-col-xs-9 aui-padded-5 aui-padded-l-5 aui-font-size-14'
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        case 'warrant':
                          return (
                            <>
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                                bookPrice={bookPrice}
                                priceId={priceId}
                                checkBookPrice={checkBookPrice}
                                bookSaleTotal={bookSaleTotal}
                                saleTotalId={saleTotalId}
                                checkBookSaleTotal={checkBookSaleTotal}
                                bookSize={bookSize}
                                sizeId={sizeId}
                                checkBookSize={checkBookSize}
                                bookPriceSort={bookPriceSort}
                                priceSortId={priceSortId}
                                checkBookPriceSort={checkBookPriceSort}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'last':
                        case 'good':
                        case 'male':
                        case 'female':
                        case 'ly':
                          return (
                            <>
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                                bookPrice={bookPrice}
                                priceId={priceId}
                                checkBookPrice={checkBookPrice}
                                warTypeList={warTypeList}
                                warTypeId={warTypeId}
                                checkBookWarType={checkBookWarType}
                                bookSaleTotal={bookSaleTotal}
                                saleTotalId={saleTotalId}
                                checkBookSaleTotal={checkBookSaleTotal}
                                bookSize={bookSize}
                                sizeId={sizeId}
                                checkBookSize={checkBookSize}
                                bookPriceSort={bookPriceSort}
                                priceSortId={priceSortId}
                                checkBookPriceSort={checkBookPriceSort}
                                bookEsilver={bookEsilver}
                                esilverId={esilverId}
                                checkBookEsilver={checkBookEsilver}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'zq':
                          return (
                            <>
                              <Classify
                                {...this.props}
                                classList={classList}
                                classId={classId}
                                modelId={modelId}
                                checkBookClass={checkBookClass}
                              />
                              {bookStat ? (
                                <div
                                  className={`aui-grid sort-category total-statics`}
                                >
                                  <div className={`aui-row`}>
                                    <div class='aui-col-12 aui-padded-5 aui-font-size-14'>
                                      <span style={{ marginLeft: '0.7rem' }}>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        case 'jpb':
                        case 'qnb':
                          return (
                            <>
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                />
                              </div>
                            </>
                          )

                        case 'mfzj':
                          return (
                            <>
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                  bookSaleTotal={bookSaleTotal}
                                  saleTotalId={saleTotalId}
                                  checkBookSaleTotal={checkBookSaleTotal}
                                />
                              </div>
                            </>
                          )
                        case 'ffzj':
                          return (
                            <>
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                  warTypeList={warTypeList}
                                  warTypeId={warTypeId}
                                  checkBookWarType={checkBookWarType}
                                />
                              </div>
                            </>
                          )
                        case 'nkhy':
                          return (
                            <>
                              <div
                                className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white image`}
                              >
                                <div
                                  class='aui-content aui-margin-b-0 aaa aui-padded-10'
                                  style={{ margin: '0px' }}
                                >
                                  <img
                                    src={require('@/assets/images/toplist/nkhy.jpeg')}
                                  />
                                </div>
                              </div>
                              <WhiteSpace />
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                />
                                <div
                                  className={`aui-grid sort-category total-statics aui-text-align-l`}
                                >
                                  <div className={`aui-row aui-text-align-l`}>
                                    <div
                                      class='aui-col-xs-9 aui-padded-5 aui-padded-l-5 aui-font-size-14'
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                    <div
                                      className={`aui-col-xs-3 aui-padded-0`}
                                    >
                                      <Button
                                        inline
                                        size='small'
                                        className={`btn-small aui-margin-r-5`}
                                        type='warning'
                                        onClick={() => {
                                          toPage('buy', this.context)
                                        }}
                                      >
                                        开通
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        case 'xnk':
                          return (
                            <>
                              <div
                                className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white image`}
                              >
                                <div
                                  class='aui-content aui-margin-b-0 aaa aui-padded-10'
                                  style={{ margin: '0px' }}
                                >
                                  <img
                                    src={require('@/assets/images/toplist/xnk.png')}
                                  />
                                </div>
                              </div>
                              <WhiteSpace />
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                />
                                <div
                                  className={`aui-grid sort-category total-statics aui-text-align-l`}
                                >
                                  <div className={`aui-row aui-text-align-l`}>
                                    <div
                                      class='aui-col-xs-9 aui-padded-5 aui-padded-l-5 aui-font-size-14'
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                    <div
                                      className={`aui-col-xs-3 aui-padded-0`}
                                    >
                                      <Button
                                        inline
                                        size='small'
                                        className={`btn-small aui-margin-r-5`}
                                        type='warning'
                                        onClick={() => {
                                          toWxPay('49900', '155')
                                        }}
                                      >
                                        开通
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )

                        case 'jcb':
                          return (
                            <>
                              <div
                                className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white image`}
                              >
                                <div
                                  class='aui-content aui-margin-b-0 aaa aui-padded-10'
                                  style={{ margin: '0px' }}
                                >
                                  <img
                                    src={require('@/assets/images/toplist/dnk.png')}
                                  />
                                </div>
                              </div>
                              <WhiteSpace />
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                />
                                <div
                                  className={`aui-grid sort-category total-statics aui-text-align-l`}
                                >
                                  <div className={`aui-row aui-text-align-l`}>
                                    <div
                                      class='aui-col-xs-9 aui-padded-5 aui-padded-l-5 aui-font-size-14'
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                    <div
                                      className={`aui-col-xs-3 aui-padded-0`}
                                    >
                                      <Button
                                        inline
                                        size='small'
                                        className={`btn-small aui-margin-r-5`}
                                        type='warning'
                                        onClick={() => {
                                          toWxPay('99900', '154')
                                        }}
                                      >
                                        开通
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        case 'dpk':
                          return (
                            <>
                              <div
                                className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white image`}
                              >
                                <div
                                  class='aui-content aui-margin-b-0 aaa aui-padded-10'
                                  style={{ margin: '0px' }}
                                >
                                  <img
                                    src={require('@/assets/images/toplist/dpk.png')}
                                  />
                                </div>
                              </div>
                              <WhiteSpace />
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                />
                                <div
                                  className={`aui-grid sort-category total-statics aui-text-align-l`}
                                >
                                  <div className={`aui-row aui-text-align-l`}>
                                    <div
                                      class='aui-col-xs-9 aui-padded-5 aui-padded-l-5 aui-font-size-14'
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                    <div
                                      className={`aui-col-xs-3 aui-padded-0`}
                                    >
                                      <Button
                                        inline
                                        size='small'
                                        className={`btn-small aui-margin-r-5`}
                                        type='warning'
                                        onClick={() => {
                                          toWxPay('68800', '156')
                                        }}
                                      >
                                        开通
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        case 'lyk':
                          return (
                            <>
                              <div
                                className={`aui-margin-t-0 more-info aui-border-radius-10 aui-bg-white image`}
                              >
                                <div
                                  class='aui-content aui-margin-b-0 aaa aui-padded-10'
                                  style={{ margin: '0px' }}
                                >
                                  <img
                                    src={require('@/assets/images/toplist/lyk.png')}
                                  />
                                </div>
                              </div>
                              <WhiteSpace />
                              <div
                                className={`aui-content aui-border-radius-10 `}
                              >
                                <Classify
                                  {...this.props}
                                  classList={classList}
                                  classId={classId}
                                  modelId={modelId}
                                  checkBookClass={checkBookClass}
                                  bookPrice={bookPrice}
                                  priceId={priceId}
                                  checkBookPrice={checkBookPrice}
                                  bookSize={bookSize}
                                  sizeId={sizeId}
                                  checkBookSize={checkBookSize}
                                />
                                <div
                                  className={`aui-grid sort-category total-statics aui-text-align-l`}
                                >
                                  <div className={`aui-row aui-text-align-l`}>
                                    <div
                                      class='aui-col-xs-9 aui-padded-5 aui-padded-l-5 aui-font-size-14'
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        截止{moment().format('DD/MM/YYYY')}，
                                        {title}共{bookStat.totalCount}本。
                                      </span>
                                    </div>
                                    <div
                                      className={`aui-col-xs-3 aui-padded-0`}
                                    >
                                      <Button
                                        inline
                                        size='small'
                                        className={`btn-small aui-margin-r-5`}
                                        type='warning'
                                        onClick={() => {
                                          toWxPay('86600', '157')
                                        }}
                                      >
                                        开通
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                      }
                    })()}
                  </div>
                )}
                renderBodyComponent={() => <MyBody />}
                renderSectionBodyWrapper={(sectionID) => <LikeBody />}
                renderRow={(rowData, sectionId, rowID) => (
                  <>
                    {(() => {
                      console.log(typeid)
                      switch (typeid) {
                        case 'xsyh':
                          return (
                            <>
                              <BookListItem
                                {...this.props}
                                deleteUserBook={this.deleteUserBook}
                                xsthList={rowData}
                                rowID={rowID}
                              />
                            </>
                          )
                        case 'mshd':
                          return (
                            <>
                              <BookListItem
                                {...this.props}
                                deleteUserBook={this.deleteUserBook}
                                mshdList={rowData}
                                rowID={rowID}
                              />
                            </>
                          )
                        case 'jpb':
                          return (
                            <>
                              <BookListItem
                                {...this.props}
                                deleteUserBook={this.deleteUserBook}
                                jpbList={rowData}
                                rowID={rowID}
                                changeHomeListFirstHeight={
                                  changeHomeListFirstHeight
                                }
                              />
                            </>
                          )
                        default:
                          return (
                            <>
                              <BookListItem
                                {...this.props}
                                deleteUserBook={this.deleteUserBook}
                                homeList={rowData}
                                rowID={rowID}
                                changeHomeListFirstHeight={
                                  changeHomeListFirstHeight
                                }
                              />
                            </>
                          )
                      }
                    })()}
                  </>
                )}
                renderFooter={() => (
                  <div
                    style={{ clear: 'both', padding: 0, textAlign: 'center' }}
                  >
                    {isLoading ? antIcon : hasMore ? '' : '没有更多'}
                  </div>
                )}
                pullToRefresh={
                  <PullToRefresh
                    indicator={{
                      activate: antIcon,
                      deactivate: antIcon,
                      release: antIcon,
                      finish: antIcon
                    }}
                    refreshing={refreshing}
                    distanceToRefresh={55}
                    onRefresh={onRefresh}
                  />
                }
                onEndReached={onEndReached}
                onEndReachedThreshold={20}
              />
            </div>
          </>
        ) : (
          ''
        )}
        <WxPay onRef={this.onPayRef} payNotify={this.payNotify}></WxPay>
      </>
    )
  }

  componentDidMount() {
    var timestamp = new Date().getTime()
    const { isFirstLoad } = this.state
    const { typeid } = this.props.match.params
    let _that = this
    switch (typeid) {
      case 'new':
        this.setState(
          {
            priceId: 5
          },
          () => {}
        )
        break
      case 'zqm':
        this.setState(
          {
            priceId: 14
          },
          () => {}
        )
        break
      case 'zql':
        this.setState(
          {
            priceId: 18
          },
          () => {}
        )
        break
    }
    this.setState(
      {
        typeid: typeid,
        stamp: timestamp
      },
      () => {
        //window.addEventListener('scroll', this.handleScroll);

        if (isFirstLoad) {
          try {
            var toplist = JSON.parse(localStorage.getItem('toplist'))

            let classifyParam = toplist[typeid]
            _that.setState(classifyParam, () => {
              _that.reqClassfyTags()
              _that.reqBookCount()
              _that.reqBookList()
            })
          } catch (err) {
            let toplist = {}
            localStorage.setItem('toplist', JSON.stringify(toplist))

            this.reqClassfyTags()
            this.reqBookCount()
            this.reqBookList()
          }
        }

        sdk.getJSSDK(
          {
            title: this.context.siteinfo
              ? this.context.siteinfo.title + ' | ' + this.state.title
              : '',
            url: window.location.href,
            type: 'common'
          },
          timestamp,
          this.context
        )
      }
    )
  }
}

export default index
