/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable default-case */
import React, { Component } from 'react';
import axios from '@/assets/js/axios.js';
import { getUserInfo, quitLogin } from '@/assets/js/user';
import HeaderNav from '@/components/HeaderNav';
import { render } from 'react-dom'
import Countdown from 'react-countdown';
import ctx from '@/assets/js/ctx';
import {
  getQueryString,
  signParam,
  toOrderList,
  axiosParam,
  toBookRead,
  toCatalog,
} from '@/assets/js/string';
import DocumentTitle from 'react-document-title';
import { checkToken } from '@/assets/js/hoc.js';
import {
  PullToRefresh,
  Flex,
  Button,
  List,
  ListView,
  WhiteSpace,
  WingBlank,
  Result,
  Icon,
  SegmentedControl,
} from 'antd-mobile';
import { Grid } from 'antd-mobile-v5';
import TopNav from '@/components/TopNav';
import Title from '@/components/Title';
import { loadingMore } from '@/assets/js/content';
import { string } from '@/assets/js/string';
import { getTheme, getSetting } from '@/assets/js/setting';
import $ from 'jquery';
import { toPage, toAliPay, toHome } from '../../assets/js/string'
import sdk from '@/routes/wxShare'
const Item = List.Item;
const Brief = Item.Brief;

@checkToken
export class index extends Component {
  static contextType = ctx;

  constructor() {
    super();

    this.state = {
      pageTitle: '订单详情',
      userInfo: {},
      siteInfo: {},
      orderInfo: {},
      typeId: 1,
      payMoney: '-',
      payCustomMoney: '',
      page: 0,
      isAllLoaded: false,
      financeRuleList: [],
      financeMonthlyRuleList: [],
      isFirstLoaded: true,
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      dataList: [],
      pagesize: 10,
      isLoading: true,
      refreshing: true,
      down: true,
      hasMore: true,
      useBodyScroll: true,
      paySuccess: false,
      showAliPay: true,
      payType: 0,
    };
  }

  getOrderDetail = () => {
    const { stamp, orderId } = this.state;

    let params = {
      siteId: 15,
      orderId: orderId,
    };
    params = $.extend(params, axiosParam(this.context, stamp));

    axios.post(global.constants.apiUrl + 'order/getOrderDetail', params).then(res => {
      let code = res.code.toString();
      if (res.code === '1') {
        this.setState(
          {
            isAllLoaded: true,
            orderInfo: res.data,
          },
          () => {}
        );
      }
    });
  };

  onCountDownComplete = () => {
    toOrderList(this.context);
  }

  
  render() {
    const { isAllLoaded, pageTitle, dataList, orderInfo,orderId } = this.state;

    const { onTabChange, onRefresh, onChangePay, toWxPay, onCountDownComplete } = this;
    const antIcon = <div className={`am-icon-dot`} />;

    const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <></>;
  } else {
    // Render a countdown
    return <>
      <div className={`count-down`}>
      <Grid columns={3} gap={8} style={{'width':'10rem','margin':'20px auto'}}>
          <Grid.Item>
          <div>{hours}<br/>
          <span className={`aui-font-size-10`}>小时</span></div>
          </Grid.Item>
          <Grid.Item>
            <div style={{'padding':'1rem'}}>{minutes}<br/>
          <span className={`aui-font-size-10`}>分</span></div>
          </Grid.Item>
          <Grid.Item>
            <div style={{'padding':'1rem'}}>{seconds}<br/>
          <span className={`aui-font-size-10`}>秒</span></div>
        </Grid.Item>
        </Grid>
        </div>
      </>;
  }
    };
    
    return (
      <>
        <DocumentTitle
          title={this.context.siteinfo ? this.context.siteinfo.title + ' | ' + pageTitle : ''}
        ></DocumentTitle>
        <TopNav name={pageTitle} history={this.props.history} />

        <div className={`aui-order-detail aui-bg-wx ${getTheme()}`}>
          {isAllLoaded ? (
            <>
              <WhiteSpace />
              <div
                className={`aui-margin-15 aui-margin-t-0 aui-bg-white free-list aui-border-radius-10`}
              >
                {( () => {
                  switch (orderInfo.order_status) {
                    case -1:
                      return <>
                          <div class="aui-content aui-pay-body">
                            <section class="aui-grid">
                              <div class="aui-row">
                                <div className="sub-title">订单提交成功</div>
                                <Result
                                  img={
                                    <img src={`https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg`} className="spe am-icon am-icon-md" style={{'width': '3rem', 'height': '3rem' }} alt="" />
                                  }
                                  title="订单提交成功"
                                  message="宝，为了保护您的权益，购买独家版权需要二次审核，小二已经收到您的订单申请啦，正在抓紧审核中，请您耐心等待哦~~"
                                />
                                <Countdown
    date={orderInfo.endtime}
    intervalDelay={0}
    precision={3}
    renderer={renderer}
                                  onComplete={onCountDownComplete}
  />
                                <Button
                                  className={`aui-border-radius-10 btn-large`}
                                  type="warning"
                                  onClick={() => {
                                    toOrderList(this.context);
                                  }}
                                >
                                  查看订单
                                </Button>
                              </div>
                            </section>
                          </div>
                        </>
                    case -2:
                      return <>
                          <div class="aui-content aui-pay-body">
                            <section class="aui-grid">
                              <div class="aui-row">
                                <div className="sub-title">订单提交成功</div>
                                <Result
                                  img={
                                    <Icon
                                      type="cross-circle-o"
                                      className="spe"
                                      style={{ fill: '#F13642', width: '3rem', height: '3rem' }}
                                    />
                                  }
                                  title="审核失败"
                                  message="宝，很遗憾，您挑选的作品版权已被其他商家售出，请更换其他作品购买哦~"
                                />
                                
                                <Button
                                  className={`aui-border-radius-10 btn-large`}
                                  type="warning"
                                  onClick={() => {
                                    toOrderList(this.context);
                                  }}
                                >
                                  返回订单
                                </Button>
                              </div>
                            </section>
                          </div>
                      </>
                    case 0:
                      return <>
                          <div class="aui-content aui-pay-body">
                            <section class="aui-grid">
                              <div class="aui-row">
                                <div className="sub-title">审核成功</div>
                              <Result
                                img={<Icon type="check-circle" className="spe" style={{ fill: '#ff0000', width: '3rem', height: '3rem' }} />}
                                
                                  title="审核成功"
                                  message="宝，恭喜您，您挑选的作品版权可以出单，请在24H内付款以防止被其他人抢走哦~"
                                />
                                <Countdown
                                  date={orderInfo.endtime}
                                  intervalDelay={0}
                                  precision={3}
                                  renderer={renderer}
                                  onComplete={onCountDownComplete}
                                />
                                <Button
                                  className={`aui-border-radius-10 btn-large`}
                                type="warning"
                                  onClick={() => {
                                    toOrderPay(orderId,this.context);
                                  }}
                                >
                                  去支付
                                </Button>
                              </div>
                            </section>
                          </div>
                        </>
                    default:
                      return (
                        <>
                          
                        </>
                      );
                  }
                })()}
              </div>
              <WhiteSpace />
            </>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }

  componentDidMount() {
    var timestamp = new Date().getTime();
    const { orderid } = this.props.match.params;
    this.setState(
      {
        stamp: timestamp,
        orderId: orderid,
      },
      () => {
        getUserInfo(this.context, timestamp).then(res => {
          if (res.code < 0) {
            if (res.code == -3) {
              quitLogin(this.context);
            }
          } else {
            this.setState({
              userInfo: res.data,
            });
            this.getOrderDetail();
            sdk.getJSSDK({ title: this.context.siteinfo ? this.context.siteinfo.title + ' | 订单详情' : '',url: window.location.href, type: 'common'},timestamp,this.context)
          }
        });
      }
    );
  }
}

export default index;
